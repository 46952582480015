import React, { useEffect, useState, useContext } from "react";
import { API_BASE_URL, IMAGES_BASE_URL, tokenCookie, keyHeader } from "../../../config";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import {
  CCol,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CSpinner,
  CAlert,
  CButton
} from "@coreui/react";
import JobItem from "./JobItem";
import SendApplication from "./SendApplication";
import Loading from "src/components/myWidgets/loadingWidget";
import { isLogin } from "src/components/middleware/auth";
import FavoriteContext from "../FavoriteContext";
import apiClient from "../apiClient";
import apiPublic from "../apiPublic";

function JobDetail(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [job, setJob] = useState(null);
  const [items, setItems] = useState([]);
  const [modal2, setModal2] = useState(false);
  const [modal, setModal] = useState(false);
  const [exist, setExist] = useState(false);
  const [createdByCompany, setCreatedByCompany] = useState(false);
  const [owner, setOwner] = useState(false);
  const [isLoadedOwner, setIsLoadedOwner] = useState(false);
  const [closed, setClosed] = useState(false);
  const [isBeating, setBeating] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const { favorites, updateFavorites, checkFavorite } = useContext(
    FavoriteContext
  );

  const { id } = props.match.params;

  const table = "JobOffer";

  useEffect(() => {
    //console.log('propsdsrdhf------',id);
    //console.log(props.location.state);
    localStorage.setItem("prevUrl", props.location.pathname);

    const requestOptions = {
      headers: {
      }
    };
    if (tokenCookie) {
      requestOptions.headers.Authorization = `Bearer ${tokenCookie}`;
    }

    const formData2 = new FormData();
    //formData2.append("user",  tokenCookie);
    formData2.append("id", id);

    if (isLogin())
      if (!props.location.pathname.includes('myItemDetails'))
        apiClient
          .post(`/jobs/checkOwner`, formData2)
          .then((result) => {
            // console.log("resultt jj ow ", result);
            setOwner(result.data.owner)
            setIsLoadedOwner(true);
          })
          .catch((error) => {
            //console.log("err jj ow", error);

          });


    if (props.location.state) {

      if (props.location.state.createdBy.accountType.id == 2) setCreatedByCompany(true);
      setJob(props.location.state);
      setClosed(props.location.state.closed)
      //console.log("resultt props", props.location.state);
      setIsLoaded(true);

      const pathName = 'JobOffer';
      axios(`${API_BASE_URL}/addLog/${pathName}/${id}`, keyHeader)
        .then((res) => res.data)
        .then(
          (result) => {
            //console.log('add log result');
            //console.log(result);
          },
          (error) => {
            //console.log(error);
          }
        );


    }
    else {
      const formData2 = new FormData();

      formData2.append("id", id);
      axios
        .post(`${API_BASE_URL}/jobs/show`, formData2)
        .then((result) => {
          //console.log("resultt jjobbb ", result);
          if (result.data.item.createdBy.accountType.id == 2) setCreatedByCompany(true);
          setJob(result.data.item);
          setClosed(result.data.item.closed)

          setIsLoaded(true);

          const pathName = 'JobOffer';
          axios(`${API_BASE_URL}/addLog/${pathName}/${id}`, keyHeader)
            .then((res) => res.data)
            .then(
              (result) => {
                //console.log('add log result');
                //console.log(result);
              },
              (error) => {
                //console.log(error);
              }
            );

        })
        .catch((error) => {
          setIsLoaded(true);
          setError(error);
        });
    }

    if (!props.location.pathname.includes('myItemDetails'))
      // apiPublic(`/similar-job/${id}`) 
      axios(`${API_BASE_URL}/similar-job/${id}`, requestOptions)
        .then((res) => res.data)
        .then(
          (result) => {
            setItems(result);
            // console.log('ssiii', result);
          },
          (error) => {
            setIsLoaded(true);
            //  setError(error);
          }
        );

    const formData = new FormData();
    //formData.append("candidature", tokenCookie);
    formData.append("jobOffer", id);
    if (isLogin())
      apiClient
        .post("/candidature-send-jobOffer", formData)
        .then((res) => {
          // console.log("resCAND", res);
          setExist(res.data);
          ////console.log(localStorage);
          //console.log("iddddd" + id);
          //console.log("exxxxxx");

          //console.log(exist);
          setTimeout(() => {
            //console.log(exist);
          }, 200);
        })
        .catch((error) => {
          //console.log(" errors : ", error);
        });


  }, [id, props.location.pathname]);

  useEffect(() => {
    if (favorites) {
      setIsFav(checkFavorite(id, table));
    }
  }, [id, favorites]);

  const setFavourite = async () => {
    if (!isLogin()) setModal(true);
    else {
      setBeating(true);
      try {
        const success = await updateFavorites(id, table);

        if (success) {
          setIsFav(true);
          setBeating(false);
        } else {
          setIsFav(false);
          setBeating(false);
        }
      } catch (error) {
        //console.error(error);
        setBeating(false);
      }
    }
  };

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <Loading />
    );
  } else {
    return (
      <div className="container jobDetailsPage">
        {job && <main className="l-main">
          <div className="l-main__grid o-container jobDetailsPage">
            <div className="l-main__article l-main__content l-main__section l-article ">
              <div className="row jobAndCompanyRow">
                <CCol xs="4" md="4">
                  <div className="bordered">
                    <img
                      src={
                        createdByCompany ? `${IMAGES_BASE_URL}/public/uploads/members/${job.createdBy.path}`
                          : job.company ? `${IMAGES_BASE_URL}/public/uploads/companies/${job.company.path}`
                            : job.createdBy.company && job.createdBy.company.path
                              ? `${IMAGES_BASE_URL}/public/uploads/companies/${job.createdBy.company.path}`
                              : "https://www.keejob.com/static/recruiter/images/default-logo.jpg"
                      }
                      alt=""
                      className="campany-logo"
                    />
                    <div className="companyDetails">
                      <h5 className="text-blue-primary text-bold">
                        {createdByCompany ? job.createdBy.nom
                          : job.company ? job.company.name
                            : job.createdBy.company ? job.createdBy.company.name
                              : ''}
                      </h5>
                      <span>
                        {createdByCompany ? job.createdBy.adresse
                          : job.company ? job.company.address
                            : job.createdBy.company ? job.createdBy.company.address
                              : ''}
                      </span>{" "}
                      <br />
                      <span>
                        {job.company ? job.company.city
                          : job.createdBy.company ? job.createdBy.company.city
                            : ''}
                        {job.company ? " , " + job.company.country
                          : job.createdBy.company ? " , " + job.createdBy.company.country
                            : ''}
                      </span>
                    </div>
                  </div>{" "}
                </CCol>
                <CCol xs="8" md="8">
                  <div className="bordered">
                    {closed && <CAlert color="danger" className='closedAnnonceNote'>
                      this offer is closed cuurently !
                    </CAlert>}
                    <h5 className="job-title">{job.title}
                      <CButton
                        onClick={
                          isBeating ? () => { }
                            : () => {
                              setFavourite();
                            }
                        }
                        className={`favorite favIconClass ${isBeating ? 'beating' : ''}`}
                      >
                        {isFav == null ? ''
                          : isFav == false ? <svg

                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="var(--ci-primary-color, currentColor)"
                              d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"
                            />
                          </svg>
                            : isFav == true ? <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="var(--ci-primary-color, currentColor)"
                                d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
                              />
                            </svg>
                              : ''}
                      </CButton>
                    </h5>
                    {job.jobType !== "" && (
                      <>
                        <strong>
                          <span>Type de poste</span>
                        </strong>{" "}
                        <br />
                        <span>{job.jobType}</span> <br />{" "}
                      </>
                    )}
                    {job.vacant && (<>
                      <strong>
                        <span>Poste disponible</span>
                      </strong>{" "}
                      <br />
                      <span>{job.vacant}</span> <br />{" "}
                    </>)}
                    {job.levelStudies !== "" && (
                      <>
                        <strong>
                          <span>Niveau d'études</span>
                        </strong>{" "}
                        <br />
                        <span>{job.levelStudies}</span> <br />{" "}
                      </>
                    )}
                    {job.levelExp !== "" && (
                      <>
                        {" "}
                        <strong>
                          <span>Niveau d'éxpériences</span>
                        </strong>{" "}
                        <br />
                        <span>{job.levelExp}</span> <br />
                      </>
                    )}

                    <p></p>
                    {/* <Link to={`/send-job-application/${job.id}`}></Link>*/}
                    <div className="applyOrShareButton">  {
                      !isLogin() ?
                        <button
                          className=" c-button btn-job"
                          onClick={() => setModal(!modal)}
                        >
                          Postuler maintenant
                        </button>
                        : closed ? <button
                          className=" c-button btn-job"
                          id="btn-disabled"
                          disabled
                        >
                          Postuler maintenant
                        </button>
                          : !isLoadedOwner ? <CSpinner color="default" size="sm" />
                            : owner ? <button
                              className=" c-button btn-job"

                            >
                              <a style={{ color: 'white' }} rel="noopener"
                                role="img" aria-label="Facebook Link"
                                href={`https://www.facebook.com/sharer/sharer.php?u=https://tunisia-living.com/20222508/jobs/job-details/${job.id}/${job.sector.slug}/${job.slug}`}
                                target="_blank" className="lb_socialicon cc-fb w-inline-block">
                                <svg style={{ width: '14px', filter: 'invert(1)', verticalAlign: 'top' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" /></svg> Share via facebook
                              </a> </button>
                              : !exist ? <button
                                className=" c-button btn-job"
                                onClick={() => setModal2(!modal2)}
                              >
                                Postuler maintenant
                              </button>
                                : exist ?
                                  <button
                                    className=" c-button btn-job"
                                    id="btn-disabled"
                                    disabled
                                  >
                                    Postuler maintenant
                                  </button>
                                  : null}
                    </div>
                    <div className="shareJobButton" >
                      <button
                        className=" c-button btn-job"

                      >
                        <a style={{ color: 'white' }} rel="noopener"
                          role="img" aria-label="Facebook Link"
                          href={`https://www.facebook.com/sharer/sharer.php?u=https://tunisia-living.com/20222508/jobs/job-details/${job.id}/${job.sector.slug}/${job.slug}`}
                          target="_blank" className="lb_socialicon cc-fb w-inline-block">
                          <svg style={{ width: '14px', filter: 'invert(1)', verticalAlign: 'top' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" /></svg> Share via facebook
                        </a> </button>
                    </div>
                  </div>{" "}
                </CCol>
              </div>

              <div className="row">
                <CCol xs="12" md="12">
                  <div className="bordered">
                    <h5 className="text-blue-primary text-bold">
                      Descriptif du poste
                    </h5>
                    {ReactHtmlParser(job.description)}

                    <p></p>
                    <div className="applyOrShareButton">
                      {closed ? <button
                        className=" c-button btn-job"
                        id="btn-disabled"
                        disabled
                      >
                        Postuler maintenant
                      </button>
                        : !isLoadedOwner ? <CSpinner color="default" size="sm" />
                          : owner ? <button
                            className=" c-button btn-job"

                          >
                            <a style={{ color: 'white' }} rel="noopener"
                              role="img" aria-label="Facebook Link"
                              href={`https://www.facebook.com/sharer/sharer.php?u=https://tunisia-living.com/20222508/jobs/job-details/${job.id}/${job.sector.slug}/${job.slug}`}
                              target="_blank" className="lb_socialicon cc-fb w-inline-block">
                              <svg style={{ width: '14px', filter: 'invert(1)', verticalAlign: 'top' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" /></svg> Share via facebook
                            </a> </button>
                            : !exist ? <button
                              className=" c-button btn-job"
                              onClick={() => setModal2(!modal2)}
                            >
                              Postuler maintenant
                            </button>
                              : exist ?
                                <button
                                  className=" c-button btn-job"
                                  id="btn-disabled"
                                  disabled
                                >
                                  Postuler maintenant
                                </button>
                                : null}
                    </div>
                  </div>
                </CCol>
              </div>
            </div>
            <aside className="l-main__sidebar l-sidebar">
              <div className="l-sidebar__widget u-show-tablet-landscape">
                <div className="l-sidebar__widgetHeading c-heading c-heading--subhead c-heading--bold c-heading--underline c-posts__heading">
                  Offres d'emploi similaires
                </div>
                <ul className="c-posts ">
                  {items.map((jobOffer, index) => {
                    return (
                      <JobItem
                        key={index}
                        title={jobOffer.title}
                        company=
                        {jobOffer.createdBy.accountType.id == 2 ? jobOffer.createdBy.nom
                          : jobOffer.company ? jobOffer.company.name
                            : jobOffer.createdBy.company ? jobOffer.createdBy.company.name
                              : ''}
                        urlCompany={jobOffer.createdBy.accountType.id == 2 ? `/jobs/jobs-by-company/${jobOffer.createdBy.id}`
                          : jobOffer.company ? `/jobs/jobs-by-company/${jobOffer.company.id}`
                            : jobOffer.createdBy.company ? `/jobs/jobs-by-company/${jobOffer.createdBy.company.id}`
                              : ''}
                        url={`/jobs/job-details/${jobOffer.id}/${jobOffer.sector.slug}/${jobOffer.slug}`}
                        logo=
                        {
                          jobOffer.createdBy.accountType.id == 2 ? `${IMAGES_BASE_URL}/public/uploads/members/${jobOffer.createdBy.path}`
                            : jobOffer.company ? `${IMAGES_BASE_URL}/public/uploads/companies/${jobOffer.company.path}`
                              : jobOffer.createdBy.company.path
                                ? `${IMAGES_BASE_URL}/public/uploads/companies/${jobOffer.createdBy.company.path}`
                                : "https://www.keejob.com/static/recruiter/images/default-logo.jpg"
                        }
                      />
                    );
                  })}
                </ul>
              </div>
            </aside>
            <CModal show={modal2} onClose={setModal2}>
              <CModalHeader closeButton>
                <CModalTitle>Envoyer candidature </CModalTitle>
              </CModalHeader>
              <CModalBody>
                {isLogin() && localStorage.getItem("roles") && localStorage.getItem("roles") === "ROLE_MEMBER" ? (
                  <>
                    <SendApplication
                      title={job.title}
                      idJob={job.id}
                    ></SendApplication>
                  </>
                ) : (
                  <div className="row mt-3">
                    <div className="col-md-12">
                      {" "}
                      <Link to="/members/login"> Login </Link> ou{" "}
                      <Link to="/members/register"> Créer un compte </Link> pour
                      postuler
                    </div>
                  </div>
                )}
              </CModalBody>
            </CModal>
          </div>
          <CModal show={modal} onClose={() => setModal(!modal)}>
            <CModalHeader closeButton></CModalHeader>
            <CModalBody>Vous devez vous connecter d'abord</CModalBody>
            <CModalFooter>
              <Link className="btn-primary btn " to="/members/login">
                Login
              </Link>
              <CButton color="secondary" onClick={() => setModal(false)}>
                Cancel
              </CButton>
            </CModalFooter>
          </CModal>

        </main>}
      </div>
    );
  }
}

export default JobDetail;
