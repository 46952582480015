import React, { useEffect, useState, useContext } from "react";
import MemberContext from "./MemberContext";

import { API_BASE_URL, IMAGES_BASE_URL, keyHeader, tokenCookie } from "../../../config";
import { getBadge, textBadge } from "src/actions";
import axios from "axios";
import Moment from "react-moment";
import Loading from "src/components/myWidgets/loadingWidget";
import { Link } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import apiClient from "../apiClient";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CSpinner,
  CBadge, CDataTable
} from "@coreui/react";
function MyGoodPlans(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState(null);
  const [errorDel, setErrorDel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { plans, loadedPlans, setPlans } = useContext(MemberContext);

  const toggle = (id) => {
    setModal(!modal);
    setItem(id);
  };
  function deleteGoodPlans(id) {
    setIsLoading(true);
    apiClient
      .delete(`/goodPlans/delete/${id}`)
      .then((res) => {
        toggle();
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          setItems((items) => items.filter((x) => x.id !== id));
          setPlans((items) => items.filter((x) => x.id !== id));
          setIsLoading(false);
        }
        if (
          res.data.status.toString() ===
          "ForeignKeyConstraintViolationException"
        ) {
          setErrorDel("Vous ne pouvez pas supprimer cet élément, il a des relations");
          alert("Vous ne pouvez pas supprimer cet élément, il a des relations");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
        setErrorDel("Vous ne pouvez pas supprimer cet bon plans maintenant");
        setIsLoading(false);
      });
  }
  useEffect(() => {

    if (loadedPlans) {
      setItems(plans);
      setIsLoaded(true)
      //console.log('props plans 2 <<<<<< ', plans.length);   
    }

    //   if (props.location.state) {
    //     setIsLoaded(true);
    //     setItems(props.location.state);
    //   }
    //     else {
    // const formData = new FormData();
    // formData.append("key",tokenCookie );
    // axios.post(`${API_BASE_URL}/goodPlans/By_member`, formData, keyHeader)
    //     .then(response => {
    //       console.log('response --- ', response);
    //       setIsLoaded(true);
    //       setItems(response.data.items);

    //     })
    //     .catch(error => {
    //       setIsLoaded(true);
    //       setError('error !');
    //         console.log('error --- ', error)

    //     });
    //   }

    // axios(`${API_BASE_URL}/goodPlans/By_member/${localStorage.getItem("id")}`)
    //   .then((res) => res.data)
    //   .then(
    //     (result) => {
    //       setIsLoaded(true);
    //       setItems(result);
    //       console.log('bbbbbon',result);
    //     },
    //     (error) => {
    //       setIsLoaded(true);
    //       setError(error);
    //     }
    //   );
  }, [plans, loadedPlans]);

  const fields = [
    {
      key: "thumb",
      label: "thumb",
      // _style: { width: "20%" },
    },
    {
      key: "Actions",
      label: "Actions",
    },
  ];

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <Loading />
    );
  } else if (!items.length) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div className="noItemsText">
              Il n'y a pas encore d'annonces ici <br /><br />
              <Link to='/bon-plans/add'>
                <button className="ml-1 btn btn-info" type="button">
                  commencez à ajouter des annonces maintenant
                </button></Link>
            </div>
          </div>
        </main>
      </div>

    )
  }
  else {
    return (
      <>

        <CDataTable addTableClasses="table table-hover table-outline mb-0  d-sm-table"
          items={items}
          fields={fields}
          itemsPerPageSelect
          itemsPerPage={10}
          hover
          pagination
          scopedSlots={{
            thumb: (goodPlans) => (
              <td>
                <div className="vehicle-thumbnail">
                  <img
                    src={
                      goodPlans.mainPicture
                        ? `${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${goodPlans.mainPicture.name}`
                        : ""
                    }
                    alt=""
                  />
                </div>
                <div className="profileAnnonceCardDetails">
                  <strong>
                    <div>
                      {goodPlans.nom} <CBadge color={getBadge(goodPlans.status)}>
                        {textBadge(goodPlans.status)}
                      </CBadge>
                    </div>
                    <div>
                      {goodPlans.typeGoodPlans.label} - {" "}
                      {goodPlans.categoryGoodPlans.label}
                    </div>

                  </strong>
                  <div>
                    <strong>Numéro de série :</strong>
                    <span> {goodPlans.sn}</span>
                  </div>
                  <div>
                    <strong>Date d'ajout :{" "}</strong>
                    <span><Moment format="DD/MM/YYYY">
                      {goodPlans.createdAt}
                    </Moment></span>
                  </div>
                </div>
              </td>
            ),
            Actions: (goodPlans) => (
              <td>
                <Link
                  // to={`/members/profile/myItemDetails/goodPlans/${goodPlans.id}`}
                  to={{
                    pathname: `/members/profile/myItemDetails/goodPlans`,
                    state: goodPlans
                  }}
                >
                  <CButton>
                    <img src="assets/icons/eye.svg" />
                  </CButton></Link>
                <Link
                  // to={`/members/profile/bon-plans-edit`}
                  to={{
                    pathname: '/members/profile/bon-plans-edit',
                    state: goodPlans
                  }}
                >
                  <CButton >
                    <CIcon name="cilPencil" />
                  </CButton>
                </Link>
                <CButton
                  onClick={(e) => toggle(goodPlans.id)}
                >
                  <CIcon name="cilTrash" />
                </CButton>
              </td>
            )
          }}
        />

        <CModal show={modal} onClose={toggle}>
          <CModalHeader closeButton></CModalHeader>
          <CModalBody>Voulez-vous vraiment supprimer ce bon plans ?</CModalBody>
          <CModalFooter>
            {/* <CButton
                                  color="primary"
                                  onClick={() => {
                                    deleteGoodPlans(item);
                                  }}
                                >
                                  Oui
                                </CButton> */}
            {!isLoading && (
              <CButton
                color="primary"
                onClick={() => {
                  deleteGoodPlans(item);
                }}
              >
                Oui
              </CButton>
            )}
            {isLoading && (
              <CButton color="primary" disabled>
                <span>
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Oui</span>
              </CButton>
            )}

            <CButton color="secondary" onClick={toggle}>
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
      </>
    );
  }
}

export default MyGoodPlans;
