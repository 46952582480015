import React, { useEffect, useState, useContext } from "react";
import { API_BASE_URL, IMAGES_BASE_URL, keyHeader } from "src/config";
import axios from "axios";
import { useHistory } from "react-router-dom";
//import DateTimePicker from "react-datetime-picker";
//import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import {
  CCol,
  CNav,
  CTabContent,
  CTabs,
  CNavItem,
  CNavLink,
  CSelect,
  CFormGroup,
  CLabel,
  CButton,
  CSpinner,
  CInput,
} from "@coreui/react";
import Loading from "src/components/myWidgets/loadingWidget";
import Breadcrumb from "../Breadcrumb";
import FavoriteContext from "../FavoriteContext";

function SearchTempBonPlans() {

  const [loading, setLoading] = useState(false);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  // const [locality, setLocality] = useState("");
  // const [localities, setLocalities] = useState([]);
  const [types, setTypes] = useState([]);
  const [type, setType] = useState("1");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [typelabel, setTypelabel] = useState("Loisir");
  const [isloaded, setIsloaded] = useState(false);
  // const [datefrom, setDatefrom] = useState();
  // const [dateto, setDateto] = useState();
  // const [nom, setNom] = useState([]);
  const [isLoadedALL, setisLoadedALL] = useState(false);
  const [all, setAll] = useState([]);
  const [errorAll, setErrorALL] = useState(false);
  const [results, setresults] = useState([]);
  const [retryCount, setRetryCount] = useState(0);

  const [banner, setBanner] = useState([]);
  const { banners } = useContext(
    FavoriteContext
  );
  useEffect(() => {
    setBanner(banners.find((item) => item.position === "goodPlan"));
  }, [banners]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${API_BASE_URL}/good_plans/all`, keyHeader);
        setAll(result.data);
        setresults(result.data)
        setisLoadedALL(true);
        //console.log('res all ', retryCount ,result)
      } catch (error) {
        //console.log('errrrrr' ,  retryCount)
        if ((error.message === 'Network Error' || error.code === 'ECONNABORTED') && retryCount < 2) {
          setRetryCount((prevCount) => prevCount + 1);
        } else {
          setErrorALL(true);
          setRetryCount(9)
          //console.error('Failed to fetch data. Please check your internet connection.');
        }
      }
    };
    fetchData();
  }, [retryCount]);


  useEffect(() => {
    let isMounted = true;

    if (!localStorage.getItem("typeGoodPlans")) {
      axios(`${API_BASE_URL}/goodPlans/type-goodPlans/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            //console.log("result555", result);
            setTypes(result);
            localStorage.setItem("typeGoodPlans", JSON.stringify(result));
            var typesF = [];
            typesF = result.find((category) => category.id.toString() === "1");
            setCategories(typesF.categoryGoodPlans);

            setIsloaded(true);
          },
          (error) => {
          }
        );
    } else {
      setTypes(JSON.parse(localStorage.getItem("typeGoodPlans")));
      var typesF = [];
      typesF = JSON.parse(localStorage.getItem("typeGoodPlans")).find((category) => category.id.toString() === "1");
      setCategories(typesF.categoryGoodPlans);

      setIsloaded(true);

    }

    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            setCountries(result);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => {
          }
        );
    } else {
      setCountries(JSON.parse(localStorage.getItem("countries")));
    }

    // Clean-up:
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (isLoadedALL) {
      var results = all.filter(function (el) {
        const typec = !type || el.typeGoodPlans.id == type;
        const categoryc = !category || el.categoryGoodPlans.id == category;
        const countryc = !country || el.country.id == country;
        const cityc = !city || el.city.id == city;
        const minPricec = !minPrice || el.price >= minPrice;
        const maxPricec = !maxPrice || el.price <= maxPrice;
        //const elDate = new Date(el.datef);   
        //const datefromc = !datefrom || elDate >= datefrom;  
        //const datetoc = !dateto || elDate <= dateto;  

        return typec && categoryc && countryc && cityc && minPricec && maxPricec;
      });

      //console.log('rsddsdxxx ',results)
      setresults(results);
    }
  }, [isLoadedALL, type, category, country, city, minPrice, maxPrice]);


  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    let path = ``;
    if (type) path += `type=${type}&`
    if (category) path += `category=${category}&`
    //if(datefrom) path+=`datefrom=${datefrom}&`
    //if(dateto) path+=`dateto=${dateto}&`
    if (minPrice) path += `minPrice=${minPrice}&`
    if (maxPrice) path += `maxPrice=${maxPrice}&`
    if (country) path += `country=${country}&`
    if (city) path += `city=${city}&`

    //console.log(path.charAt(path.length - 1))
    if (path.charAt(path.length - 1) == '&') path = path.substring(0, path.length - 1)
    //console.log(path)

    history.push({
      pathname: `/bon-plans/search-result`,
      search: `?${path}`,
      state: {
        result: results,
        all: { all: all, cities: cities }
      },
    });
  }

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      //console.log("countriesF", countriesF);
    } else {
      setCity([]);
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      //console.log("citiesF", citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };
  // const handleDelegateChange = (e) => {
  //   setDelegate(e.target.value);
  //   let id = e.target.value;
  //   var delegatesF = [];
  //   console.log("iddd", id);
  //   if (id !== "0") {
  //     delegatesF = delegates.find((local) => local.id.toString() === id);
  //     setLocalities(delegatesF.localities);
  //     console.log("delegatesF", delegatesF);
  //   } else {
  //     setLocality([]);
  //     //  setModel("");
  //   }
  // };

  // const handleTypeChange = (e) => {
  //   console.log(types);
  //   setType(e.target.value);
  //   let id = e.target.value;
  //   console.log("e.target.value");
  //   console.log(e.target.value);
  //   setTimeout(() => {
  //     console.log("type");
  //     console.log(type);
  //     console.log("id");
  //     console.log(id);
  //   }, 200);

  //   var typesF = [];
  //   if (id !== "0") {
  //     typesF = types.find((category) => category.id.toString() === id);
  //     setCategories(typesF.categoryGoodPlans);
  //     console.log(typesF);
  //   } else {
  //     setCategories([]);
  //     setCategory("");
  //   }
  // };
  const handleTypeChange2 = (i, label) => {
    setType(i);
    setTypelabel(label);
    let id = i;
    var typesF = [];
    typesF = types.find((category) => category.id.toString() === id);
    setCategories(typesF.categoryGoodPlans);
    setCategory(''); setCountry(''); setCity('');
    setMinPrice(''); setMaxPrice('');
    //console.log(typesF);
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  return isloaded ? (
    <>
      <div className="vehicle-search">
        <div className="container">
          <Breadcrumb />
          <div className="row">

            <CCol
              md="6"
              className="justify-content-md-center container-vehicle"
            >
              <CTabs>
                <div className="row ">
                  <CCol xs="12" md="12">
                    <CNav variant="tabs" className="vehicule-tabs">
                      <CNavItem
                        className="vehicule-navItem bonplanIcon"
                        onClick={() => handleTypeChange2("1", "Loisir")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/loisir.svg"}
                            className="icon-vehicle2"
                            alt="loisir"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem bonplanIcon"
                        onClick={() => handleTypeChange2("2", "Soirées")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/soiree-dj.svg"}
                            className="icon-vehicle2"
                            alt="soirees"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem bonplanIcon"
                        onClick={() => handleTypeChange2("3", "Food & Drink")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/food-drink.svg"}
                            className="icon-vehicle2"
                            alt="food-drink"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem bonplanIcon"
                        onClick={() => handleTypeChange2("4", "Hotels")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/hotels.svg"}
                            className="icon-vehicle2"
                            alt="hotels"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem bonplanIcon"
                        onClick={() => handleTypeChange2("5", "Bien-Être ")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/bien-etre.svg"}
                            className="icon-vehicle2"
                            alt="bien-etre"
                          />
                        </CNavLink>
                      </CNavItem>
                      {/* <CNavItem
                        className="vehicule-navItem bonplanIcon"
                       // onClick={() => handleTypeChange2("6", "Shopping")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/shopping.svg"}
                            className="icon-vehicle2"
                            alt="shopping"
                          />
                        </CNavLink>
                      </CNavItem> */}
                    </CNav>
                  </CCol>
                </div>

                <CTabContent fade={false} className="vehicule-tabContent filteringPage">
                  <div
                    style={{
                      display: "block",
                      padding: "20px",
                      borderRadius: "4px",
                    }}
                    className="searchFormWrapper"
                  >
                    <form onSubmit={handleSubmit}>
                      <CFormGroup row>
                        {/* <CCol xs="6" md="6" style={{ display: "none" }}>
                          <CLabel htmlFor="type">Type </CLabel>
                          <CSelect
                            name="type"
                            value={type}
                            onChange={(e) => handleTypeChange(e)}
                          >
                            <option value="0"> Choisir le type</option>
                            {types.map((type) => {
                              return (
                                <option key={type.id} value={type.id}>
                                  {type.label}
                                </option>
                              );
                            })}
                          </CSelect>

                          {submitted && !type && (
                            <span className="invalid-feedback error">
                              Veuillez choisir le type
                            </span>
                          )}
                        </CCol> */}
                        <CCol xs="6" md="6">
                          <CLabel className="typeLabel">{typelabel}</CLabel>

                          {/* <CLabel htmlFor="category">Categorie</CLabel> */}
                          <CSelect
                            name="category"
                            value={category}
                            onChange={(e) => handleCategoryChange(e)}
                            disabled={isLoadedALL ? false : true}
                          >
                            <option value="0"> Choisir la categorie </option>
                            {categories.map((category) => {
                              return (
                                <option key={category.id} value={category.id}>
                                  {category.label}
                                </option>
                              );
                            })}
                          </CSelect>
                        </CCol>
                        {/* {type == 1 &&
                          (category == 1 ||
                            category == 2 ||
                            category == 3 ||
                            category == 8) && (
                            <CCol xs="6" md="6">
                              <CLabel className="typeLabel">Nom</CLabel>
                              <CInput
                                type="text"
                                name="nom"
                                value={nom}
                                onChange={(e) => {
                                  setNom(e.target.value);
                                  console.log("from");
                                  console.log(datefrom);
                                  console.log("to");
                                  console.log(dateto);
                                }}
                              ></CInput>
                            </CCol>
                          )} */}
                      </CFormGroup>
                      {/* {type == 1 &&
                        (category == 1 ||
                          category == 2 ||
                          category == 3 ||
                          category == 8) && (
                          <>
                            <CFormGroup row className="datepickersearch">
                              <CCol xs="6" md="6">
                                <CFormGroup>
                                  <span>Du</span>
                                  <br />
                                  <DatePicker
                                    value={datefrom}
                                    onChange={setDatefrom}
                                    disabled = {isLoadedALL? false : true} 
                                  />
                                </CFormGroup>
                              </CCol>
                              <CCol xs="6" md="6">
                                <CFormGroup>
                                  <span>Au</span>
                                  <br />
                                  <DatePicker
                                    value={dateto}
                                    onChange={setDateto}
                    disabled = {isLoadedALL? false : true} 
                                  />
                                </CFormGroup>
                              </CCol>
                            </CFormGroup>
                          </>
                        )} */}
                      {
                        //!(
                        //type == 1 &&
                        //(category == 1 ||
                        //category == 2 ||
                        // category == 3 ||
                        //   category == 8)
                        //) && 
                        (
                          <>
                            <CFormGroup row>
                              <CCol xs="6" md="6" className="min-max2">
                                <CFormGroup>
                                  {/* <span>Prix a</span> */}
                                  <div className="inputWithSuffix">
                                    <CInput
                                      disabled={isLoadedALL ? false : true}
                                      type="number"
                                      name="minPrice"
                                      min={1}
                                      // max={maxpriceconst}
                                      value={minPrice}
                                      placeholder={"Prix de "}
                                      onChange={(e) => setMinPrice(parseInt(e.target.value))}
                                    ></CInput>
                                    <span className="suf">TND</span> </div>
                                </CFormGroup>
                              </CCol>
                              <CCol xs="6" md="6" className="min-max2">
                                <CFormGroup>
                                  {/* <span>de</span> */}
                                  <div className="inputWithSuffix">
                                    <CInput
                                      disabled={isLoadedALL ? false : true}
                                      min={1}
                                      // max={maxpriceconst}
                                      type="number"
                                      name="maxPrice"
                                      placeholder={"Prix à "}
                                      value={maxPrice}
                                      onChange={(e) => setMaxPrice(parseInt(e.target.value))}
                                    ></CInput>
                                    <span className="suf">TND</span> </div>
                                </CFormGroup>
                              </CCol>
                            </CFormGroup>
                          </>
                        )}
                      <CFormGroup row>
                        <CCol xs="6" md="6">
                          <CFormGroup>
                            <CSelect
                              name="country"
                              value={country}
                              onChange={(e) => handleCountryChange(e)}
                              disabled={isLoadedALL ? false : true}
                            >
                              <option value="0"> Choisissez un pays</option>
                              {countries.map((country) => {
                                return (
                                  <option key={country.id} value={country.id}>
                                    {country.label}
                                  </option>
                                );
                              })}
                            </CSelect>
                          </CFormGroup>
                        </CCol>
                        <CCol xs="6" md="6">
                          <CFormGroup>
                            <CSelect
                              name="city"
                              value={city}
                              onChange={(e) => handleCityChange(e)}
                              disabled={isLoadedALL ? false : true}
                            >
                              <option value="0"> Gouvernorat</option>
                              {cities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.label}
                                  </option>
                                );
                              })}
                            </CSelect>
                          </CFormGroup>
                        </CCol>
                      </CFormGroup>

                      {/* <CFormGroup row>
                        <CCol xs="6" md="6">
                          <CFormGroup>
                            Délégation
                            <CSelect
                              name="delegate"
                              value={delegate}
                              onChange={(e) => handleDelegateChange(e)}
                            >
                              <option value="0">
                                {" "}
                                Choisissez une délégation
                              </option>
                              {delegates.map((delegate) => {
                                return (
                                  <option key={delegate.id} value={delegate.id}>
                                    {delegate.label}
                                  </option>
                                );
                              })}
                            </CSelect>
                          </CFormGroup>
                        </CCol>
                        <CCol xs="6" md="6">
                          <CFormGroup>
                            Localité
                            <CSelect
                              name="locality"
                              value={locality}
                              onChange={(e) => setLocality(e.target.value)}
                            >
                              <option value="0"> Localité</option>
                              {localities.map((local) => {
                                return (
                                  <option key={local.id} value={local.id}>
                                    {local.label}
                                  </option>
                                );
                              })}
                            </CSelect>
                          </CFormGroup>
                        </CCol>
                      </CFormGroup> */}

                      <CFormGroup row>
                        <CCol xs="6" md="6"></CCol>
                        <CCol xs="6" md="6">
                          {!isLoadedALL && retryCount > 0 && retryCount < 4 ?
                            <CButton className=" btn-search" disabled>
                              <span>
                                {" "}
                                <CSpinner color="default" size="sm" />
                              </span> <span> Erreur réseau ! 😟 réessaye </span>
                            </CButton>
                            :
                            errorAll ?
                              <CButton className=" btn-search" disabled>
                                <span> Error Chercher ! 😟 <br />Vérifiez votre connection internet</span>
                              </CButton>
                              : !loading && isLoadedALL ? (
                                <CButton className=" btn-search" type="submit">
                                  <span> {results.length} résultats</span>
                                </CButton>
                              )
                                : !loading && isLoadedALL ? (
                                  <CButton className=" btn-search" type="submit">
                                    <span> {results.length} résultats</span>
                                  </CButton>
                                )
                                  : <CButton className=" btn-search" disabled>
                                    <span>
                                      {" "}
                                      <CSpinner color="default" size="sm" />
                                    </span>

                                    <span> Chercher </span>
                                  </CButton>
                          }
                        </CCol>
                      </CFormGroup>
                    </form>
                  </div>
                </CTabContent>
              </CTabs>
            </CCol>
            <CCol md="6">
              <div className="sideImgWrapper">
                {banner &&
                  <img
                    src={IMAGES_BASE_URL + '/public/uploads/banners/' + banner.path}
                    alt={banner.alt}
                  />
                }
              </div>
            </CCol>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Loading />
  );
}

export default SearchTempBonPlans;
