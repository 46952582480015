import React, { useEffect, useState } from "react";
import { API_BASE_URL, keyHeader } from "../../../../config";
import axios from "axios";
//import { Range } from "rc-slider";
import { useHistory } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
//import 'rc-slider/assets/index.css';
import {
  CCol,
  CSelect,
  CFormGroup,
  CInputRadio,
  CLabel,
  CButton,
  CInput,
  CSpinner,
} from "@coreui/react";
import Loading from "src/components/myWidgets/loadingWidget";

export default function SearchTruck(props) {
  const [makes, setMakes] = useState([]);
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [status, setStatus] = useState(null);
  const [bodyStyle, setBodyStyle] = useState("");
  const [year, setYear] = useState("");
  const [fuel, setFuel] = useState("");
  const [transmission, setTransmission] = useState("");
  const [boite, setBoite] = useState("");
  const [minMileage, setMinMileage] = useState("");
  const [maxMileage, setMaxMileage] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [axleConfig, setAxleConfig] = useState("");
  const [loading, setLoading] = useState(false);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [isLoaded1, setIsLoaded1] = useState(false);
  const [isLoaded2, setIsLoaded2] = useState(false);
  const [warranty, setWarranty] = useState("");
  const [isLoadedALL, setisLoadedALL] = useState(false);
  const [all, setAll] = useState([]);
  const [errorAll, setErrorALL] = useState(false);
  const [results, setresults] = useState([]);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${API_BASE_URL}/annonce_camion/all`, keyHeader);
        setAll(result.data);
        setresults(result.data)
        setisLoadedALL(true);
        //console.log('res all ', retryCount ,result)
      } catch (error) {
        //console.log('errrrrr' ,  retryCount)
        // If there is a network error and retries are less than 3, retry the request
        if ((error.message === 'Network Error' || error.code === 'ECONNABORTED') && retryCount < 2) {
          setRetryCount((prevCount) => prevCount + 1);
        } else {
          // After 3 retries, log a message to check the internet connection
          setErrorALL(true);
          setRetryCount(9)
          //console.error('Failed to fetch data. Please check your internet connection.');
        }
      }
    };

    // Call the fetchData function
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retryCount]);


  useEffect(() => {
    if (!localStorage.getItem("truckBrands")) {
      axios(`${API_BASE_URL}/vehicles/truck-brands`)
        .then((res) => res.data)
        .then(
          (result) => {
            setMakes(result);
            setIsLoaded1(true);
            localStorage.setItem("truckBrands", JSON.stringify(result));
          },
          (error) => {
            // console.log(error);
            setIsLoaded1(true);
          }
        );
    } else {
      setMakes(JSON.parse(localStorage.getItem("truckBrands")));
      setIsLoaded1(true);
    }

    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            //  console.log("countries", result);
            setCountries(result);
            setIsLoaded2(true);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => { }
        );
    } else {
      //console.log("countris2222222");
      // console.log(localStorage.getItem("countries"));
      setIsLoaded2(true);
      setCountries(JSON.parse(localStorage.getItem("countries")));
    }
  }, []);

  useEffect(() => {
    //  handleFilter(e);
    //console.log(make,model)
    var results = all.filter(function (el) {
      const statusc = status === null || el.state == status;
      const makec = !make || el.brand.id == make;
      const modelc = model > 0 ? el.model.id == model : true;
      //userInputAge > 0 ? item.age === userInputAge : true;
      const yearc = year > 0 ? el.year == year : true;
      const bodyStylec = !bodyStyle || el.bodyStyle == bodyStyle;
      const fuelc = !fuel || el.fuel == fuel;
      const boitec = !boite || el.boite == boite;
      const warrantyc = !warranty || el.warranty == warranty;
      const countryc = !country || el.country.id == country;
      const cityc = !city || el.city.id == city;
      const minPricec = !minPrice || el.price >= minPrice;
      const maxPricec = !maxPrice || el.price <= maxPrice;
      const minMileagec = !minMileage || el.mileage >= minMileage;
      const maxMileagec = !maxMileage || el.mileage <= maxMileage;

      return statusc && makec && modelc && bodyStylec && yearc && fuelc && boitec && warrantyc && countryc && cityc && minPricec && maxPricec && minMileagec && maxMileagec;
    });
    //console.log('rsddsdxxx ',results)
    setresults(results);
  }, [status, make, model, bodyStyle, year, fuel, boite, warranty, country, city, minPrice, maxPrice, minMileage, maxMileage]);


  let history = useHistory();

  const getYear = () => {
    const years = [];
    for (let i = new Date().getFullYear(); i > 1950; i--) {
      years.push(
        <option value={i} key={i}>
          {" "}
          {i}
        </option>
      );
    }
    return years;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let path = ``;
    if (make) path += `make=${make}&`
    if (model) path += `model=${model}&`
    if (year) path += `year=${year}&`
    if (bodyStyle) path += `bodyStyle=${bodyStyle}&`
    if (fuel) path += `fuel=${fuel}&`
    if (boite) path += `boite=${boite}&`
    if (minMileage) path += `minMileage=${minMileage}&`
    if (maxMileage) path += `maxMileage=${maxMileage}&`
    if (minPrice) path += `minPrice=${minPrice}&`
    if (maxPrice) path += `maxPrice=${maxPrice}&`
    if (warranty) path += `warranty=${warranty}&`
    if (country) path += `country=${country}&`
    if (city) path += `city=${city}&`
    if (status) path += `status=${status}&`

    //console.log(path.charAt(path.length - 1))
    if (path.charAt(path.length - 1) == '&') path = path.substring(0, path.length - 1)
    //console.log(path)

    history.push({
      pathname: "/vehicles/camions/search-result",
      search: `?${path}`,
      state: {
        result: results,
        all: { all: all, cities: cities }
      },
    });
  }


  const handleSubmitOld = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    formData.append("brand", make);
    formData.append("state", status);
    formData.append("model", model);
    formData.append("bodyStyle", bodyStyle);
    formData.append("year", year);
    formData.append("fuel", fuel);
    formData.append("transmission", transmission);
    formData.append("minMileage", minMileage);
    formData.append("maxMileage", maxMileage);
    formData.append("minPrice", minPrice);
    formData.append("maxPrice", maxPrice);
    formData.append("axleConfig", axleConfig);
    formData.append("warranty", warranty);
    formData.append("country", country);
    formData.append("city", city);
    axios
      .post(API_BASE_URL + "/annonces_camions/search", formData)
      .then((res) => {
        // console.log(res.data);
        history.push({
          pathname: "/vehicles/camions/search-result",
          state: { result: res.data },
        });
        setLoading(false);
        // console.log(props);
      })
      .catch((error) => {
        // console.log(" errors : ", error);
        setLoading(false);
      });
  };
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      //console.log("countriesF", countriesF);
    } else {
      setCity([]);
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    // console.log("iddd", id);
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      //  console.log("citiesF", citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };
  const handleDelegateChange = (e) => {
    setDelegate(e.target.value);
    let id = e.target.value;
    var delegatesF = [];
    // console.log("iddd", id);
    if (id !== "0") {
      delegatesF = delegates.find((local) => local.id.toString() === id);
      setLocalities(delegatesF.localities);
      // console.log("delegatesF", delegatesF);
    } else {
      setLocality([]);
      //  setModel("");
    }
  };
  if (isLoaded1 && isLoaded2) {
    return (
      <>
        <form onSubmit={handleSubmit} className="searchPage">
          <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup variant="custom-checkbox" inline>
                <CFormGroup variant="checkbox" inline>
                  <CInputRadio
                    className="form-check-input"
                    id="1"
                    name="status"
                    value="1"
                    onChange={() => setStatus(1)}
                    disabled={isLoadedALL ? false : true}
                  />
                  <CLabel variant="checkbox" htmlFor="neuf">
                    Neuf
                  </CLabel>
                </CFormGroup>
                <CFormGroup variant="checkbox" inline>
                  <CInputRadio
                    className="form-check-input"
                    id="0"
                    name="status"
                    value="0"
                    onChange={() => setStatus(0)}
                    disabled={isLoadedALL ? false : true}
                  />
                  <CLabel variant="checkbox" htmlFor="occasion">
                    Occasion
                  </CLabel>
                </CFormGroup>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6"></CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol xs="6" md="6">
              <CSelect
                name="bodystyle"
                value={bodyStyle}
                onChange={(e) => setBodyStyle(e.target.value)}
                disabled={isLoadedALL ? false : true}
              >
                <option value=""> Type de véhicule</option>
                <option value="Benne">Benne</option>
                <option value="Chassis">Châssis</option>
                <option value="Citerne">Citerne</option>
                <option value="Fourgon">Fourgon</option>
                <option value="Frigorifique">Frigorifique</option>
                <option value="Plateau">Plateau</option>
                <option value="Polybenne">Polybenne</option>
                <option value="Porte-conteneurs">Porte conteneurs</option>
                <option value="Porte-engins">Porte engins</option>
                <option value="Savoyarde">Savoyarde</option>
                <option value="Utilitaire">Utilitaire</option>
                <option value="Pick up">Pick Up</option>
              </CSelect>
            </CCol>
            <CCol xs="6" md="6">
              <CSelect
                name="make"
                value={make}
                onChange={(e) => setMake(e.target.value)}
                disabled={isLoadedALL ? false : true}
              >
                <option value="0"> Choisir la marque</option>
                {makes.map((make) => {
                  return (
                    <option key={make.id} value={make.id}>
                      {make.label}
                    </option>
                  );
                })}
              </CSelect>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            {/* <CCol xs="6" md="6">
              <CSelect
                name="axleConfig"
                value={axleConfig}
                onChange={(e) => setAxleConfig(e.target.value)}
                disabled = {isLoadedALL? false : true} 
              >
                <option value="">Configuration essieux</option>
                <option value="4x2">4x2</option>
                <option value="4x4"> 4x4</option>
                <option value="6x2">6x2</option>
                <option value="6x4">6x4</option>
                <option value="6x6">6x6</option>
                <option value="8x2">8x2</option>
                <option value="8x4">8x4</option>
                <option value="8x6">8x6</option>
                <option value="8x8">8x8</option>
                <option value="10x4">10x4</option>
                <option value="10x8">10x8</option>
                <option value="12x6">12x6</option>
                <option value="2 essieux">2 essieux</option>
                <option value="3 essieux">3 essieux</option>
                <option value=">3 essieux"> {">"}3 essieux</option>
              </CSelect>
            </CCol> */}
            <CCol xs="6" md="6">
              <CSelect
                name="year"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                disabled={isLoadedALL ? false : true}
              >
                <option value=""> 1ère immatriculation</option>
                {getYear()}
              </CSelect>
            </CCol>
            <CCol xs="6" md="6">
              <CSelect
                disabled={isLoadedALL ? false : true}
                name="warranty"
                value={warranty}
                onChange={(e) => setWarranty(e.target.value)}
              >
                <option value="">Garantie</option>
                <option value="0">0</option>
                <option value="moins 1"> Moins d'une année</option>
                <option value="">1 an</option>
                <option value="Automatique">2 ans</option>
              </CSelect>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs="6" md="6">
              <CSelect
                name="fuel"
                value={fuel}
                onChange={(e) => setFuel(e.target.value)}
                disabled={isLoadedALL ? false : true}
              >
                <option value="">Carburant</option>
                <option value="Essence">Essence</option>
                <option value="Diesel">Diesel</option>
              </CSelect>
            </CCol>
            <CCol xs="6" md="6">
              <CSelect
                name="boite"
                value={boite}
                onChange={(e) => setBoite(e.target.value)}
                disabled={isLoadedALL ? false : true}
              >
                <option value="">Boite</option>
                <option value="Manuelle"> Manuelle</option>
                <option value="Semi-automatique"> Semi-automatique</option>
                <option value="Automatique">Automatique</option>
              </CSelect>
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol xs="6" md="6" className="min-max2">
              <CFormGroup>
                {/* <span>Kilométrage a</span> */}
                <div className="inputWithSuffix">
                  <CInput
                    disabled={isLoadedALL ? false : true}
                    type="number"
                    name="minMileage"
                    min={0}
                    // max={maxmileconst}
                    value={minMileage}
                    placeholder={
                      "Kilométrage de "}
                    onChange={(e) => {
                      setMinMileage(parseInt(e.target.value));
                    }}
                  ></CInput>
                  <span className="suf">km</span> </div>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6" className="min-max2">
              <CFormGroup>
                {/* <span>de</span> */}
                <div className="inputWithSuffix">
                  <CInput
                    disabled={isLoadedALL ? false : true}
                    min={1}
                    // max={maxmileconst}
                    type="number"
                    name="maxMileage"
                    placeholder={
                      "Kilométrage à "}
                    value={maxMileage}
                    onChange={(e) => {
                      setMaxMileage(parseInt(e.target.value));
                    }}
                  ></CInput>
                  <span className="suf">km</span> </div>
              </CFormGroup>
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol xs="6" md="6" className="min-max2">
              <CFormGroup>
                {/* <span>Prix a</span> */}
                <div className="inputWithSuffix">
                  <CInput
                    disabled={isLoadedALL ? false : true}
                    type="number"
                    name="minPrice"
                    min={1}
                    // max={maxpriceconst}
                    value={minPrice}
                    placeholder={"Prix de "}
                    onChange={(e) => setMinPrice(parseInt(e.target.value))}
                  ></CInput>
                  <span className="suf">TND</span> </div>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6" className="min-max2">
              <CFormGroup>
                {/* <span>de</span> */}
                <div className="inputWithSuffix">
                  <CInput
                    disabled={isLoadedALL ? false : true}
                    min={1}
                    // max={maxpriceconst}
                    type="number"
                    name="maxPrice"
                    placeholder={"Prix à "}
                    value={maxPrice}
                    onChange={(e) => setMaxPrice(parseInt(e.target.value))}
                  ></CInput>
                  <span className="suf">TND</span> </div>
              </CFormGroup>
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup>
                {/* Pays */}
                <CSelect
                  disabled={isLoadedALL ? false : true}
                  name="country"
                  value={country}
                  onChange={(e) => handleCountryChange(e)}
                >
                  <option value="0"> Choisissez un pays</option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6">
              <CFormGroup>
                {/* Gouvernorat */}
                <CSelect
                  disabled={isLoadedALL ? false : true}
                  name="city"
                  value={city}
                  onChange={(e) => handleCityChange(e)}
                >
                  <option value="0"> Gouvernorat</option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CFormGroup>


          <CFormGroup row>
            <CCol xs="6" md="6"></CCol>
            <CCol xs="6" md="6">
              {!isLoadedALL && retryCount > 0 && retryCount < 4 ?
                <CButton className=" btn-search" disabled>
                  <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span> <span> Erreur réseau ! 😟 réessaye </span>
                </CButton>
                :
                errorAll ?
                  <CButton className=" btn-search" disabled>
                    <span> Error Chercher ! 😟 <br />Vérifiez votre connection internet</span>
                  </CButton>
                  : !loading && isLoadedALL ? (
                    <CButton className=" btn-search" type="submit">
                      <span> {results.length} résultats</span>
                    </CButton>
                  )
                    : !loading && isLoadedALL ? (
                      <CButton className=" btn-search" type="submit">
                        <span> {results.length} résultats</span>
                      </CButton>
                    )
                      : <CButton className=" btn-search" disabled>
                        <span>
                          {" "}
                          <CSpinner color="default" size="sm" />
                        </span>

                        <span> Chercher </span>
                      </CButton>
              }
            </CCol>
          </CFormGroup>
        </form>
      </>
    );
  } else
    return (
      <div className="container">
        <main className="l-main lmain2">
          <div className="l-section l-section--archive">
            <div className="loading_logo">
              <img
                src={"assets/icons/tunisia-living-loading-icon.gif"}
                alt="tunisia living loading icon"
              />
              <p>Chargement...</p>
            </div>
          </div>
        </main>
      </div>
    );
}
