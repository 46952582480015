import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom";
import dateFormat from "dateformat";
import { logoutLocal } from 'src/components/middleware/auth';
import {
  CBadge,
  CAlert,
  CRow,
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CSpinner,
  CSelect,
  CModalHeader,
  CModalBody,
  CModal,
  CModalFooter,
  CInputCheckbox
} from "@coreui/react";

import {
  API_BASE_URL, IMAGES_BASE_URL, tokenCookie, keyHeader, sizeAlert,
  realEstateEquipementsTypes, realEstateEquipementsAll
} from 'src/config';
import axios from "axios";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import imageCompression from "browser-image-compression";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CIcon from "@coreui/icons-react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Loading from 'src/components/myWidgets/loadingWidget';
import { Basic, Scroll, Eventt, HousePlan, HouseSetting, Adresse, Contact, Extra, Imgs } from "src/components/myWidgets/formTitles";
import { getBadge, textBadge } from 'src/actions';
import { addWatermark, addWatermarkToAlbum } from "src/actions";
import MemberContext from '../members/MemberContext';
import apiClient from '../apiClient';

const EditRealEstate = (props) => {
  /****** reale estate  *********/
  // const [error] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedeq, setIsLoadedeq] = useState(false);
  const [isLoadedeq1, setIsLoadedeq1] = useState(false);
  const [isLoadedeq2, setIsLoadedeq2] = useState(false);
  const [isLoadedeq3, setIsLoadedeq3] = useState(false);
  const [annonce, setAnnonce] = useState(null);
  //const [setImgPrincipal] = useState(null); 
  const [otherImages, setOtherImages] = useState([]);
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState(null);
  const [setErrorDel] = useState("");
  const [setItems] = useState([]);
  const [typeImage, setTypeImage] = useState(false);
  const [index, setIndex] = useState([]);
  const [idRealEstate, setIdRealEstate] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState("");
  const [pImageThumb, setpImageThumb] = useState(null);
  const [oldImgP, setoldImgp] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [id, setId] = useState(null)


  const [price, setPrice] = useState(null);
  const [album, setAlbum] = useState([]);
  const [imgPrincipal, setImgPrincipal] = useState(null);
  const [description, setDescription] = useState("");
  const [surface, setSurface] = useState("");
  const [title, setTitle] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [typeAnnonce, setTypeAnnonce] = useState("");
  const [newPhone, setNewPhone] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [doneuploadingfile, setDoneuploadingfile] = useState(true);
  const [uploadingImage, setUploadingImage] = useState(false);

  const [imgCount, setImgCount] = useState(0);
  const [imgCountA, setImgCountA] = useState(0);
  const [abortControllers, setAbortControllers] = useState({});
  const [removedfiles, setremovedfiles] = useState([]);
  // const { id } = props.match.params;


  const [typeLocal, setTypeLocal] = useState("");


  const [equipmentsCommun, setEquipmentsCommun] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [realEstateEquipements, setRealEstateEquipements] = useState([]);
  const [realEstateEquipementsType, setRealEstateEquipementsType] = useState([]);
  const [nbTerrase, setNbrTerrase] = useState("");
  const [nbBalcon, setNbrBalcon] = useState("");
  const [nbrElevators, setNbrElevators] = useState("");
  const [nbrBathroom, setNbrBathroom] = useState("");
  const [nbrRoom, setNbrRoom] = useState("");
  const [nbrPiece, setNbrPiece] = useState("");
  const [nbParkingSpace, setNbrParkingSpace] = useState("");
  const [typeApartment, setTypeApartment] = useState("");
  const [dateDep, setDateDep] = useState("");
  const [dateArrival, setDateArrival] = useState("");


  const [nbrGarden, setNbrGarden] = useState("");
  const [nbrCavesSol, setNbrCavesSol] = useState("");
  const [nbrGarage, setNbrGarage] = useState("");
  const [nbrFloor, setNbrFloor] = useState("");
  const [typeHouse, setTypeHouse] = useState("");
  const [nbPool, setNbPool] = useState("");


  const [typeTerrain, setTypeTerrain] = useState("");


  const [typeRealEstate, setTypeRealEstate] = useState("");

  const [hasChanged, sethasChanged] = useState(false);
  const { estates, loadedEstates, setEstates } = useContext(MemberContext);


  useEffect(() => {
    if (annonce && !hasChanged &&
      (title != annonce.title || (price && price != annonce.price)
        || nbrFloor != annonce.nbFloors || nbrPiece != annonce.nbPieces
        || nbrRoom != annonce.nbRooms || nbrBathroom != annonce.nbBathroom
        || nbrGarage != annonce.nbGarages || nbrCavesSol != annonce.nbCaveSousSol
        || nbrElevators != annonce.nbElevators || nbrGarden != annonce.nbGardens
        || nbPool != annonce.nbPool || nbParkingSpace != annonce.nbParkingSpace
        || nbBalcon != annonce.nbBalcon || nbTerrase != annonce.nbTerrase
        || surface != annonce.surface || typeAnnonce != annonce.typeAnnonce
        || (annonce.typeHouse && typeHouse != annonce.typeHouse)
        || (annonce.typeGround && typeTerrain != annonce.typeGround)
        || (annonce.typeNewRealEstate && typeRealEstate != annonce.typeNewRealEstate)
        || (annonce.typeCommercialSpace && typeLocal != annonce.typeCommercialSpace)
        || (annonce.typeApartment && typeApartment != annonce.typeApartment)
        || country != annonce.country.id || city != annonce.city.id
        || delegate != annonce.delegate.id || locality != annonce.locality.id
        || description != annonce.description || (!newPhone && phoneNumber == '') || phoneNumber != annonce.phoneNumber
        || pImageThumb || imgCountA > 0
      )
    ) {
      sethasChanged(true);
      //console.log(' in use : ' , album.length)
    }

  },
    [price, surface, typeAnnonce, typeHouse, typeTerrain, typeRealEstate, typeLocal, typeApartment
      , nbrPiece, nbrFloor, nbrRoom, nbrBathroom, nbrGarage, nbrCavesSol, nbrElevators
      , nbrGarden, nbPool, nbParkingSpace, nbTerrase, nbBalcon
      , title, imgCountA, imgPrincipal, description, phoneNumber, newPhone, locality, delegate, city, country]
  );

  const history = useHistory();

  useEffect(() => {
    let result = props.location.state;
    setId(props.location.state.id);

    //console.log('show real estate', result)
    setAnnonce(result)
    setIsLoaded(true);

    setTitle(result.title)
    setPhoneNumber(result.phoneNumber)
    setPrice(result.price)
    setSurface(result.surface)
    setTypeAnnonce(result.typeAnnonce)

    setCountry(result.country.id)
    //setCities(result.country.cities)
    setCity(result.city.id)
    //setDelegates(result.city.delegations)
    setDelegate(result.delegate.id)
    // setLocalities(result.delegate.localities)
    setLocality(result.locality.id)
    setDescription(result.description)

    if (result.mainPicture) {
      setImgPrincipal(`${IMAGES_BASE_URL}/public/uploads/images/${result.type}/${result.mainPicture.name}`)
      setoldImgp(true); // heeeeere   

    }

    if (result.images) {
      setOtherImages(result.images)
    }

    if (result.typeGround) setTypeTerrain(result.typeGround)
    if (result.typeNewRealEstate) setTypeRealEstate(result.typeNewRealEstate)
    if (result.typeCommercialSpace) setTypeLocal(result.typeCommercialSpace)

    if (result.type == 'house') {

      //   if(result.typeAnnonce==='annonce_location')
      //     {axios(`${API_BASE_URL}/realEstate/equipements/loc_vacance`)
      //   .then((res) => res.data)
      //   .then(
      //     (resultt) => {
      //       if (resultt[0] === "No equipement found!") {
      //         if (result.equipement) {
      //           result.equipement.forEach(element => {
      //             equipmentsCommun.forEach((elementtab, i) => {
      //               if (element.id === equipmentsCommun[i].id) {
      //                 equipmentsCommun[i].checked = true
      //               }
      //             });
      //           });
      //         }
      //         console.log('equipmentsCommun :', equipmentsCommun)
      //         setRealEstateEquipements(equipmentsCommun);
      //       }
      //       else {
      //         let newArray = [...equipmentsCommun, ...resultt]
      //         if (result.equipement) {
      //           result.equipement.forEach(element => {
      //             newArray.forEach((elementtab, i) => {
      //               if (element.id === newArray[i].id) {
      //                 newArray[i].checked = true
      //               }
      //             });
      //           });
      //         }
      //         setRealEstateEquipements(newArray);
      //         console.log('newArray :', newArray)

      //       }
      //       setIsLoadedeq1(true);
      //     },
      //     (error) => {
      //       setError(error);
      //     }
      //   );
      // }
      //   else if(result.typeAnnonce==='annonce_vente')
      //   {
      //     axios(`${API_BASE_URL}/realEstate/equipements/loc_vente`)
      //     .then((res) => res.data)
      //     .then(
      //       (resultt) => {
      //         if (resultt[0] === "No equipement found!") {
      //           if (result.equipement) {
      //             result.equipement.forEach(element => {
      //               equipmentsCommun.forEach((elementtab, i) => {
      //                 if (element.id === equipmentsCommun[i].id) {
      //                   equipmentsCommun[i].checked = true
      //                 }
      //               });
      //             });
      //           }
      //           setRealEstateEquipements(equipmentsCommun);
      //         }
      //         else {
      //           let newArray = [...equipmentsCommun, ...resultt]
      //           if (result.equipement) {
      //             result.equipement.forEach(element => {
      //               newArray.forEach((elementtab, i) => {
      //                 if (element.id === newArray[i].id) {
      //                   newArray[i].checked = true
      //                 }
      //               });
      //             });
      //           }
      //           setRealEstateEquipements(newArray);
      //         }
      //         setIsLoadedeq2(true)

      //       },
      //       (error) => {
      //         setError(error);
      //       }
      //     );

      //   } else {
      //     axios(`${API_BASE_URL}/realEstate/equipements/all`)
      //         .then((res) => res.data)
      //         .then(
      //           (resultt) => {
      //             if (resultt[0] === "No equipement found!") {
      //               if (result.equipement) {
      //                 result.equipement.forEach(element => {
      //                   equipmentsCommun.forEach((elementtab, i) => {
      //                     if (element.id === equipmentsCommun[i].id) {
      //                       equipmentsCommun[i].checked = true
      //                     }
      //                   });
      //                 });
      //               }
      //               setRealEstateEquipements(equipmentsCommun);
      //             }
      //             else {
      //               let newArray = [...equipmentsCommun, ...resultt]
      //               if (result.equipement) {
      //                 result.equipement.forEach(element => {
      //                   newArray.forEach((elementtab, i) => {
      //                     if (element.id === newArray[i].id) {
      //                       newArray[i].checked = true
      //                     }
      //                   });
      //                 });
      //               }
      //               setRealEstateEquipements(newArray);
      //             }
      //             setIsLoadedeq3(true)
      //           },
      //           (error) => {
      //             setError(error);
      //           }
      //         );
      //   }

      if (result.departureDate) setDateDep(dateFormat(result.departureDate, "yyyy-mm-dd"))
      if (result.arrivalDate) setDateArrival(dateFormat(result.arrivalDate, "yyyy-mm-dd"))
      setTypeHouse(result.typeHouse)
      setNbrFloor(result.nbFloors)
      setNbrPiece(result.nbPieces)
      setNbrRoom(result.nbRooms)
      setNbrBathroom(result.nbBathroom)
      setNbrGarage(result.nbGarages)
      setNbrCavesSol(result.nbCaveSousSol)
      setNbrElevators(result.nbElevators)
      setNbrGarden(result.nbGardens)
      setNbrBalcon(result.nbBalcon)
      setNbrTerrase(result.nbTerrase)
      setNbPool(result.nbPool)
      setNbrParkingSpace(result.nbParkingSpace)

      let nCheckbox = []
      if (result.equipement) {
        result.equipement.forEach(element => {
          nCheckbox.push(element.id + "");
        })
      }
      let rea = 1;
      //console.log('nCheckboxgchgvhb', nCheckbox , equipments.includes(rea.toString()))
      setEquipments(nCheckbox);
    }

    if (result.type == 'apartment') {

      //   if(result.typeAnnonce==='annonce_location') {
      //     axios(`${API_BASE_URL}/realEstate/equipements/loc_vacance`)
      //     .then((res) => res.data)
      //     .then(
      //       (resultt) => {
      //         if (resultt[0] === "No equipement found!") {
      //           if (result.equipement) {
      //             result.equipement.forEach(element => {
      //               equipmentsCommun.forEach((elementtab, i) => {
      //                 if (element.id === equipmentsCommun[i].id) {
      //                   equipmentsCommun[i].checked = true
      //                 }
      //               });
      //             });
      //           }
      //           setRealEstateEquipements(equipmentsCommun);
      //         }
      //         else {
      //           let newArray = [...equipmentsCommun, ...resultt]
      //           if (result.equipement) {
      //             result.equipement.forEach(element => {
      //               newArray.forEach((elementtab, i) => {
      //                 if (element.id === newArray[i].id) {
      //                   newArray[i].checked = true
      //                 }
      //               });
      //             });
      //           }
      //           setRealEstateEquipements(newArray);
      //         }
      //         setIsLoadedeq1(true)
      //       },
      //       (error) => {
      //         setError(error);
      //       }
      //     );
      //   }
      //   else if(result.typeAnnonce==='annonce_vente')
      // {

      //   axios(`${API_BASE_URL}/realEstate/equipements/loc_vente`)
      //   .then((res) => res.data)
      //   .then(
      //     (resultt) => {
      //       if (resultt[0] === "No equipement found!") {
      //         if (result.equipement) {
      //           result.equipement.forEach(element => {
      //             equipmentsCommun.forEach((elementtab, i) => {
      //               if (element.id === equipmentsCommun[i].id) {
      //                 equipmentsCommun[i].checked = true
      //               }
      //             });
      //           });
      //         }
      //         setRealEstateEquipements(equipmentsCommun);
      //       }
      //       else {
      //         let newArray = [...equipmentsCommun, ...resultt]
      //         if (result.equipement) {
      //           result.equipement.forEach(element => {
      //             newArray.forEach((elementtab, i) => {
      //               if (element.id === newArray[i].id) {
      //                 newArray[i].checked = true
      //               }
      //             });
      //           });
      //         }
      //         setRealEstateEquipements(newArray);
      //       }
      //       setIsLoadedeq2(true)

      //     },
      //     (error) => {
      //       setError(error);
      //     }
      //   );

      // } else {
      //   axios(`${API_BASE_URL}/realEstate/equipements/all`)
      //   .then((res) => res.data)
      //   .then(
      //     (resultt) => {
      //       if (resultt[0] === "No equipement found!") {
      //         if (result.equipement) {
      //           result.equipement.forEach(element => {
      //             equipmentsCommun.forEach((elementtab, i) => {
      //               if (element.id === equipmentsCommun[i].id) {
      //                 equipmentsCommun[i].checked = true
      //               }
      //             });
      //           });
      //         }
      //         setRealEstateEquipements(equipmentsCommun);
      //       }
      //       else {
      //         let newArray = [...equipmentsCommun, ...resultt]
      //         if (result.equipement) {
      //           result.equipement.forEach(element => {
      //             newArray.forEach((elementtab, i) => {
      //               if (element.id === newArray[i].id) {
      //                 newArray[i].checked = true
      //               }
      //             });
      //           });
      //         }
      //         setRealEstateEquipements(newArray);
      //       }
      //       setIsLoadedeq3(true)

      //     },
      //     (error) => {
      //       setError(error);
      //     }
      //   );
      // }

      setTypeApartment(result.typeApartment)
      if (result.departureDate) setDateDep(dateFormat(result.departureDate, "yyyy-mm-dd"))
      if (result.arrivalDate) setDateArrival(dateFormat(result.arrivalDate, "yyyy-mm-dd"))
      setNbrRoom(result.nbRooms)
      setNbrBathroom(result.nbBathrooms)
      setNbrElevators(result.nbElevators)
      setNbrBalcon(result.nbBalcon)
      setNbrTerrase(result.nbTerrase)
      setNbrPiece(result.nbPieces)
      setNbrParkingSpace(result.nbParkingSpace)



      let nCheckbox = []
      if (result.equipement) {
        result.equipement.forEach(element => {
          nCheckbox.push(element.id + "");
        })
      }
      //console.log('nCheckboxNNNNNNNNN', nCheckbox)
      let rea = 10;
      //console.log(equipments.includes(rea.toString()))
      setEquipments(nCheckbox);
    }

  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log('Equipments in submit : '  , equipments) 
    //console.log('Equipments in submit : ' , hasChanged , equipments) ; return;

    setSubmitted(true);

    let isValid1 = title && typeAnnonce && price && surface && country && city && delegate && locality
      && imgPrincipal && (phoneNumber.length == 0 || isValidPhoneNumber(phoneNumber)) ? true : false;
    let isValid = false;

    const watermarkedAlbum = await addWatermarkToAlbum(album);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("surface", surface);
    formData.append("description", description);
    formData.append("price", price);
    //formData.append("createdBy", tokenCookie);
    formData.append("locality", locality);
    formData.append("typeAnnonce", typeAnnonce);
    if (newPhone) formData.append("phone", phoneNumber);
    if (pImageThumb) {
      const watermarkedP = await addWatermark(imgPrincipal);
      formData.append("imgPrincipal", watermarkedP);
      formData.append("newimgPrincipal", 'yes');
    }
    else {
      formData.append("newimgPrincipal", 'no');
    }
    for (let index = 0; index < watermarkedAlbum.length; index++) {
      formData.append("album[]", watermarkedAlbum[index]);
    }

    let myLink = "";



    if (annonce.type == 'garage') {
      myLink = "/annonce_garage/edit/";
      isValid = isValid1
    }
    else if (annonce.type == 'commercial_space') {
      isValid = isValid1 && typeLocal ? true : false;
      myLink = "/annonce_space_commercial/edit/";
      formData.append("typeCommercialSpace", typeLocal);
    }

    else if (annonce.type == 'house') {
      isValid = isValid1 && typeHouse && nbrFloor && nbrPiece && nbrRoom && nbrBathroom && nbrGarage &&
        nbrGarden && nbrCavesSol && nbrElevators && nbBalcon && nbTerrase && nbPool &&
        nbParkingSpace && equipments.length > 0 ? true : false;

      formData.append("typeHouse", typeHouse);
      formData.append("nbFloor", nbrFloor);
      formData.append("nbPiece", nbrPiece);
      formData.append("nbRoom", nbrRoom);
      formData.append("nbGarage", nbrGarage);
      formData.append("nbCaveSol", nbrCavesSol);
      formData.append("nbElevators", nbrElevators);
      formData.append("nbGardens", nbrGarden);
      formData.append("nbBalcon", nbBalcon);
      formData.append("nbParkingSpace", nbParkingSpace);
      formData.append("nbTerrase", nbTerrase);
      formData.append("equipement", equipments);
      formData.append("nbBathroom", nbrBathroom);
      formData.append("nbPool", nbPool);

      if (typeAnnonce === "annonce_location" || typeAnnonce === "annonce_vacance") {

        formData.append("departureDate", dateDep);
        formData.append("arrivalDate", dateArrival);
        isValid = isValid && dateArrival <= dateDep ? true : false;
        if (typeAnnonce === "annonce_location") myLink = "/annonce_house/location/edit/";
        else if (typeAnnonce === "annonce_vacance") myLink = "/annonce_house/vacance/edit/";
      }
      else if (typeAnnonce === "annonce_vente") {
        myLink = "/annonce_house/vente/edit/";
      }
    }
    else if (annonce.type == 'apartment') {
      isValid = isValid1 && nbrPiece && nbrRoom && nbrBathroom && nbrElevators && nbBalcon &&
        nbTerrase && nbParkingSpace && equipments.length > 0 ? true : false;

      formData.append("typeApartment", typeApartment);
      formData.append("nbPiece", nbrPiece);
      formData.append("nbRoom", nbrRoom);
      formData.append("nbElevators", nbrElevators);
      formData.append("nbBalcon", nbBalcon);
      formData.append("nbParkingSpace", nbParkingSpace);
      formData.append("nbTerrase", nbTerrase);
      formData.append("equipement", equipments);
      formData.append("nbBathroom", nbrBathroom);


      if (typeAnnonce === "annonce_location" || typeAnnonce === "annonce_vacance") {
        formData.append("departureDate", dateDep);
        formData.append("arrivalDate", dateArrival);
        isValid = isValid && dateArrival <= dateDep ? true : false;
        if (typeAnnonce === "annonce_location") myLink = "/annonce_apartment/location/edit/";
        else if (typeAnnonce === "annonce_vacance") myLink = "/annonce_apartment/vacance/edit/";
      } else if (typeAnnonce === "annonce_vente") {
        myLink = "/annonce_apartment/vente/edit/"
      }
    }
    else if (annonce.type == 'new_real_estate') {
      isValid = isValid1 && typeRealEstate ? true : false;
      formData.append("typeNewRealEstate", typeRealEstate);
      myLink = "/annonce_new_real_estate/edit/"
    }
    else if (annonce.type == 'office') {
      isValid = isValid1;
      myLink = "/annonce_office/edit/";
    }
    else if (annonce.type == 'ground') {
      formData.append("typeGround", typeTerrain);
      myLink = "/annonce_ground/edit/";
      isValid = isValid1 && typeTerrain ? true : false;
    }


    if (!doneuploadingfile) {
      alert("not done uploading files yet");
      setLoading(false);

      return false;
    } else {

      //console.log("isValid",isValid)        
      //console.log(API_BASE_URL +myLink, formData , keyHeader)

      if (isValid) {
        setLoading(true)

        apiClient
          .post(myLink + id, formData)
          .then((res) => {
            //console.log(res);
            if (res.data.status.toString() === "ok") {
              setSuccess(true);
              if (res.data.annonce) {
                const updatedAnnonces = [...estates];
                const elIndex = updatedAnnonces.findIndex(el => el.id === id);
                if (elIndex !== -1) {
                  updatedAnnonces[elIndex] = res.data.annonce;
                  setEstates(updatedAnnonces);
                }
              }
              setTimeout(() => {
                history.push("/members/profile/immobilier");
              }, 1500);
            }
            setLoading(false)
          })
          .catch((error) => {
            //console.log(" errors : ", error);
            setLoading(false)
            if (error.response && error.response.status === 401) {
              logoutLocal();
              window.location.replace("/members/login");
            }
          });


      } else {
        window.scrollTo(0, 200);
      }



    }
  };


  useEffect(() => {

    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            setCountries(result);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => { }
        );
    } else {
      setCountries(JSON.parse(localStorage.getItem("countries")));

      {
        var countriesF = [];
        countriesF = JSON.parse(localStorage.getItem("countries")).find((city) => city.id.toString() == props.location.state.country.id);
        setCities(countriesF.cities);
        if (props.location.state.city.id !== "0") {
          var citiesF = [];
          citiesF = countriesF.cities.find((delegate) => delegate.id.toString() == props.location.state.city.id);
          setDelegates(citiesF.delegations);
          //console.log('citiesF', citiesF);
        }
        if (props.location.state.delegate.id !== "0") {
          var delegatesF = [];
          delegatesF = citiesF.delegations.find((local) => local.id.toString() == props.location.state.delegate.id);
          setLocalities(delegatesF.localities);
          //console.log('delegatesF', delegatesF);
        }
      }

    }

    //   if(props.location.state.type == 'house' || props.location.state.type == 'apartment')
    //   {
    //   axios(`${API_BASE_URL}/realEstate/equipements/loc_vente_vac`)
    //   .then((res) => res.data)
    //   .then(
    //     (result) => {
    //       setIsLoaded(true);
    //       setEquipmentsCommun(result);
    //     },
    //     (error) => {
    //       setError(error);
    //     }
    //   );

    //   axios(`${API_BASE_URL}/realEstate/equipement/type/all`)
    //   .then((res) => res.data)
    //   .then(
    //     (result) => {


    //       setRealEstateEquipementsType(result);
    //       console.log('setRealEstateEquipements type', result); 
    //         setIsLoadedeq(true);
    //     },
    //     (error) => {
    //       setError(error);
    //     }
    //   );

    // }

  }, []);

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    //console.log("iddd", id)
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      //console.log('countriesF', countriesF);
    } else {
      setCity([]);
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    //console.log("iddd", id)
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      //console.log('citiesF', citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };
  const handleDelegateChange = (e) => {
    setDelegate(e.target.value);
    let id = e.target.value;
    var delegatesF = [];
    //console.log("iddd", id)
    if (id !== "0") {
      delegatesF = delegates.find((local) => local.id.toString() === id);
      setLocalities(delegatesF.localities);
      //console.log('delegatesF', delegatesF);
    } else {
      setLocality([]);
      //  setModel("");
    }
  };

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = async ({ meta, file }, status) => {

    if (status === 'preparing' && meta.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      return;
      //file = null;
    }
    setDoneuploadingfile(false);
    let removedfilesx = removedfiles

    if (status === 'preparing' && !(meta.size > 5 * 1024 * 1024)) { setImgCountA(imgCountA + 1) }
    if (status.toString() === "done" && !(meta.size > 5 * 1024 * 1024)) {
      let newalb = album;
      let options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
      const abortController = new AbortController();
      const fileId = meta.id;
      const updatedAbortControllers = { ...abortControllers, [fileId]: abortController };
      setAbortControllers(updatedAbortControllers);
      options.signal = abortController.signal;

      try {
        file = await imageCompression(file, options);
      } catch (error) {
        //console.log('erro compress : ',error);
        // return;
      }
      if ((!removedfiles.includes(meta.id) && !removedfilesx.includes(meta.id)) && !(meta.size > 5 * 1024 * 1024)) {
        newalb.push(file);
        // setAlbum(prevAlbum => [...prevAlbum, file]);
        setAlbum(newalb)
        setImgCount(prevCount => prevCount + 1);
      } else {
        //console.log("new album in done else");
        //console.log(newalb.length ,newalb);
      }
      setDoneuploadingfile(true);
    }
    if (status.toString() === "removed" && !(meta.size > 5 * 1024 * 1024)) {
      removedfilesx.push(meta.id);
      setremovedfiles(removedfiles)
      // setremovedfiles(prevA => [...prevA, meta.id]);
      const fileId = meta.id;
      if (abortControllers[fileId]) {
        abortControllers[fileId].abort();
        const updatedAbortControllers = { ...abortControllers };
        delete updatedAbortControllers[fileId];
        setAbortControllers(updatedAbortControllers);

      }

      let index = album.findIndex((obj) => obj.name === file.name);
      let newAlbum = album;
      {
        newAlbum.splice(index, 1);
        if (index > -1) {
          if (imgCount > 0) setImgCount(newAlbum.length);
        }
        if (imgCountA > 0) setImgCountA(imgCountA - 1);
        setAlbum(newAlbum);
      }
      setDoneuploadingfile(true);

    } else setDoneuploadingfile(true);
  };

  const handleFileSection = async (event) => {
    setUploadingImage(true);

    let file = event.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      alert(sizeAlert);
      setUploadingImage(false);
      event.target.value = '';
      return;
    }

    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      file = await imageCompression(file, options);
    } catch (error) {
      //console.log(error);
    }
    setoldImgp(false)
    setpImageThumb(URL.createObjectURL(file));
    setImgPrincipal(file);
    setUploadingImage(false);

  };

  const toggle = (id, type, i) => {
    setModal(!modal);
    setItem(id)
    setTypeImage(type)
    setIndex(i)
  };

  function deleteImageRealEstate(idIm, index) {
    //console.log("id", idIm, index);
    setDeleting(true); // heeeeere

    apiClient
      .delete(`/real_estate/delete_image/` + idIm)
      .then((res) => {
        toggle();
        if (typeImage) {
          setImgPrincipal(false);
          setoldImgp(false); // heeeeere

        }
        otherImages.splice(index, 1);
        setOtherImages(otherImages);
        if (res.data.status.toString() === "ok") {
          setOtherImages((items) => items.filter((x) => x.idIm !== idIm));
          //console.log('okkd', otherImages)
        }
        if (
          res.data.status.toString() ===
          "ForeignKeyConstraintViolationException"
        ) {
          setErrorDel(
            "Vous ne pouvez pas supprimer cette image"
          );
        }
        setDeleting(false);
      })
      .catch((err) => {
        //console.log(err);
        setDeleting(false);
      })
  }

  const changePhoneNumber = (event) => {
    //console.log(event.target.checked);
    if (event.target.checked) { setNewPhone(false); setPhoneNumber(""); }
    else {
      setNewPhone(true);
    }
  }

  // useEffect(() => {

  // axios(`${API_BASE_URL}/real_estate/show/${id}`)
  //   .then((res) => res.data)
  //   .then((result) => {
  //     console.log('show real estate', result)
  //     setIdRealEstate({ id })
  //     setAnnonce(result)

  //   });

  // }, []
  // );

  const handleTypeChange = (e) => {
    setTypeAnnonce(e.target.value);
    let type = e.target.value;
    //console.log('type annonce', type)
    setEquipments([]);
    // if (type === "annonce_vente") {
    //   axios(`${API_BASE_URL}/realEstate/equipements/loc_vente`)
    //     .then((res) => res.data)
    //     .then(
    //       (result) => {

    //         //      setIsLoaded(true);
    //         // setRealEstateEquipements(...equipmentsCommun,...result);
    //         console.log('result.length', result.length)
    //         if (result[0] === "No equipement found!") {
    //           setRealEstateEquipements(equipmentsCommun);

    //         }
    //         else {
    //           let newArray = [...equipmentsCommun, ...result]

    //           setRealEstateEquipements(newArray);
    //         }
    //       setIsLoadedeq2(true)

    //       },
    //       (error) => {
    //         setError(error);
    //       }
    //     );
    // }
    // else if (type === "annonce_vacance") {
    //   axios(`${API_BASE_URL}/realEstate/equipements/loc_vacance`)
    //     .then((res) => res.data)
    //     .then(
    //       (result) => {
    //         if (result[0] === "No equipement found!") {
    //           setRealEstateEquipements(equipmentsCommun);

    //         }
    //         else {
    //           let newArray = [...equipmentsCommun, ...result]

    //           setRealEstateEquipements(newArray);
    //         }
    //       setIsLoadedeq1(true)

    //       },
    //       (error) => {
    //         setError(error);
    //       }
    //     );
    // }
    // else {
    //   // annonce location
    //   axios(`${API_BASE_URL}/realEstate/equipements/all`)
    //     .then((res) => res.data)
    //     .then(
    //       (result) => {
    //         setRealEstateEquipements(result);
    //       setIsLoadedeq3(true)

    //       },
    //       (error) => {
    //         setError(error);
    //       }
    //     );
    // }


  };

  const onChangeExt = (event, equipement) => {
    let nCheckbox = equipments.slice(); // create a new copy of state value
    if (isValueExist(nCheckbox, event)) {
      // check if the same value is preexisted in the array
      const index = nCheckbox.indexOf(event.target.value);

      nCheckbox.splice(index, 1); // removing the preexciting value
      // equipement.checked = false

    } else {
      nCheckbox.push(event.target.value); // inserting the value of checkbox in the array
      // equipement.checked = true

    }
    setEquipments(nCheckbox);
    sethasChanged(true)
  };

  function isValueExist(data, event) {
    if (data.length === 0) {
      return false;
    }

    for (let i = 0; i <= data.length; i++) {
      if (event.target.value === data[i]) {
        return true;
      }
    }
    return false;
  }

  const garageForm = <>
    <div className="advInfoSzctionCard" >
      <Basic name='Garage' />
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="typeAnnonce">Type de l'annonce</CLabel>
            <CSelect
              name="typeAnnonce"
              value={typeAnnonce}
              onChange={(e) => setTypeAnnonce(e.target.value)}
            >
              <option value="">Choisissez le type de l'annonce</option>
              <option value="annonce_location">Annonce de location</option>
              <option value="annonce_vente">Annonce de vente </option>
            </CSelect>
            {submitted && !typeAnnonce && (
              <span className="invalid-feedback error">obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="title">Titre</CLabel>
            <CInput
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></CInput>
            {submitted && !title && (
              <span className="invalid-feedback error">
                Obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <CRow xs="12">


        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="price">Prix en TND</CLabel>
            <CInput
              type="number"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            ></CInput>
            {submitted && !price && (
              <span className="invalid-feedback error">
                Obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>

        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="surface">Surface </CLabel>
            <CInputGroup>
              <CInput
                name="surface"
                value={surface}
                onChange={(e) => setSurface(e.target.value)}
              />
              <CInputGroupAppend>
                <CInputGroupText>m²</CInputGroupText>
              </CInputGroupAppend>
            </CInputGroup>
            {submitted && !surface && (
              <span className="invalid-feedback error">
                Obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>


      </CRow>
      <Scroll />
    </div>



  </>

  const spaceForm = <>
    <div className="advInfoSzctionCard" >
      <Basic name='Local commercial' />
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="typeAnnonce">Type de l'annonce</CLabel>
            <CSelect
              name="typeAnnonce"
              value={typeAnnonce}
              onChange={(e) => setTypeAnnonce(e.target.value)}
            >
              <option value="">Choisissez le type de l'annonce</option>
              <option value="annonce_location">Annonce de location</option>
              <option value="annonce_vente">Annonce de vente </option>
            </CSelect>
            {submitted && !typeAnnonce && (
              <span className="invalid-feedback error">obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>

      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="typeLocal">Type de local</CLabel>
            <CSelect
              name="typeLocal"
              value={typeLocal}
              onChange={(e) => setTypeLocal(e.target.value)}
            >
              <option value="">Choisissez le type de local</option>
              <option value="magasin">Magasin</option>
              <option value="restaurant">Restaurant </option>
              <option value="hotel">Hotel </option>
              <option value="zone de stockage">Zone de stockage </option>
              <option value="autres">Autres </option>
            </CSelect>
            {submitted && !typeLocal && (
              <span className="invalid-feedback error">obligatoire</span>
            )}
          </CFormGroup>
        </CCol>

        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="title">Titre</CLabel>
            <CInput
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></CInput>
            {submitted && !title && (
              <span className="invalid-feedback error">Obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>

      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="price">Prix en TND</CLabel>
            <CInput
              type="number"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            ></CInput>
            {submitted && !price && (
              <span className="invalid-feedback error">Obligatoire</span>
            )}
          </CFormGroup>
        </CCol>

        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="surface">Surface </CLabel>
            <CInputGroup>
              <CInput
                name="surface"
                value={surface}
                onChange={(e) => setSurface(e.target.value)}
              />
              <CInputGroupAppend>
                <CInputGroupText>m²</CInputGroupText>
              </CInputGroupAppend>
            </CInputGroup>
            {submitted && !surface && (
              <span className="invalid-feedback error">Obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <Scroll />
    </div>
  </>

  const groundForm = <>

    <div className="advInfoSzctionCard" >
      <Basic name='Terrain' />
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="typeAnnonce">Type de l'annonce</CLabel>
            <CSelect
              name="typeAnnonce"
              value={typeAnnonce}
              onChange={(e) => setTypeAnnonce(e.target.value)}
            >
              <option value="">Choisissez le type de l'annonce</option>
              <option value="annonce_location">Annonce de location</option>
              <option value="annonce_vente">Annonce de vente </option>
            </CSelect>
            {submitted && !typeAnnonce && (
              <span className="invalid-feedback error">obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="typeTerrain">Type de terrain</CLabel>
            <CSelect
              name="typeTerrain"
              value={typeTerrain}
              onChange={(e) => setTypeTerrain(e.target.value)}
            >
              <option value="">Choisissez le type de terrain</option>
              <option value="terrain à bâtir">Terrain à bâtir</option>
              <option value="terrain agricole">terrain agricole </option>
            </CSelect>
            {submitted && !typeTerrain && (
              <span className="invalid-feedback error">obligatoire</span>
            )}
          </CFormGroup>
        </CCol>

        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="title">Titre</CLabel>
            <CInput
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></CInput>
            {submitted && !title && (
              <span className="invalid-feedback error">Obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="price">Prix en TND</CLabel>
            <CInput
              type="number"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            ></CInput>
            {submitted && !price && (
              <span className="invalid-feedback error">Obligatoire</span>
            )}
          </CFormGroup>
        </CCol>

        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="surface">Surface </CLabel>
            <CInputGroup>
              <CInput
                name="surface"
                value={surface}
                onChange={(e) => setSurface(e.target.value)}
              />
              <CInputGroupAppend>
                <CInputGroupText>m²</CInputGroupText>
              </CInputGroupAppend>
            </CInputGroup>
            {submitted && !surface && (
              <span className="invalid-feedback error">Obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <Scroll />
    </div>
  </>

  const newForm = <>
    <div className="advInfoSzctionCard" >
      <Basic name='Immobilier neuf' />
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="typeAnnonce">Type de l'annonce</CLabel>
            <CSelect
              name="typeAnnonce"
              value={typeAnnonce}
              onChange={(e) => setTypeAnnonce(e.target.value)}
            >
              <option value="">Choisissez le type de l'annonce</option>
              <option value="annonce_location">Annonce de location</option>
              <option value="annonce_vente">Annonce de vente </option>
            </CSelect>
            {submitted && !typeAnnonce && (
              <span className="invalid-feedback error">obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="typeRealEstate">Type de l'immoblier</CLabel>
            <CSelect
              name="typeRealEstate"
              value={typeRealEstate}
              onChange={(e) => setTypeRealEstate(e.target.value)}
            >
              <option value="">Choisissez le type de l'immobilier</option>
              <option value="lotissement">Lotissement</option>
              <option value="résidence">Résidence </option>
            </CSelect>
            {submitted && !typeRealEstate && (
              <span className="invalid-feedback error">obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="title">Titre</CLabel>
            <CInput
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></CInput>
            {submitted && !title && (
              <span className="invalid-feedback error">Obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="price">Prix en TND</CLabel>
            <CInput
              type="number"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            ></CInput>
            {submitted && !price && (
              <span className="invalid-feedback error">Obligatoire</span>
            )}
          </CFormGroup>
        </CCol>

        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="surface">Surface </CLabel>
            <CInputGroup>
              <CInput
                name="surface"
                value={surface}
                onChange={(e) => setSurface(e.target.value)}
              />
              <CInputGroupAppend>
                <CInputGroupText>m²</CInputGroupText>
              </CInputGroupAppend>
            </CInputGroup>
            {submitted && !surface && (
              <span className="invalid-feedback error">Obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <Scroll />
    </div>
  </>

  const officeForm = <>
    <div className="advInfoSzctionCard" >
      <Basic name='Bureau' />
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="typeAnnonce">Type de l'annonce</CLabel>
            <CSelect
              name="typeAnnonce"
              value={typeAnnonce}
              onChange={(e) => setTypeAnnonce(e.target.value)}
            >
              <option value="">Choisissez le type de l'annonce</option>
              <option value="annonce_location">Annonce de location</option>
              <option value="annonce_vente">Annonce de vente </option>
            </CSelect>
            {submitted && !typeAnnonce && (
              <span className="invalid-feedback error">obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="title">Titre</CLabel>
            <CInput
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></CInput>
            {submitted && !title && (
              <span className="invalid-feedback error">Obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="price">Prix en TND</CLabel>
            <CInput
              type="number"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            ></CInput>
            {submitted && !price && (
              <span className="invalid-feedback error">Obligatoire</span>
            )}
          </CFormGroup>
        </CCol>

        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="surface">Surface </CLabel>
            <CInputGroup>
              <CInput
                name="surface"
                value={surface}
                onChange={(e) => setSurface(e.target.value)}
              />
              <CInputGroupAppend>
                <CInputGroupText>m²</CInputGroupText>
              </CInputGroupAppend>
            </CInputGroup>
            {submitted && !surface && (
              <span className="invalid-feedback error">Obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <Scroll />
    </div>
  </>

  const apartmentForm = <>
    <div className="advInfoSzctionCard" >
      <Basic name='Appartement' />
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbrSeat">Type d'annonce</CLabel>
            <CSelect
              name="typeAnnonce"
              value={typeAnnonce}
              onChange={(e) => handleTypeChange(e)}
            >
              <option value="">Choisissez le type d'annonce</option>
              <option value="annonce_location">Annonce de location</option>
              <option value="annonce_vente">Annonce de vente </option>
              <option value="annonce_vacance">Annonce de vacances</option>
            </CSelect>
            {submitted && !typeAnnonce && (
              <span className="invalid-feedback error">obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>

      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="typeApartment">Type d'appartement</CLabel>
            <CSelect
              name="typeApartment"
              value={typeApartment}
              onChange={(e) => setTypeApartment(e.target.value)}
            >
              <option value="">Choisissez le type d'appartement</option>
              <option value="appartement">Appartement</option>
              <option value="chambre">Chambre </option>
              <option value="etage_de_villa">Étage de villa</option>
              <option value="studio">Studio</option>
              <option value="duplex">Duplex</option>
            </CSelect>
            {submitted && !typeApartment && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="title">Titre</CLabel>
            <CInput
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></CInput>
            {submitted && !title && (
              <span className="invalid-feedback error">
                Obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>

      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="price">Prix en TND</CLabel>
            <CInput
              type="number"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            ></CInput>
            {submitted && !price && (
              <span className="invalid-feedback error">
                Obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>

        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="surface">Surface </CLabel>
            <CInputGroup>
              <CInput
                name="surface"
                value={surface}
                onChange={(e) => setSurface(e.target.value)}
              />
              <CInputGroupAppend>
                <CInputGroupText>m²</CInputGroupText>
              </CInputGroupAppend>
            </CInputGroup>
            {submitted && !surface && (
              <span className="invalid-feedback error">
                Obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <Scroll />
    </div>

    {/*annonce de location  */}
    {(typeAnnonce === "annonce_location" || typeAnnonce === "annonce_vacance") &&
      <>

        <div className="advInfoSzctionCard" >
          <Eventt name="dates disponibles d'arrivée et de départ" />
          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="dateArrival">Date d'arrivée</CLabel>
                <CInput
                  type="date"
                  name="dateArrival"
                  value={dateArrival}
                  onChange={(e) => { setDateArrival(e.target.value); sethasChanged(true) }}
                ></CInput>
                {submitted &&
                  dateArrival &&
                  dateDep &&
                  dateDep < dateArrival && (
                    <span className="invalid-feedback error">
                      Veuillez entrer la date d'arrivée inférieur la date du
                      départ
                    </span>
                  )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="dateDep">Date du départ</CLabel>
                <CInput
                  type="date"
                  name="dateDep"
                  value={dateDep}
                  onChange={(e) => { setDateDep(e.target.value); sethasChanged(true) }}
                ></CInput>
                {submitted &&
                  dateDep &&
                  dateArrival &&
                  dateDep < dateArrival && (
                    <span className="invalid-feedback error">
                      Veuillez entrer la date du départ supérieur la date
                      d'arrivée
                    </span>
                  )}
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll />
        </div>
      </>}

    <div className="advInfoSzctionCard" >
      <HousePlan name="Plan de l'Appartement" />
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbrRoom">Nombre des chambres</CLabel>
            <CSelect
              name="nbrRoom"
              value={nbrRoom}
              onChange={(e) => setNbrRoom(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 chambre</option>
              <option value="1">1 chambre</option>
              <option value="2">2 chambres</option>
              <option value="3">3 chambres</option>
              <option value="4">4 chambres</option>
              <option value="5">5 chambres</option>
              <option value="6">6 chambres</option>
              <option value="7">7 chambres</option>
              <option value="8">8 chambres</option>
              <option value="9">9 chambres</option>
              <option value="10">10 chambres</option>
            </CSelect>
            {submitted && !nbrRoom && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbrBathroom">
              Nombre des salles de bains
            </CLabel>
            <CSelect
              name="nbrBathroom"
              value={nbrBathroom}
              onChange={(e) => setNbrBathroom(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 salle de bains</option>
              <option value="1">1 salle de bains</option>
              <option value="2">2 salles de bains</option>
              <option value="3">3 salles de bains</option>
              <option value="4">4 salles de bains</option>
              <option value="5">5 salles de bains</option>
            </CSelect>
            {submitted && !nbrBathroom && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>

      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbBalcon">Nombre des balcons</CLabel>
            <CSelect
              name="nbBalcon"
              value={nbBalcon}
              onChange={(e) => setNbrBalcon(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 balcon</option>
              <option value="1">1 balcon</option>
              <option value="2">2 balcons</option>
              <option value="3">3 balcons</option>
              <option value="4">4 balcons</option>
              <option value="5">5 balcons</option>
            </CSelect>
            {submitted && !nbBalcon && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbTerrase">Nombre des terrases </CLabel>
            <CSelect
              name="nbTerrase"
              value={nbTerrase}
              onChange={(e) => setNbrTerrase(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 terrase</option>
              <option value="1">1 terrase</option>
              <option value="2">2 terrases</option>
              <option value="3">3 terrases</option>
              <option value="4">4 terrases</option>
              <option value="5">5 terrases</option>
            </CSelect>
            {submitted && !nbTerrase && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbrPiece">Nombre des pièces </CLabel>
            <CSelect
              name="nbrPiece"
              value={nbrPiece}
              onChange={(e) => setNbrPiece(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 pièce</option>
              <option value="1">1 pièce</option>
              <option value="2">2 pièces </option>
              <option value="3">3 pièces </option>
              <option value="4">4 pièces </option>
              <option value="5">5 pièces </option>
              <option value="6">6 pièces </option>
              <option value="7">7 pièces </option>
              <option value="8">8 pièces </option>
              <option value="9">9 pièces </option>
              <option value="10">10 pièces </option>
            </CSelect>
            {submitted && !nbrPiece && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>

        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbParkingSpace">
              Nombre des places de parking{" "}
            </CLabel>
            <CSelect
              name="nbParkingSpace"
              value={nbParkingSpace}
              onChange={(e) => setNbrParkingSpace(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 place de parking</option>
              <option value="1">1 place de parking</option>
              <option value="2">2 places de parking</option>
              <option value="3">3 places de parking</option>
              <option value="4">4 places de parking</option>
              <option value="5">5 places de parking</option>
            </CSelect>
            {submitted && !nbParkingSpace && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>

      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbrElevators">
              Nombre des ascenseurs
            </CLabel>
            <CSelect
              name="nbrElevators"
              value={nbrElevators}
              onChange={(e) => setNbrElevators(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 ascenseur</option>
              <option value="1">1 ascenseur</option>
              <option value="2">2 ascenseurs</option>
              <option value="3">3 ascenseurs</option>
              <option value="4">4 ascenseurs</option>
              <option value="5">5 ascenseurs</option>
            </CSelect>
            {submitted && !nbrElevators && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <Scroll />
    </div>


    {typeAnnonce && <>
      {typeAnnonce === "annonce_vente" ?
        <>


          <div className="advInfoSzctionCard" >
            <HouseSetting name="équipements de l'Appartement" />


            <div className="information-title">
              <h2>Installation</h2>
            </div>



            {realEstateEquipementsAll && realEstateEquipementsAll.map((realEstatequipment, index) => {

              return (
                <>

                  {realEstatequipment.type_annonce_value == "3" &&
                    <>


                      <CCol xs="12" key={index}>

                        <CFormGroup variant="checkbox" className="checkbox">
                          <CInputCheckbox
                            id={`custom-checkbox-${index}`}
                            name={realEstatequipment.id}
                            value={realEstatequipment.id}
                            onChange={(e) => onChangeExt(e)}
                            //selected={realEstateEquipements.includes(realEstatequipment.label)}
                            checked={equipments.includes(realEstatequipment.id.toString())}
                          />
                          <label htmlFor={`custom-checkbox-${index}`}>
                            {realEstatequipment.label}
                          </label>
                        </CFormGroup>
                      </CCol>


                    </>
                  }
                </>

              );

            })}


            {submitted && !equipments.length > 0 && (
              <span className="invalid-feedback error">
                Veuillez choisir au moins un équipement
              </span>
            )}
            <Scroll />
          </div>


        </>
        :
        <>
          <div className="advInfoSzctionCard" >
            <HouseSetting name="équipements de l'Appartement" />
            {realEstateEquipementsTypes && realEstateEquipementsTypes.map((typeEquipment, index) => {
              return (
                <>


                  <div className="information-title">
                    <h2>{typeEquipment.label}</h2>
                  </div>


                  <div>
                    <CRow>
                      {realEstateEquipementsAll && realEstateEquipementsAll.map((realEstatequipment, index) => {

                        return (
                          <>

                            {typeEquipment && typeEquipment.id == realEstatequipment.type_id &&
                              <>


                                <CCol xs="3" key={index}>

                                  <CFormGroup variant="checkbox" className="checkbox">
                                    <CInputCheckbox
                                      id={`custom-checkbox-${index}`}
                                      name={realEstatequipment.id}
                                      value={realEstatequipment.id}
                                      onChange={(e) => onChangeExt(e)}
                                      // selected={realEstateEquipements.includes(realEstatequipment.label)}
                                      checked={equipments.includes(realEstatequipment.id.toString())}

                                    />
                                    <label htmlFor={`custom-checkbox-${index}`}>
                                      {realEstatequipment.label}
                                    </label>
                                  </CFormGroup>
                                </CCol>


                              </>
                            }
                          </>

                        );

                      })}
                    </CRow>
                  </div>

                </>
              );
            })}

            {submitted && !equipments.length > 0 && (
              <span className="invalid-feedback error">
                Veuillez choisir au moins un équipement
              </span>
            )}
            <Scroll />
          </div>


        </>
      }
    </>}

  </>

  const houseForm = <>
    <div className="advInfoSzctionCard" >
      <Basic name='maison' />
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbrSeat">Type d'annonce</CLabel>
            <CSelect
              name="typeAnnonce"
              value={typeAnnonce}
              onChange={(e) => handleTypeChange(e)}
            >
              <option value="">Choisissez le type d'annonce</option>
              <option value="annonce_location">Annonce de location</option>
              <option value="annonce_vente">Annonce de vente </option>
              <option value="annonce_vacance">Annonce de vacances</option>
            </CSelect>
            {submitted && !typeAnnonce && (
              <span className="invalid-feedback error">obligatoire</span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>

      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="typeHouse">Type de la maison</CLabel>
            <CSelect
              name="typeHouse"
              value={typeHouse}
              onChange={(e) => setTypeHouse(e.target.value)}
            >
              <option value="">Choisissez le type de la maison</option>
              <option value="villa">Villa</option>
              <option value="etage_de_villa">Étage de villa</option>
              <option value="bungalow">Bungalow </option>
              <option value="maison">Maison</option>
              <option value="studio_de_maison">Studio de maison</option>
            </CSelect>
            {submitted && !typeHouse && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="title">Titre</CLabel>
            <CInput
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></CInput>
            {submitted && !title && (
              <span className="invalid-feedback error">
                Obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>

      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="price">Prix en TND</CLabel>
            <CInput
              type="number"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            ></CInput>
            {submitted && !price && (
              <span className="invalid-feedback error">
                Obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>

        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="surface">Surface </CLabel>
            <CInputGroup>
              <CInput
                name="surface"
                value={surface}
                onChange={(e) => setSurface(e.target.value)}
              />
              <CInputGroupAppend>
                <CInputGroupText>m²</CInputGroupText>
              </CInputGroupAppend>
            </CInputGroup>
            {submitted && !surface && (
              <span className="invalid-feedback error">
                Obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <Scroll />
    </div>

    {(typeAnnonce === "annonce_location" || typeAnnonce === "annonce_vacance") &&
      <>

        <div className="advInfoSzctionCard" >
          <Eventt name="dates disponibles d'arrivée et de départ" />
          <CRow xs="12">
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="dateArrival">Date d'arrivée</CLabel>
                <CInput
                  type="date"
                  name="dateArrival"
                  value={dateArrival}
                  onChange={(e) => { setDateArrival(e.target.value); sethasChanged(true) }}
                ></CInput>
                {submitted &&
                  dateArrival &&
                  dateDep &&
                  dateDep < dateArrival && (
                    <span className="invalid-feedback error">
                      Veuillez entrer la date d'arrivée inférieur la date du
                      départ
                    </span>
                  )}
              </CFormGroup>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="dateDep">Date du départ</CLabel>
                <CInput
                  type="date"
                  name="dateDep"
                  value={dateDep}
                  onChange={(e) => { setDateDep(e.target.value); sethasChanged(true) }}
                ></CInput>
                {submitted &&
                  dateDep &&
                  dateArrival &&
                  dateDep < dateArrival && (
                    <span className="invalid-feedback error">
                      Veuillez entrer la date du départ supérieur la date
                      d'arrivée
                    </span>
                  )}
              </CFormGroup>
            </CCol>
          </CRow>
          <Scroll />
        </div>
      </>}
    {/*annonce de location 
          typeAnnonce === "annonce_location"  */}

    <div className="advInfoSzctionCard" >
      <HousePlan name="Plan de la maison" />
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbrFloor">Nombre des étages</CLabel>
            <CSelect
              name="nbrFloor"
              value={nbrFloor}
              onChange={(e) => setNbrFloor(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 étage</option>
              <option value="1">1 étage</option>
              <option value="2">2 étages</option>
              <option value="3">3 étages</option>
              <option value="4">4 étages</option>
              <option value="5">5 étages</option>
            </CSelect>
            {submitted && !nbrFloor && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbrPiece">Nombre des pièces </CLabel>
            <CSelect
              name="nbrPiece"
              value={nbrPiece}
              onChange={(e) => setNbrPiece(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 pièce</option>
              <option value="1">1 pièce</option>
              <option value="2">2 pièces </option>
              <option value="3">3 pièces </option>
              <option value="4">4 pièces </option>
              <option value="5">5 pièces </option>
              <option value="6">6 pièces </option>
              <option value="7">7 pièces </option>
              <option value="8">8 pièces </option>
              <option value="9">9 pièces </option>
              <option value="10">10 pièces </option>
            </CSelect>
            {submitted && !nbrPiece && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbrRoom">Nombre des chambres</CLabel>
            <CSelect
              name="nbrRoom"
              value={nbrRoom}
              onChange={(e) => setNbrRoom(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 chambre</option>
              <option value="1">1 chambre</option>
              <option value="2">2 chambres</option>
              <option value="3">3 chambres</option>
              <option value="4">4 chambres</option>
              <option value="5">5 chambres</option>
              <option value="6">6 chambres</option>
              <option value="7">7 chambres</option>
              <option value="8">8 chambres</option>
              <option value="9">9 chambres</option>
              <option value="10">10 chambres</option>
            </CSelect>
            {submitted && !nbrRoom && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbrBathroom">
              Nombre des salles de bains
            </CLabel>
            <CSelect
              name="nbrBathroom"
              value={nbrBathroom}
              onChange={(e) => setNbrBathroom(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 salle de bains</option>
              <option value="1">1 salle de bains</option>
              <option value="2">2 salles de bains</option>
              <option value="3">3 salles de bains</option>
              <option value="4">4 salles de bains</option>
              <option value="5">5 salles de bains</option>
            </CSelect>
            {submitted && !nbrBathroom && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbrGarage">Nombre des garages</CLabel>
            <CSelect
              name="nbrGarage"
              value={nbrGarage}
              onChange={(e) => setNbrGarage(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 garage</option>
              <option value="1">1 garage</option>
              <option value="2">2 garages</option>
              <option value="3">3 garages</option>
              <option value="4">4 garages</option>
              <option value="5">5 garages</option>
            </CSelect>
            {submitted && !nbrGarage && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbrCavesSol">
              Nombre des caves / sous sol
            </CLabel>
            <CSelect
              name="nbrCavesSol"
              value={nbrCavesSol}
              onChange={(e) => setNbrCavesSol(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 Cave / sous sol</option>
              <option value="1">1 Cave / sous sol</option>
              <option value="2">2 Caves / sous sol</option>
              <option value="3">3 Caves / sous sol</option>
              <option value="4">4 Caves / sous sol</option>
              <option value="5">5 Caves / sous sol</option>
            </CSelect>
            {submitted && !nbrCavesSol && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbrElevators">
              Nombre des ascenseurs
            </CLabel>
            <CSelect
              name="nbrElevators"
              value={nbrElevators}
              onChange={(e) => setNbrElevators(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 ascenseur</option>
              <option value="1">1 ascenseur</option>
              <option value="2">2 ascenseurs</option>
              <option value="3">3 ascenseurs</option>
              <option value="4">4 ascenseurs</option>
              <option value="5">5 ascenseurs</option>
            </CSelect>
            {submitted && !nbrElevators && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbrGarden">Nombre des jardins </CLabel>
            <CSelect
              name="nbrGarden"
              value={nbrGarden}
              onChange={(e) => setNbrGarden(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 jardin</option>
              <option value="1">1 jardin</option>
              <option value="2">2 jardins</option>
              <option value="3">3 jardins</option>
              <option value="4">4 jardins</option>
              <option value="5">5 jardins</option>
            </CSelect>
            {submitted && !nbrGarden && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>

      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbBalcon">Nombre des balcons</CLabel>
            <CSelect
              name="nbBalcon"
              value={nbBalcon}
              onChange={(e) => setNbrBalcon(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 balcon</option>
              <option value="1">1 balcon</option>
              <option value="2">2 balcons</option>
              <option value="3">3 balcons</option>
              <option value="4">4 balcons</option>
              <option value="5">5 balcons</option>
            </CSelect>
            {submitted && !nbBalcon && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbTerrase">Nombre des terrases </CLabel>
            <CSelect
              name="nbTerrase"
              value={nbTerrase}
              onChange={(e) => setNbrTerrase(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 terrase</option>
              <option value="1">1 terrase</option>
              <option value="2">2 terrases</option>
              <option value="3">3 terrases</option>
              <option value="4">4 terrases</option>
              <option value="5">5 terrases</option>
            </CSelect>
            {submitted && !nbTerrase && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <CRow xs="12">
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbPool">Nombre des piscines</CLabel>
            <CSelect
              name="nbPool"
              value={nbPool}
              onChange={(e) => setNbPool(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 piscine</option>
              <option value="1">1 piscine</option>
              <option value="2">2 piscines</option>
              <option value="3">3 piscines</option>
              <option value="4">4 piscines</option>
              <option value="5">5 piscines</option>
            </CSelect>
            {submitted && !nbPool && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="nbParkingSpace">
              Nombre des places de parking{" "}
            </CLabel>
            <CSelect
              name="nbParkingSpace"
              value={nbParkingSpace}
              onChange={(e) => setNbrParkingSpace(e.target.value)}
            >
              <option value=""></option>
              <option value="0">0 place de parking</option>
              <option value="1">1 place de parking</option>
              <option value="2">2 places de parking</option>
              <option value="3">3 places de parking</option>
              <option value="4">4 places de parking</option>
              <option value="5">5 places de parking</option>
            </CSelect>
            {submitted && !nbParkingSpace && (
              <span className="invalid-feedback error">
                obligatoire
              </span>
            )}
          </CFormGroup>
        </CCol>
      </CRow>
      <Scroll />
    </div>
    {typeAnnonce && <>

      {typeAnnonce === "annonce_vente" ?
        <>
          <div className="advInfoSzctionCard" >
            <HouseSetting name="équipements de la maison" />

            <div className="information-title">
              <h2>Installation</h2>
            </div>



            {realEstateEquipementsAll && realEstateEquipementsAll.map((realEstatequipment, index) => {

              return (
                <>

                  {realEstatequipment.type_annonce_value == "3" &&
                    <>


                      <CCol xs="12" key={index}>

                        <CFormGroup variant="checkbox" className="checkbox">
                          <CInputCheckbox
                            id={`custom-checkbox-${index}`}
                            name={realEstatequipment.id}
                            value={realEstatequipment.id}
                            onChange={(e) => onChangeExt(e)}
                            //selected={realEstateEquipements.includes(realEstatequipment.label)}
                            checked={equipments.includes(realEstatequipment.id.toString())}
                          />
                          <label htmlFor={`custom-checkbox-${index}`}>
                            {realEstatequipment.label}
                          </label>
                        </CFormGroup>
                      </CCol>


                    </>
                  }
                </>

              );

            })}

            {submitted && !equipments.length > 0 && (
              <span className="invalid-feedback error">
                Veuillez choisir au moins un équipement
              </span>
            )}
            <Scroll />
          </div>

        </>

        :
        <>
          <div className="advInfoSzctionCard" >
            <HouseSetting name="équipements de la maison" />
            {realEstateEquipementsTypes && realEstateEquipementsTypes.map((typeEquipment, index) => {
              return (
                <>


                  <div className="information-title">
                    <h2>{typeEquipment.label}</h2>
                  </div>


                  <div>
                    <CRow>
                      {realEstateEquipementsAll && realEstateEquipementsAll.map((realEstatequipment, index) => {

                        return (
                          <>

                            {typeEquipment && typeEquipment.id == realEstatequipment.type_id &&
                              <>


                                <CCol xs="3" key={index}>

                                  <CFormGroup variant="checkbox" className="checkbox">
                                    <CInputCheckbox
                                      id={`custom-checkbox-${index}`}
                                      name={realEstatequipment.id}
                                      value={realEstatequipment.id}
                                      onChange={(e) => onChangeExt(e)}
                                      // selected={realEstateEquipements.includes(realEstatequipment.label)}
                                      checked={equipments.includes(realEstatequipment.id.toString())}

                                    />
                                    <label htmlFor={`custom-checkbox-${index}`}>
                                      {realEstatequipment.label}
                                    </label>
                                  </CFormGroup>
                                </CCol>


                              </>
                            }
                          </>

                        );

                      })}
                    </CRow>
                  </div>

                </>
              );
            })}

            {submitted && !equipments.length > 0 && (
              <span className="invalid-feedback error">
                Veuillez choisir au moins un équipement
              </span>
            )}
            <Scroll />
          </div>

        </>}
    </>}
  </>

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <Loading />
    );
  } else {
    return (
      <div className="container addingPage editPage">
        <form onSubmit={handleSubmit} id="form-add">
          <div className="information-title">
            <h2>
              Modifier {annonce.type == 'house' ? 'Maison'
                : annonce.type == 'apartment' ? 'appartement'
                  : annonce.type == "new_real_estate" ? 'immoblier'
                    : annonce.type == "commercial_space" ? 'local'
                      : annonce.type == "ground" ? 'Terrain'
                        : annonce.type.replaceAll("_", " ")} {annonce.typeAnnonce && ' - ' + annonce.typeAnnonce.replaceAll("_", " ")
              }
            </h2> <CBadge color={getBadge(props.location.state.status)}>
              {textBadge(props.location.state.status)}
            </CBadge>
            <span className="snSpan snSpanE ">SN: {props.location.state.sn} </span>
          </div>
          <>
            {annonce.type === 'house' ?
              <>
                {houseForm}
              </>

              : null
            }

            {annonce.type === 'apartment' ?
              <>
                {apartmentForm}
              </>

              : null
            }
            {annonce.type === 'new_real_estate' ?
              <>
                {newForm}
              </>

              : null
            }

            {annonce.type === 'garage' ?
              <>
                {garageForm}
              </>

              : null
            }
            {annonce.type === 'office' ?
              <>
                {officeForm}
              </>

              : null
            }
            {annonce.type === 'commercial_space' ?
              <>
                {spaceForm}
              </>

              : null
            }
            {annonce.type === 'ground' ?
              <>
                {groundForm}
              </>

              : null
            }

          </>
          <>
            <div className="advInfoSzctionCard" >
              <Adresse />
              <CRow xs="12">
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="country">Pays</CLabel>
                    <CSelect
                      name="country"
                      value={country}
                      onChange={(e) => handleCountryChange(e)}
                    >
                      <option value="0"> Choisissez un pays</option>
                      {countries && countries.map((country) => {
                        return (
                          <option key={country.id} value={country.id}>
                            {country.label}
                          </option>
                        );
                      })}
                    </CSelect>
                    {submitted && !country && (
                      <span className="invalid-feedback error">
                        Error
                      </span>
                    )}
                  </CFormGroup>
                </CCol>
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="Région">Gouvernorat</CLabel>
                    <CSelect
                      name="city"
                      value={city}
                      onChange={(e) => handleCityChange(e)}
                    >
                      <option value="0"> Gouvernorat</option>
                      {cities && cities.map((city) => {
                        return (
                          <option key={city.id} value={city.id}>
                            {city.label}
                          </option>
                        );
                      })}
                    </CSelect>
                    {submitted && !city && (
                      <span className="invalid-feedback error">
                        Error
                      </span>
                    )}
                  </CFormGroup>
                </CCol>
              </CRow>

              <CRow xs="12">
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="delegate">Délégation</CLabel>
                    <CSelect
                      name="delegate"
                      value={delegate}
                      onChange={(e) => handleDelegateChange(e)}
                    >
                      <option value="0"> Choisissez une délégation</option>
                      {delegates && delegates.map((delegate) => {
                        return (
                          <option key={delegate.id} value={delegate.id}>
                            {delegate.label}
                          </option>
                        );
                      })}
                    </CSelect>
                    {submitted && !delegate && (
                      <span className="invalid-feedback error">
                        Error
                      </span>
                    )}
                  </CFormGroup>
                </CCol>
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="loclité">Localité</CLabel>
                    <CSelect
                      name="locality"
                      value={locality}
                      onChange={(e) => setLocality(e.target.value)}
                    >
                      <option value="0"> Localité</option>
                      {localities && localities.map((local) => {
                        return (
                          <option key={local.id} value={local.id}>
                            {local.label}
                          </option>
                        );
                      })}
                    </CSelect>
                    {submitted && !locality && (
                      <span className="invalid-feedback error">
                        Error
                      </span>
                    )}
                  </CFormGroup>
                </CCol>
              </CRow>

              <Scroll />
            </div>
            <div className="advInfoSzctionCard" >
              <Contact />
              <CRow xs="12">
                <CCol xs="6">
                  {newPhone && <CFormGroup>
                    <CLabel htmlFor="phoneNumber">Numéro téléphone</CLabel>
                    <PhoneInput
                      type="text"
                      onChange={(value) => setPhoneNumber(value)}
                      placeholder="Entrez un numéro de téléphone"
                      defaultCountry="TN"
                      international
                      name="phoneNumber"
                      id="phoneNumber"
                      value={phoneNumber}
                    />
                    {submitted && newPhone && phoneNumber.length > 0 && !isValidPhoneNumber(phoneNumber) && (
                      <span className="invalid-feedback error">Ce numéro de téléphone n'est pas valide</span>
                    )}
                  </CFormGroup>}
                  <CFormGroup variant="checkbox" className="checkbox">
                    <CInputCheckbox
                      onChange={changePhoneNumber}
                    />
                    <CLabel variant="checkbox" className="form-check-label">
                      Utilisez le numéro téléphone de votre compte
                    </CLabel>
                  </CFormGroup>
                </CCol>
              </CRow>
              <Scroll />
            </div>

            <div className="advInfoSzctionCard" >
              <Extra />
              <CRow>
                <CCol>
                  <CFormGroup>
                    <CKEditor
                      editor={ClassicEditor}
                      data={annonce.description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data)
                      }}
                    />


                    {submitted && !description > 0 && (
                      <span className="invalid-feedback error">Obligatoire</span>
                    )}
                  </CFormGroup>
                </CCol>
              </CRow>
              <Scroll />
            </div>

            <div className="advInfoSzctionCard" >

              <Imgs />

              <CRow>
                <CCol>
                  <CFormGroup style={{ display: "inline-block" }}>
                    <CLabel htmlFor="pImage">Principal image</CLabel>
                    <input
                      type="file"
                      name="pImage"
                      onChange={handleFileSection}
                      accept="image/*"
                    ></input>
                  </CFormGroup>
                  {uploadingImage ?
                    <span style={{ height: "fit-content", marginLeft: "10px", lineHeight: "90px" }}>
                      {" "}
                      <CSpinner color="default" size="sm" />
                    </span>
                    : !uploadingImage && oldImgP ?
                      <img style={{ height: "85px", width: "auto" }}
                        src={imgPrincipal}
                        alt="preview"
                        className="img-fluid img-thumbnail "
                      />
                      : !uploadingImage && pImageThumb ?
                        <img style={{ height: "85px", width: "auto" }}
                          src={pImageThumb}
                          alt="preview"
                          className="img-fluid img-thumbnail "
                        /> : ''}

                  {submitted && !imgPrincipal && (
                    <span className="invalid-feedback error">
                      Veuillez uploader une image
                    </span>
                  )}
                </CCol>
              </CRow><br />


              {otherImages.length > 1 &&
                <>
                  <CLabel htmlFor="pImage">Autres images</CLabel>
                  {otherImages.map((other, i) => {

                    return (

                      <span key={i} className="img-thumbnail-others">
                        {!other.principal ?
                          <>

                            <img
                              src={`${IMAGES_BASE_URL}/public/uploads/images/${annonce.type}/${other.name}`}
                              width="100"
                              className="img-fluid img-thumbnail "
                              alt=""
                            />
                            <CButton onClick={(e) => toggle(other.id, false, i)}><CIcon name="cilTrash" /></CButton>

                          </>

                          : null}
                      </span>

                    );

                  })
                  }
                  <br /><br /> </>
              }

              {imgCountA > 0 && <> <div className="imgCounter"> {imgCount} {imgCount > 1 ? ' images téléchargées sur ' : ' image téléchargée sur '}  {imgCountA} </div>
                <div className="loading-bar">
                  <div className="loading-bar-fill" style={{ width: `${imgCountA == 0 ? 0 : (imgCount / imgCountA) * 100}%` }}></div>
                </div><br /></>}
              <CLabel >ajouter des images</CLabel>
              <CRow>
                <CCol>
                  <Dropzone
                    // getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    // onSubmit={handleSubmitF}
                    accept="image/*"
                    maxFiles={10 - otherImages.length}
                    maxSizeBytes={5 * 1024 * 1024}
                  >
                    <input />
                  </Dropzone>
                </CCol>
              </CRow>
              <br />
            </div>

            {success && (
              <CAlert
                show={true}
                // autohide="5000"
                autohide={false}
                placement="top-end"
                color="success"
              >
                {`Limmobilier a été modifiée avec succès !`}
              </CAlert>
            )}

            <CRow>
              <CCol md="3" className="offset-9">
                {
                  ((!(doneuploadingfile && imgCountA == album.length)) || uploadingImage) ?
                    (
                      <CButton color="primary" block disabled>
                        <span>
                          {" "}
                          <CSpinner color="default" size="sm" />
                        </span>

                        <span> chargement des images</span>
                      </CButton>
                    )

                    : loading ? (
                      <CButton color="primary" block disabled>
                        <span>
                          {" "}
                          <CSpinner color="default" size="sm" />
                        </span>

                        <span> Valider</span>
                      </CButton>
                    )
                      :
                      <CButton color="primary" block disabled={!hasChanged} type="submit">
                        Valider
                      </CButton>

                }

              </CCol>
            </CRow>
          </>



        </form>

        <CModal show={modal} onClose={toggle}>
          <CModalHeader closeButton></CModalHeader>
          <CModalBody>
            voulez vous vraiment supprimer cette image?

          </CModalBody>
          <CModalFooter>
            <CButton
              color="primary"
              onClick={() => {
                deleteImageRealEstate(item, index);
              }}
            >
              Oui
            </CButton>

            <CButton color="secondary" onClick={toggle}>
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>

      </div>
    );
  }
}

export default EditRealEstate
