import React, { Component } from "react";
import axios from "axios";
import { API_BASE_URL, IMAGES_BASE_URL, BASE_URL } from "../../../config";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import NewsItem from "./NewsItem";
import CIcon from "@coreui/icons-react";
import { FacebookShareButton, FacebookIcon } from "react-share";
import { CButton, CCol, CFormGroup, CEmbed, CRow } from "@coreui/react";
import Comment from "./Comment";
import Weather from "../widgets/weather";
import { Helmet } from "react-helmet-async";
import apiClient from "../apiClient";

export class Article extends Component {
  constructor(props) {
    super(props);

    this.state = {
      arabic: false,
      news: "",
      newsSameCat: [],
      categoryId: null,
      ministere: null,
      commentary: "",
      nbrComments: "0",
      content: '',
      newContentMode: true,
      createdBy: localStorage.getItem("id"),
      errors: {
        commentary: "",
      },
      success: false,
      isLoaded: false,
    };
  }
  //const [new, setNews] = useState('');
  convertDate(isoDate) {
    let date = new Date(isoDate);

    return date.toLocaleDateString("en-GB");
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    localStorage.setItem("prevUrl", this.props.location.pathname);
    //console.log('props: ',this.props.location.state);
    if (this.props.location.state) {
      //console.log('props yes: ',this.props.location.state);
      let result = this.props.location.state;
      if (result.sections && result.sections.length > 0 && result.sections[0].position && result.sections[0].position < 0) {
        this.setState({ newContentMode: false });
      } else {
        const originalString = result.content;
        let index = 0;
        let replacedString = originalString.replace(/image-reference-label.png/g, () => {
          let replacement = IMAGES_BASE_URL + '/public/uploads/actualites/' + result.sections[index].path;
          index = (index + 1);
          return replacement;
        });

        ////console.log("replacedstring : ",replacedString);

        this.setState({
          content: replacedString,
        });
      }

      this.setState({
        news: result,
        categoryId: result.id,
        nbrComments: result.comments.length,
        isLoaded: true,
      });
      var arabic = /[\u0600-\u06FF]/;
      var string = result.title;

      //alert(arabic.test(string)); 
      if (arabic.test(string)) this.setState({ arabic: true });
      else (this.setState({ arabic: false }));

    }
    else {
      //console.log('props no: ',this.props.location.state);
      axios(`${API_BASE_URL}/news/show/${id}`)
        .then((res) => res.data)
        .then((result) => {
          //console.log('resulttt: ',result);
          if (result.sections && result.sections.length > 0 && result.sections[0].position && result.sections[0].position < 0) {
            this.setState({ newContentMode: false });
          } else {
            const originalString = result.content;
            let index = 0;
            let replacedString = originalString.replace(/image-reference-label.png/g, () => {
              let replacement = IMAGES_BASE_URL + '/public/uploads/actualites/' + result.sections[index].path;
              index = (index + 1);
              return replacement;
            });

            ////console.log("replacedstring : ",replacedString);

            this.setState({
              content: replacedString,
            });
          }

          this.setState({
            news: result,
            categoryId: result.id,
            nbrComments: result.comments.length,
            isLoaded: true,
          });
          var arabic = /[\u0600-\u06FF]/;
          var string = result.title;

          //alert(arabic.test(string)); 
          if (arabic.test(string)) this.setState({ arabic: true });
          else (this.setState({ arabic: false }));

        });
    }

    // Afficher actualités ayant la meme catégory que l'actualité affiché
    axios(`${API_BASE_URL}/news/same-category`, {
      params: { categoryId: this.props.match.params.catId },
    })
      .then((res) => res.data)
      .then((result) => {
        //console.log("same cat : ", result);
        this.setState({
          newsSameCat: result,
        });
      });
    axios(`${API_BASE_URL}/news/infos-ministere`, {
      params: { categoryId: this.props.match.params.catId },
    })
      .then((res) => res.data)
      .then((result) => {
        //console.log(result);
        if (result.status)
          this.setState({
            ministere: result.status,
          });
      });
  }
  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;

    if (id !== prevProps.match.params.id || this.state.success) {
      this.setState({
        isLoaded: false
      });
      if (this.props.location.state) {
        let result = this.props.location.state;
        //console.log('reeeeees 1 ' , result)
        if (result.sections && result.sections.length > 0 && result.sections[0].position && result.sections[0].position < 0) {
          this.setState({ newContentMode: false });
        } else {
          const originalString = result.content;
          let index = 0;
          let replacedString = originalString.replace(/image-reference-label.png/g, () => {
            let replacement = IMAGES_BASE_URL + '/public/uploads/actualites/' + result.sections[index].path;
            index = (index + 1);
            return replacement;
          });

          ////console.log("replacedstring : ",replacedString);

          this.setState({
            content: replacedString,
          });
        }



        this.setState({
          news: result,
          categoryId: result.category.id,
          nbrComments: result.comments.length,
          success: false,
          isLoaded: true
        });
        var arabic = /[\u0600-\u06FF]/;
        var string = result.title;

        //alert(arabic.test(string)); 
        if (arabic.test(string)) this.setState({ arabic: true });
        else (this.setState({ arabic: false }));

      }
      else {
        axios(`${API_BASE_URL}/news/show/${id}`)
        .then((res) => res.data)
        .then((result) => {
          //console.log('resulttt updated: ',result);
          if (result.sections && result.sections.length > 0 && result.sections[0].position && result.sections[0].position < 0) {
            this.setState({ newContentMode: false });
          } else {
            const originalString = result.content;
            let index = 0;
            let replacedString = originalString.replace(/image-reference-label.png/g, () => {
              let replacement = IMAGES_BASE_URL + '/public/uploads/actualites/' + result.sections[index].path;
              index = (index + 1);
              return replacement;
            });

            ////console.log("replacedstring : ",replacedString);

            this.setState({
              content: replacedString,
            });
          }



          this.setState({
            news: result,
            categoryId: result.category.id,
            nbrComments: result.comments.length,
            success: false,
            isLoaded: true
          });
          var arabic = /[\u0600-\u06FF]/;
          var string = result.title;

          //alert(arabic.test(string)); 
          if (arabic.test(string)) this.setState({ arabic: true });
          else (this.setState({ arabic: false }));

        });
      }

      axios(`${API_BASE_URL}/news/same-category`, {
        params: { categoryId: this.props.match.params.catId },
      })
        .then((res) => res.data)
        .then((result) => {
          this.setState({
            newsSameCat: result,
          });
        });
    }
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const { id } = this.props.match.params;
    if (this.handleValidation()) {
      const formData = new FormData();
      formData.append("commentary", this.state.commentary);
      //formData.append("createdBy", this.state.createdBy);
      apiClient
        .post(`/news/comment/${id}`, formData)
        .then((res) => {
          if (res.data.status.toString() === "ok") {
            this.setState({
              commentary: "",
              success: true,
            });
          }
        })
        .catch((error) => {
          //console.log("login errors : ", error);
        });
    }
  };

  handleValidation = () => {
    let fields = this.state.commentary;
    let errors = {};
    let formIsValid = true;
    if (!fields) {
      formIsValid = false;
      errors["commentary"] = "Veuillez entrer un commentaire";
    } else {
      errors["commentary"] = "";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  render() {
    const arabic = this.state.arabic;
    var classArabic = ''
    if (arabic) classArabic = 'arabicFontClass';
    const news = this.state.news;
    const newsSameCat = this.state.newsSameCat;
    const ministere = this.state.ministere;
    const urlShare = BASE_URL + this.props.match.url;
    let errors = this.state.errors;
    const nbrComments = this.state.news ? this.state.nbrComments : "0";
    if (!this.state.isLoaded) {
      return (
        <div className="container">
          <main className="l-main">
            <div className="l-section l-section--archive">
              <div className="loading_logo">
                <img
                  src={"assets/icons/tunisia-living-loading-icon.gif"}
                  alt="tunisia living loading icon"
                />
                <p>Chargement...</p>
              </div>
            </div>
          </main>
        </div>
      );
    } else {
      return (
        <div className="container newsPage">
          <Helmet>
            <title>{news.title}</title>
            <meta property="og:title" content={news.title} />
            <meta property="og:description" content={news.title} />
            <meta property="og:image" content={`${IMAGES_BASE_URL}/public/uploads/actualites/${news.path}`} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="article" />
            <meta name="description" content={news.title} />
            <meta name="keywords" content={news.title} />
          </Helmet>

          <main className="l-main">
            <div className="l-main__grid o-container">
              {this.state.news && <div className={`l-main__article l-main__content l-main__section l-article ${classArabic} `} >
                <div className="l-article__label c-topLabel">
                  <Link
                    className="c-topLabel__link"
                    to={
                      news && `/news/${news.category.id}/${news.category.slug}`
                    }
                  >
                    {news.category && news.category.label}
                  </Link>
                </div>
                <h1 className="l-article__title">{news.title}</h1>

                <div id="article-byline" className="c-byline l-article__byline">
                  <div className="c-byline__text">
                    <div className="c-byline__datesWrapper">
                      <div className="c-byline__dates">
                        <div
                          className="c-byline__date c-byline__date--pubDate toggle-switch"
                          data-expand="#bylineDateUpdated"
                          aria-expanded="false"
                          aria-label="Show updated date"
                        >
                          <span>
                            Publié le {this.convertDate(news.createdAt)}
                          </span>
                        </div>
                        <div
                          className="c-byline__date c-byline__date--modDate is-expandable "
                          id="bylineDateUpdated"
                        >
                          <span>
                            Modifié le {this.convertDate(news.updatedAt)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="l-article__featured ">
                  {news.path ? (
                    <img
                      src={`${IMAGES_BASE_URL}/public/uploads/actualites/${news.path}`}
                      alt={news.title}
                      className="c-posts__thumbnail"
                    />
                  ) : (
                    <span className="c-posts__thumbnail" />
                  )}
                </div>

                <div className="l-article__story l-main__story">
                  <article className="articleFrontClass l-article__text js-story-text">
                    <FacebookShareButton url={urlShare}>
                      <FacebookIcon size={32} round={true}></FacebookIcon>
                    </FacebookShareButton>

                    {/* {ReactHtmlParser(news.content)} */}
                    {
                      //!this.state.newContentMode ||  news.id<71 
                      (news.sections && news.sections.length > 0 && news.sections[0].position && news.sections[0].position < 0) || news.id < 71

                        ? ReactHtmlParser(news.content)
                        : ReactHtmlParser(decodeURIComponent(this.state.content))
                    }

                    {!this.state.newContentMode && news.sections &&
                      news.sections.map((section, index) => {
                        return (
                          <div key={index}>

                            {section.path && (
                              <img
                                src={`${IMAGES_BASE_URL}/public/uploads/actualites/${section.path}`}
                                alt={news.title}
                                className="c-posts__thumbnail"
                              />
                            )}
                            {ReactHtmlParser(section.content)}
                          </div>
                        );
                      })}

                    {news.youtubeLink !== "" && (
                      <CEmbed ratio="16by9">
                        <iframe
                          src={news.youtubeLink}
                          title="youtube link"
                        ></iframe>
                      </CEmbed>
                    )}
                  </article>
                  <div className="b-post-comment">
                    <div className="b-comments--el-tab-controls">
                      <span className="b-comments--el-tab js-comment-tab-control active">
                        <CIcon name="cilCommentSquare" size="lg" />{" "}
                        <strong>{nbrComments}</strong> comments
                      </span>
                    </div>
                    <div className="comments">
                      {news.comments &&
                        news.comments.map((comment) => {
                          return (
                            <Comment
                              key={comment.id}
                              avatar={
                                comment.createdBy.path
                                  ? `${IMAGES_BASE_URL}/public/uploads/members/${comment.createdBy.path}`
                                  : `${IMAGES_BASE_URL}/public/uploads/members/default_avatar.jpg`
                              }
                              commentary={comment.commentary}
                              date={this.convertDate(comment.dateCreation)}
                              owner={`${comment.createdBy.prenom} ${comment.createdBy.nom}`}
                            />
                          );
                        })}
                    </div>
                  </div>
                  {localStorage.getItem("roles") === "ROLE_MEMBER" ? (
                    <>
                      <div className="b-post-comment--el-title">
                        Ajouter un commentaire
                      </div>
                      <form onSubmit={this.handleSubmit} id="form-add">
                        <CFormGroup>
                          {" "}
                          <CCol>
                            <textarea
                              name="commentary"
                              rows="4"
                              value={this.state.commentary}
                              onChange={this.handleChange}
                            ></textarea>
                          </CCol>
                        </CFormGroup>
                        {errors.commentary.length > 0 && (
                          <CCol>
                            {" "}
                            <p
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.commentary}
                            </p>
                          </CCol>
                        )}
                        <CRow>
                          <CCol xs="12" sm="3">
                            <CButton color="primary" block type="submit">
                              Envoyer
                            </CButton>
                          </CCol>
                        </CRow>
                      </form>
                    </>
                  ) : (
                    <div className="row mt-3">
                      <div className="col-md-12">
                        {" "}
                        <Link to="/members/login"> Login </Link> ou{" "}
                        <Link to="/members/register"> Créer un compte </Link>{" "}
                        pour laisser un commentaire
                      </div>
                    </div>
                  )}
                </div>
              </div>}
              <aside className="l-main__sidebar l-sidebar">
                <div className="l-sidebar__widget u-show-tablet-landscape">
                  <Weather></Weather>
                  <div className="l-sidebar__widgetHeading c-heading c-heading--subhead c-heading--bold c-heading--underline c-posts__heading">
                    More from Global News
                  </div>

                  <ul className="c-posts ">
                    {newsSameCat.map((news) => {
                      return (
                        <NewsItem
                          item={news}
                          key={news.id}
                          title={news.title}
                          url={
                            news.category &&
                            `/news/${news.id}-${news.category.id}/${news.category.slug}/${news.slug}`
                          }
                          imageUrl={`${IMAGES_BASE_URL}/public/uploads/actualites/${news.path}`}
                          category={news.category && news.category.label}
                          date={this.convertDate(news.createdAt)}
                        />
                      );
                    })}
                  </ul><br />
                  {ministere && <div className="infoPratiques"><div className="l-sidebar__widgetHeading c-heading c-heading--subhead c-heading--bold c-heading--underline c-posts__heading">
                    Infos pratiques
                  </div>
                    <ul className="c-posts ">
                      <li style={{ paddingTop: "0px" }}>
                        <a
                          style={{ paddingTop: "8px" }}
                          className="c-posts__inner"
                          href={ministere.url}
                          target="blank"
                        >
                          {ministere.nom}
                        </a>
                      </li>
                    </ul></div>}
                </div>
              </aside>
            </div>
          </main>
        </div>
      );
    }
  }
}

export default Article;
