import React, { useEffect, useState, useContext } from "react";
import MemberContext from "./MemberContext";
import apiClient from "../apiClient";
import { API_BASE_URL, keyHeader, tokenCookie, IMAGES_BASE_URL } from "../../../config";
import { getBadge, textBadge } from "src/actions";
import axios from "axios";
import Moment from "react-moment";
import Loading from "src/components/myWidgets/loadingWidget";
import { Link } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import {
  CBadge,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CSpinner, CDataTable
} from "@coreui/react";
function MyRealEstate(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState(null);
  const [errorDel, setErrorDel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { estates, loadedEstates, setEstates } = useContext(MemberContext);

  const toggle = (id) => {
    //console.log("id real estate ", id);
    setModal(!modal);
    setItem(id);
  };
  function deleteRealEstate(id) {
    setIsLoading(true);

    apiClient
      .delete(`/real_estate/delete/${id}`)
      .then((res) => {
        toggle();
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          setItems((items) => items.filter((x) => x.id !== id));
          setEstates((items) => items.filter((x) => x.id !== id));
          setIsLoading(false);
        }
        if (
          res.data.status.toString() ===
          "ForeignKeyConstraintViolationException"
        ) {
          setErrorDel("Vous ne pouvez pas supprimer cet élément, il a des relations");
          alert("Vous ne pouvez pas supprimer cet élément, il a des relations");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
        setErrorDel("Vous ne pouvez pas supprimer cet bon plans maintenant");
        setIsLoading(false);
      });
  }
  useEffect(() => {

    if (loadedEstates) {
      setItems(estates);
      setIsLoaded(true)
      //console.log('props estates 2 <<<<<< ', estates.length);   
    }

    //   if (props.location.state) {
    //     setIsLoaded(true);
    //     setItems(props.location.state);
    //   }
    //     else {
    // const formData = new FormData();
    // formData.append("key",tokenCookie );
    // axios.post(`${API_BASE_URL}/real_estate/By_member`, formData, keyHeader)
    //     .then(response => {
    //       console.log('response --- ', response);
    //       setIsLoaded(true);
    //       setItems(response.data.items);

    //     })
    //     .catch(error => {
    //       setIsLoaded(true);
    //       setError(error);
    //         console.log('error --- ', error)

    //     });
    //   }

    // axios(`${API_BASE_URL}/real_estate/By_member/${localStorage.getItem("id")}`)
    //   .then((res) => res.data)
    //   .then(
    //     (result) => {
    //       setIsLoaded(true);
    //       setItems(result);
    //       console.log(result);
    //     },
    //     (error) => {
    //       setIsLoaded(true);
    //       setError(error);
    //     }
    //   );
  }, [estates, loadedEstates]);

  const fields = [
    {
      key: "thumb",
      label: "thumb",
      // _style: { width: "20%" },
    },
    {
      key: "Actions",
      label: "Actions",
    },
  ];

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <Loading />
    );
  } else if (!items.length) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div className="noItemsText">
              Il n'y a pas encore d'annonces ici <br /><br />
              <Link to='/real-estates/add'>
                <button className="ml-1 btn btn-info" type="button">
                  commencez à ajouter des annonces maintenant
                </button></Link>
            </div>
          </div>
        </main>
      </div>

    )
  }

  else {
    return (
      <>

        <CDataTable addTableClasses="table table-hover table-outline mb-0  d-sm-table"
          items={items}
          fields={fields}
          itemsPerPageSelect
          itemsPerPage={10}
          hover
          pagination
          scopedSlots={{
            thumb: (realEstate) => (
              <td>
                <div className="vehicle-thumbnail">

                  {realEstate.mainPicture &&
                    <img
                      src={`${IMAGES_BASE_URL}/public/uploads/images/${realEstate.type}/${realEstate.mainPicture.name}`}
                      alt=""
                    />
                  }
                </div>
                <div className="profileAnnonceCardDetails">
                  <strong>

                    <div>
                      {realEstate.type && realEstate.type.replaceAll("_", " ")}
                      {realEstate.typeHouse ? ' - ' + realEstate.typeHouse
                        : realEstate.typeApartment ? ' - ' + realEstate.typeApartment
                          : realEstate.typeCommercialSpace ? ' - ' + realEstate.typeCommercialSpace
                            : realEstate.typeNewRealEstate ? ' - ' + realEstate.typeNewRealEstate
                              : ''}
                      {realEstate.typeAnnonce && ' - ' + realEstate.typeAnnonce.replaceAll("_", " ")} <CBadge color={getBadge(realEstate.status)}>
                        {textBadge(realEstate.status)}
                      </CBadge>

                    </div>


                  </strong>
                  <div>{realEstate.price} TND</div>
                  <div>
                    <strong>Numéro de série :</strong>
                    <span> {realEstate.sn}</span>
                  </div>
                  <div>
                    <strong>Date d'ajout :{" "}</strong>
                    <span><Moment format="DD/MM/YYYY">
                      {realEstate.createdAt}
                    </Moment></span>
                  </div>
                </div>
              </td>
            ),
            Actions: (realEstate) => (
              <td>
                <Link to={{
                  pathname: `/members/profile/myItemDetails/real_estate`,
                  state: realEstate
                }}
                >
                  <CButton>
                    <img src="assets/icons/eye.svg" />
                  </CButton></Link>
                <Link to={{
                  pathname: '/members/profile/immobilier-edit',
                  state: realEstate
                }}
                >
                  <CButton>
                    <CIcon name="cilPencil" />
                  </CButton>
                </Link>
                <CButton
                  onClick={(e) => toggle(realEstate.id)}
                >
                  <CIcon name="cilTrash" />
                </CButton>
              </td>
            )
          }}
        />

        <CModal show={modal} onClose={toggle}>
          <CModalHeader closeButton></CModalHeader>
          <CModalBody>Voulez-vous vraiment supprimer ce véhicule ?</CModalBody>
          <CModalFooter>
            {/* <CButton
                                  color="primary"
                                  onClick={() => {
                                    deleteRealEstate(item);
                                  }}
                                >
                                  Oui
                                </CButton> */}
            {!isLoading && (
              <CButton
                color="primary"
                onClick={() => {
                  deleteRealEstate(item);
                }}
              >
                Oui
              </CButton>
            )}
            {isLoading && (
              <CButton color="primary" disabled>
                <span>
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Oui</span>
              </CButton>
            )}

            <CButton color="secondary" onClick={toggle}>
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
      </>
    );
  }
}

export default MyRealEstate;
