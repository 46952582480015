import React, { useEffect, useState, useContext } from "react";
import apiClient from "../apiClient";
//import { API_BASE_URL, IMAGES_BASE_URL , keyHeader , tokenCookie} from "../../../config";
import axios from "axios";
import Moment from 'react-moment';
import Loading from "src/components/myWidgets/loadingWidget";
import { Link } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { getBadge, textBadge } from "src/actions";
import {
  CBadge,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CSpinner, CDataTable
} from "@coreui/react";
import MemberContext from "./MemberContext";


function MyJobs() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState(null);
  const [errorDel, setErrorDel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { jobs, loadedJobs, setJobs } = useContext(MemberContext);

  const toggle = (id) => {
    setModal(!modal);
    setItem(id);
  };
  function deleteItem(id) {
    setIsLoading(true);

    apiClient
      .delete(`/job/delete/${id}`)
      .then((res) => {
        toggle();
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          setItems((items) => items.filter((x) => x.id !== id));
          setJobs((items) => items.filter((x) => x.id !== id));
          setIsLoading(false);
        }
        if (
          res.data.status.toString() ===
          "ForeignKeyConstraintViolationException"
        ) {
          setErrorDel("Vous ne pouvez pas supprimer cet élément, il a des relations");
          alert("Vous ne pouvez pas supprimer cet élément, il a des relations");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        //console.log('errrrr',err);
        setErrorDel("Vous ne pouvez pas supprimer cet item maintenant");
        setIsLoading(false);
      });
  }

  useEffect(() => {

    //console.log('props jobsmm --- ', jobs);   
    if (loadedJobs) {
      setItems(jobs);
      setIsLoaded(true)
      //console.log('props jobsmm 2 --- ', jobs.length);   
    }

    // const formData = new FormData();
    // formData.append("key",tokenCookie );

    // axios.post(`${API_BASE_URL}/jobs/my-jobs`, formData, keyHeader)
    //     .then(result => {
    //       console.log('res job ',result);
    //       setIsLoaded(true);
    //       setItems(result.data.items);

    //     })
    //     .catch(error => {
    //       setIsLoaded(true);
    //       setError(error);
    //         console.log('error --- ', error)

    //     });

  }, [loadedJobs, jobs]);

  const fields = [
    {
      key: "Titre",
      label: "Titre",
    },
    {
      key: "Candidats",
      label: "Candidats",
    },
    {
      key: "Actions",
      label: "Actions",
    },
  ];

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <Loading />
    );
  } else if (!items.length) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div className="noItemsText">
              vous n'avez pas encore publié d'offres d'emploi<br /><br />
              <Link to='/jobs/publish-job-offer'>
                <button className="ml-1 btn btn-info" type="button">
                  commencez à publié des offres maintenant
                </button></Link>
            </div>
          </div>
        </main>
      </div>

    )
  }
  else {
    return (
      <>

        <CDataTable addTableClasses="table table-hover table-outline mb-0  d-sm-table tableWithHead"
          items={items}
          fields={fields}
          itemsPerPageSelect
          itemsPerPage={10}
          hover
          sorter
          pagination
          scopedSlots={{
            Titre: (job) => (
              <td>
                <div className="profileAnnonceCardDetails">
                  <strong>
                    <div>
                      {job.title} <CBadge color={getBadge(job.status)}>
                        {textBadge(job.status)}
                      </CBadge>
                    </div>
                  </strong>
                  <div>
                    <strong>Numéro de série :</strong>
                    <span> {job.sn}</span>
                  </div>
                  <div>
                    <strong>Date d'ajout :{" "}</strong>
                    <span><Moment format="DD/MM/YYYY">
                      {job.createdAt}
                    </Moment></span>
                  </div>
                </div>
              </td>
            ),
            Candidats: (job) => (
              <td style={{ verticalAlign: 'middle' }}>
                {job.nbrCandicature > 0 ? <Link to={`/members/profile/jobs-candidats/${job.id}/`}
                >{job.nbrCandicature} candidats</Link> : <span>pas encore de candidats</span>}
              </td>
            ),
            Actions: (job) => (
              <td>
                <Link to={{
                  pathname: `/members/profile/myItemDetails/job`,
                  state: job
                }}
                >
                  <CButton>
                    <img src="assets/icons/eye.svg" />
                  </CButton></Link>
                <Link to={{
                  pathname: '/members/profile/jobs-edit',
                  state: job
                }}
                >
                  <CButton >
                    <CIcon name="cilPencil" />
                  </CButton>
                </Link>
                <CButton
                  onClick={(e) => toggle(job.id)}
                >
                  <CIcon name="cilTrash" />
                </CButton>
              </td>
            )
          }}
        />

        <CModal show={modal} onClose={toggle}>
          <CModalHeader closeButton></CModalHeader>
          <CModalBody>Voulez-vous vraiment supprimer ce job?</CModalBody>
          <CModalFooter>
            {/* <CButton
                                  color="primary"
                                  onClick={() => {
                                    deleteAnimal(item);
                                  }}
                                >
                                  Oui
                                </CButton> */}

            {!isLoading && (
              <CButton
                color="primary"
                onClick={() => {
                  deleteItem(item);
                }}
              >
                Oui
              </CButton>
            )}
            {isLoading && (
              <CButton color="primary" disabled>
                <span>
                  <CSpinner color="default" size="sm" />
                </span>

                <span> Oui</span>
              </CButton>
            )}

            <CButton color="secondary" onClick={toggle}>
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>

      </>
    )
  }
}

export default MyJobs
