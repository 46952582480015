import React, { useState, useEffect } from 'react';
import FavoriteContext from './FavoriteContext';
import { API_BASE_URL } from 'src/config';
import axios from 'axios';
import { logoutLocal, isLogin } from '../middleware/auth';
import apiClient from './apiClient';

const FavoriteProvider = ({ children }) => {
  const [favorites, setFavorites] = useState(null);
  const [banners, setBanners] = useState([]);


  const updateFavorites = (id, table) => {
    //console.log("glob id-", id, '-------table------', table);
    if (isLogin()) {
      return new Promise((resolve, reject) => {
        let item = { id: id, table: table };
        const formData = new FormData();
        //formData.append("user", tokenCookie);
        formData.append("item", id);
        formData.append("itemtable", table);

        apiClient
          .post("/setFavorite", formData)
          .then((res) => {
            //console.log(res);
            if (res.data.action === "delete") {
              //console.log("delete glob");
              setFavorites((prevFavorites) =>
                prevFavorites.filter((item) => item.id != id || item.table !== table)
              );
            } else if (res.data.action === "add") {
              //console.log("add glob");
              setFavorites((prevFavorites) => [...prevFavorites, item]);
            }
            resolve(true);
            //console.log("fav list updated glob - " ,favorites);

          })
          .catch((error) => {
            //console.log(error);
            if (error.response && error.response.status === 401) {
              //console.log('error.response: ',error.response)
              logoutLocal();
              window.location.replace("/members/login");
            }
            reject(false);
          });
      });
    } else {
      return 'log';
    }
  };


  const checkFavorite = (id, table) => {
    if (isLogin() && favorites) {
      let itemToCheck = { id: id, table: table };
      const exists = favorites.some(item => item.id == itemToCheck.id && item.table === itemToCheck.table);

      //console.log('check fav glob-',exists);
      return exists;
    }
  };

  const removeFromFavorites = (id, table) => {
    //console.log("glob  remove id-",id,'-------table------',table);
    if (isLogin() && favorites) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        //formData.append("user", tokenCookie);
        formData.append("item", id);
        formData.append("itemtable", table);

        apiClient
          .post("/setFavorite", formData)
          .then((res) => {
            //console.log(res);
            if (res.data.action === "delete") {
              //console.log("delete glob");
              setFavorites((prevFavorites) =>
                prevFavorites.filter((item) => item.id != id || item.table !== table)
              );
            }
            resolve(true);
            //console.log("fav list updated glob - " ,favorites);

          })
          .catch((error) => {
            //console.log(error);
            if (error.response && error.response.status === 401) {
              //console.log('error.response: ',error.response)
              logoutLocal();
              window.location.replace("/members/login");
            }
            reject(false);

          });
      });
    }
  };



  useEffect(() => {
    axios(`${API_BASE_URL}/banners/all`)
      .then((res) => res.data)
      .then(
        (result) => {
          //console.log('resultbbbb<<<', result);
          setBanners(result);

        },
        (error) => {
        }
      );
    // Fetch favorites when the component mounts
    if (isLogin()) {
      //console.log('fav list global == 2');

      apiClient
        .post("/favoriteList/By_member")
        .then((result) => {
          // console.log('fav list global ', result);
          if (result.data.status === 2) {
            // console.log('fav list global == 2');
          } else {
            // console.log('fav list global');
            //  console.log(typeof result.data.favList,'---',result.data.favList);
            var myData = Object.keys(result.data.favList).map(key => {
              return result.data.favList[key];
            })
            // console.log(typeof myData,'---',myData);

            setFavorites(myData);
            // setFavCount(result.data.animal.length+result.data.article.length+result.data.goodPlans.length+result.data.job.length+result.data.realEstate.length+result.data.service.length+result.data.vehicle.length)
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    } else {
      // console.log('not logged in global');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run only once when the component mounts


  const value = {
    favorites,
    banners,
    checkFavorite,
    updateFavorites,
    removeFromFavorites,
  };

  return (
    <FavoriteContext.Provider value={value}>
      {children}
    </FavoriteContext.Provider>
  );
};

export default FavoriteProvider;
