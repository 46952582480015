import React, { Component } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  CButton,
  CCol,
  CRow,
  CContainer,
  CCardGroup,
  CCard,
  CCardBody,
  CAlert,
  CSelect,
  CSpinner,
} from "@coreui/react";

import PhoneInput, { getCountries, isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { API_BASE_URL, keyHeader } from "../../../config";
import axios from "axios";
import { Link } from "react-router-dom";
import { temp_sub_url } from "../../../config";

const validEmailRegex = RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);

export class RegisterMember extends Component {
  constructor(props) {
    super(props);

    this.state = {
      captchaToken: '',
      doneRegistering: false,
      loading: false,
      cv: null,
      other: null,
      nom: "",
      prenom: "",
      email: "",
      password: "",
      phoneNumber: "",
      passwordConfirmation: "",
      emailErrors: "",
      emailErrorsA: "",
      captchaErrors: "",
      typeAccount: "1",
      fileslimit: true,
      matricule: "",
      person: "",
      adresse: "",
      website: "",
      desc: "",
      showPass: false,
      errors: {
        nom: "",
        prenom: "",
        email: "",
        password: "",
        passwordConfirmation: "",
        phoneNumber: "",
        typeAccount: "",
        files: "",
        matricule: "",
        person: "",
        adresse: "",
        website: "",
        logo: ""
      },
      success: "",
      image: null,

      croppedImage: null,
      src: null,
      crop: {
        x: 10,
        y: 10,
        width: 120,
        height: 120,
        aspect: 1 / 1,
      },
    };

    // Define the countries you want to exclude
    this.excludeCountries = ['IL']; // Add more country codes as needed, e.g., ['US', 'GB']

    // Generate the list of allowed countries by filtering out the excluded ones
    this.allowedCountries = getCountries().filter(
      (country) => !this.excludeCountries.includes(country)
    );
  }

  //componentDidMount() {
  // window.grecaptcha.ready(() => {
  //   window.grecaptcha.execute('6Ld0TcgpAAAAANxm4lF75_QlEAPOV5kbbQYXa-EY', { action: 'submit' }).then(token => {
  //     this.setState({ captchaToken: token }); // Set captchaToken in state
  //   });
  // });
  //}

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () =>
          this.setState({
            src: reader.result,
          }),
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  onImageLoaded = (image) => {
    this.imageRef = image;
  };
  onCropComplete = (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImageUrl });
    }
  };
  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result, "cropped.jpg");
      };
    });
  }
  onCropChange = (crop) => {
    this.setState({ crop });
  };
  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage });
  }
  handleValidation = () => {
    let fieldNom = this.state.nom;
    let fieldPrenom = this.state.prenom;
    let fieldEmail = this.state.email;
    let fieldPhoneNumber = this.state.phoneNumber;
    let fieldPassword = this.state.password;
    let fieldPasswordConfirmation = this.state.passwordConfirmation;
    let fieldother = this.state.other;
    let fieldmatricule = this.state.matricule;
    let fieldwebsite = this.state.website;
    let fieldperson = this.state.person;
    let fieldadresse = this.state.adresse;
    let fieldlogo = this.state.croppedImage;
    let errors = {};
    let formIsValid = true;
    if (!fieldNom) {
      formIsValid = false;
      errors["nom"] = "Le nom ne peut pas être vide";
    } else {
      errors["nom"] = "";
    }
    if (this.state.typeAccount == "1") {
      if (!fieldPrenom) {
        formIsValid = false;
        errors["prenom"] = "Le prénom ne peut pas être vide";
      } else {
        errors["prenom"] = "";
      }
    }
    if (this.state.typeAccount == "2") {
      if (!fieldmatricule) {
        formIsValid = false;
        errors["matricule"] = "Le matricule ne peut pas être vide";
      } else {
        errors["matricule"] = "";
      }
      if (!fieldwebsite) {
        formIsValid = false;
        errors["website"] = "Le site web ne peut pas être vide";
      } else {
        errors["website"] = "";
      }

      if (!fieldlogo) {
        formIsValid = false;
        errors["logo"] = "Veuillez ajouter le logo de la société";
      } else {
        errors["logo"] = "";
      }

      if (!fieldother) {
        formIsValid = false;
        errors["files"] = "Veuillez ajouter les documents de la société";
        //console.log(errors["files"]);
      } else {
        errors["files"] = "";
      }
      if (!fieldperson) {
        formIsValid = false;
        errors["person"] = "Le person ne peut pas être vide";
      } else {
        errors["person"] = "";
      }
      if (!fieldadresse) {
        formIsValid = false;
        errors["adresse"] = "L'adresse ne peut pas être vide";
      } else {
        errors["adresse"] = "";
      }
    }

    if (!fieldEmail) {
      formIsValid = false;
      errors["email"] = "L'email ne peut pas être vide";
    } else {
      errors["email"] = "";
    }
    if (!validEmailRegex.test(fieldEmail)) {
      formIsValid = false;
      errors["email"] = "Veuillez entrer une adresse mail valide";
    } else {
      errors["email"] = "";
    }
    if (!fieldPhoneNumber || fieldPhoneNumber == undefined
      || (this.state.phoneNumber && this.state.phoneNumber == undefined && (!isValidPhoneNumber(this.state.phoneNumber)))) {
      formIsValid = false;
      errors["phoneNumber"] = "Veuillez entrer un numéro de téléphone valide";
    } else {
      errors["phoneNumber"] = "";
    }

    // if (this.state.phoneNumber && this.state.phoneNumber == undefined && (!isValidPhoneNumber(this.state.phoneNumber))) {
    //   errors["phoneNumber"] = "Veuillez entrer un numéro de téléphone valide";
    //  } 
    //else {
    //   errors["phoneNumber"] = "";
    // }

    if (fieldPassword.length < 8) {
      formIsValid = false;
      errors["password"] =
        "Le mot de passe doit contenir au moins 8 caractères";
    } else {
      errors["password"] = "";
    }
    if (fieldPasswordConfirmation !== fieldPassword) {
      formIsValid = false;
      errors["passwordConfirmation"] =
        "La confirmation ne correspond pas au mot de passe";
    } else {
      errors["passwordConfirmation"] = "";
    }
    if (!this.state.fileslimit) {
      formIsValid = false;
      errors["files"] = "Veuillez ne pas ajouter plus que 4 fichiers!";
    } else {
      //  errors["files"] = "";
    }

    this.setState({ errors: errors });

    if (!formIsValid) window.scrollTo(0, 200);
    return formIsValid;
  };

  viewPassword = () => {
    let show = !this.state.showPass;
    this.setState({
      showPass: show,
    });
  };

  handleFilecv = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    //console.log(file);
    reader.onloadend = () => {
      this.setState({
        cv: file,
      });
    };
    reader.readAsDataURL(file);

  };

  handleFileother = (event) => {
    let reader = new FileReader();
    let files = event.target.files;
    if (files.length > 4) {
      let errorss = this.state.errors;
      errorss["files"] = "Veuillez ne pas ajouter plus que 4 fichiers!";
      this.setState({
        fileslimit: false,
        errors: errorss,
      });
    } else {
      let errorss = this.state.errors;
      errorss["files"] = "";
      let file = event.target.files[0];
      //console.log(file);
      reader.onloadend = () => {
        this.setState({
          other: file,
          fileslimit: true,
          errors: errorss,
        });
      };
      reader.readAsDataURL(file);
    }

  };

  // handleFilecv = (event) => {
  //   let reader = new FileReader();
  //   let file = event.target.files[0];
  //   reader.onloadend = () => {
  //     this.setState({
  //       cv: file,
  //     });
  //   };
  //   reader.readAsDataURL(file);
  //   setTimeout(() => {
  //     console.log(this.state);
  //   }, 200);
  // };
  // handleFileother = (event) => {
  //   console.log(event.target.files);
  //   let files = event.target.files;
  //   if (files.length > 4) {
  //     let errorss = this.state.errors;
  //     errorss["files"] = "Veuillez ne pas ajouter plus que 4 fichiers!";
  //     this.setState({
  //       fileslimit: false,
  //       errors: errorss,
  //     });
  //   } else {
  //     let errorss = this.state.errors;
  //     errorss["files"] = "";
  //     this.setState({
  //       other: files,
  //       fileslimit: true,
  //       errors: errorss,
  //     });
  //   }
  // };
  handleFile = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        image: file,
      });
    };
    reader.readAsDataURL(file);
    /* this.setState({
      image: event.target.files[0],
    });*/
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    this.setState({ emailErrors: "", emailErrorsA: "" });
    //let captcha = '';
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute('6Ld0TcgpAAAAANxm4lF75_QlEAPOV5kbbQYXa-EY', { action: 'submit' }).then(token => {
        //console.log('statexxxxxxxxx : ', token);
        if (this.handleValidation() && token) {
          const formData = new FormData();
          formData.append("avatar", this.state.croppedImage);
          formData.append("nom", this.state.nom);
          formData.append("prenom", this.state.prenom);
          formData.append("email", this.state.email);
          formData.append("password", this.state.passwordConfirmation);
          formData.append("passwordConfirmation", this.state.passwordConfirmation);
          formData.append("phoneNumber", this.state.phoneNumber);
          formData.append("typeAccount", this.state.typeAccount);
          formData.append("cv", this.state.cv);
          formData.append("other", this.state.other);
          formData.append("matricule", this.state.matricule);
          formData.append("person", this.state.person);
          formData.append("adresse", this.state.adresse);
          formData.append("website", this.state.website);
          formData.append("desc", this.state.desc);
          formData.append('captchaToken', token);

          // console.log(formData.get('avatar'));
          // console.log(formData.get('cv'));
          // console.log(formData.get('other'));

          axios
            .post(API_BASE_URL + "/members/register", formData, keyHeader)
            .then((res) => {
              // console.log(res.data);
              if (res.data.status.toString() == "captcha-failed") {
                // console.log('xxxxxxxxx');

                this.setState({
                  captchaErrors:
                    "Google Recaptcha n'a pas pu vérifier que vous n'êtes pas un robot, veuillez réessayer .",
                });
              }
              else
                if (
                  res.data.status.toString() ===
                  "The email provided already has an account!"
                ) {
                  this.setState({
                    emailErrors:
                      "Cet email est déjà utilisé! Veuillez choisir un autre",
                    emailErrorsA: " عنوان االبريد الإلكتروني هذا مستخدم بالفعل مسبقاً ، الرجاء إدخال عنوان مختلف "
                  });
                  window.scrollTo(0, 200);
                  this.setState({ loading: false });
                }
                //  else {
                //   this.setState({ emailErrors: "" });
                //   this.setState({ loading: false });
                // }
                else if (res.data.status.toString() === "ok") {
                  this.setState({
                    doneRegistering: true,
                    success:
                      "Votre compte a été créé avec succès, nous vous envoyons un e-mail de confirmation",
                  });

                  // document.getElementById("form-add").reset();
                  this.setState({ loading: false });
                  // setTimeout(() => {
                  //   window.location.href = temp_sub_url + "/members/login";
                  // }, 2000);
                }
              this.setState({ loading: false });
            })

            .catch((error) => {
              // console.log("Register errors : ", error);
            });
        } else {
          // console.log("not valid");
          this.setState({ loading: false });
        }
      });
    });



  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handlePhoneNumber = (phoneNumber) => this.setState({ phoneNumber });
  render() {
    const { errors } = this.state;
    if (this.state.doneRegistering)
      return (
        <div className="container">
          <main className="l-main lmain2">
            <div className="l-section l-section--archive">
              <div className="loading_logo">
                <br />
                <img
                  src={"assets/icons/success.png"}
                  alt="tunisia living loading icon"
                />
                <p style={{ color: "#1c9070" }}>
                  Votre compte a été créé avec succès, nous vous envoyons un
                  e-mail de confirmation
                </p>
              </div>
            </div>
          </main>
        </div>
      );
    else
      return (
        <div className="c-app c-default-layout flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md="6">
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <form onSubmit={this.handleSubmit} id="form-add" className="registerMemberForm">
                        <h1>Créer votre compte</h1>

                        <div className="mb-3">
                          <br />
                          <label htmlFor="typeAccount" >Type de compte</label>
                          {/* <CSelect
                name="typeAccount"
                value={this.state.typeAccount}
                onChange={this.handleChange}
              >
                <option value=""> --------- </option>
                <option  value="1">Particulier</option>
                <option  value="2">Société</option>
            
              </CSelect> */}

                          <label
                            className="compteradio"
                            style={{ marginRight: "30px" }}
                          >
                            <input
                              name="typeAccount"
                              type={"radio"}
                              value="1"
                              onChange={this.handleChange}
                              defaultChecked
                            ></input>
                            Particulier
                          </label>
                          <label className="compteradio">
                            <input
                              name="typeAccount"
                              type={"radio"}
                              value="2"
                              onChange={this.handleChange}
                            ></input>
                            Société
                          </label>
                        </div>
                        {this.state.typeAccount === "2" && (
                          <>
                            <div className="mb-3">
                              <label htmlFor="nom">Nom Société*</label>
                              <input
                                type="text"
                                name="nom"
                                value={this.state.nom}
                                onChange={this.handleChange}
                              />{" "}
                              {errors.nom && errors.nom.length > 0 && (
                                <CCol>
                                  {" "}
                                  <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.nom}
                                  </p>
                                </CCol>
                              )}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="matricule">Matricule</label>
                              <input
                                type="text"
                                name="matricule"
                                value={this.state.matricule}
                                onChange={this.handleChange}
                              />

                              {errors.matricule &&
                                errors.matricule.length > 0 && (
                                  <CCol>
                                    {" "}
                                    <p
                                      className="invalid-feedback"
                                      style={{ display: "block" }}
                                    >
                                      {errors.matricule}
                                    </p>
                                  </CCol>
                                )}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="person">Person en charge</label>
                              <input
                                type="text"
                                name="person"
                                value={this.state.person}
                                onChange={this.handleChange}
                              />{" "}
                              {errors.person && errors.person.length > 0 && (
                                <CCol>
                                  {" "}
                                  <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.person}
                                  </p>
                                </CCol>
                              )}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="adresse">Adresse*</label>
                              <input
                                type="text"
                                name="adresse"
                                value={this.state.adresse}
                                onChange={this.handleChange}
                              />{" "}
                              {errors.adresse && errors.adresse.length > 0 && (
                                <CCol>
                                  {" "}
                                  <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.adresse}
                                  </p>
                                </CCol>
                              )}
                            </div>
                          </>
                        )}

                        {this.state.typeAccount === "1" && (
                          <>
                            <div className="mb-3">
                              <label htmlFor="nom">Nom *</label>
                              <input
                                type="text"
                                name="nom"
                                value={this.state.nom}
                                onChange={this.handleChange}
                              />{" "}
                              {errors.nom && errors.nom.length > 0 && (
                                <CCol>
                                  {" "}
                                  <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.nom}
                                  </p>
                                </CCol>
                              )}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="prenom">Prénom *</label>
                              <input
                                type="text"
                                name="prenom"
                                value={this.state.prenom}
                                onChange={this.handleChange}
                              />

                              {errors.prenom && errors.prenom.length > 0 && (
                                <CCol>
                                  {" "}
                                  <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.prenom}
                                  </p>{" "}
                                </CCol>
                              )}
                            </div>
                          </>
                        )}

                        <div className="mb-3">
                          <label htmlFor="email">E-mail *</label>
                          <input
                            type="text"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                          />

                          {errors.email.length > 0 && (
                            <CCol>
                              <p
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {errors.email}
                              </p>
                            </CCol>
                          )}
                          {this.state.emailErrors.length > 0 && (
                            <CCol>
                              <p
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {this.state.emailErrors}
                              </p>
                              <p
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {this.state.emailErrorsA}
                              </p>
                            </CCol>
                          )}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="phoneNumber">
                            Numéro téléphone *
                          </label>
                          <PhoneInput
                            type="text"
                            onChange={(phoneNumber) =>
                              this.setState({ phoneNumber })
                            }
                            placeholder="Enter phone number"
                            defaultCountry="TN"
                            international
                            name="phoneNumber"
                            id="phoneNumber"
                            value={this.state.phoneNumber}
                            countries={this.allowedCountries}
                          />

                          {errors.phoneNumber.length > 0 && (
                            <CCol>
                              <p
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {errors.phoneNumber}
                              </p>
                            </CCol>
                          )}
                        </div>

                        {this.state.typeAccount === "2" && (
                          <div className="mb-3">
                            <label htmlFor="website">Site web</label>
                            <input
                              type="text"
                              name="website"
                              value={this.state.website}
                              onChange={this.handleChange}
                            />
                            {errors.website &&
                              errors.website.length > 0 && (
                                <CCol>
                                  {" "}
                                  <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.website}
                                  </p>
                                </CCol>
                              )}
                          </div>
                        )}

                        <div className="mb-3">
                          <label htmlFor="password">Mot de passe*</label>
                          <div>
                            <input
                              type={this.state.showPass ? "text" : "password"}
                              name="password"
                              value={this.state.password}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        {errors.password.length > 0 && (
                          <CCol>
                            <p
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.password}
                            </p>
                          </CCol>
                        )}
                        <div className="mb-3">
                          <label htmlFor="passwordConfirmation">
                            Confirmer le mot de passe *
                          </label>
                          <input
                            type={this.state.showPass ? "text" : "password"}
                            name="passwordConfirmation"
                            value={this.state.passwordConfirmation}
                            onChange={this.handleChange}
                          />
                        </div>
                        {errors.passwordConfirmation.length > 0 && (
                          <CCol>
                            {" "}
                            <p
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.passwordConfirmation}
                            </p>
                          </CCol>
                        )}

                        <CCol className="showPassRow">
                          <label htmlFor="showpassword">
                            <input
                              name="showpassword"
                              type="checkbox"
                              onChange={this.viewPassword}
                            />
                            Show password
                          </label>
                        </CCol>

                        {this.state.typeAccount === "1" && (
                          <>  <CRow>
                            <CCol md="12">
                              <label htmlFor="content">
                                Ajouter votre photo de profil
                              </label>
                              <input
                                type="file"
                                name="image"
                                onChange={this.onSelectFile}
                                accept="image/*"
                              />
                            </CCol>
                            {this.state.src && (
                              <p> <ReactCrop
                                src={this.state.src}
                                crop={this.state.crop}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                              /></p>
                            )}
                          </CRow>
                            <br />
                            <CRow>
                              <CCol className="mb-3">
                                <label htmlFor="cv">Ajouter votre cv</label>
                                <input
                                  type="file"
                                  name="cv"
                                  onChange={this.handleFilecv}
                                  accept=".pdf,.jpg,.png,jpeg"
                                />
                              </CCol>
                            </CRow>
                            <br />
                            <CRow>
                              <CCol className="mb-3">
                                <label htmlFor="other">
                                  Ajouter d'autres documents
                                </label>
                                <input
                                  type="file"
                                  name="other"
                                  onChange={this.handleFileother}
                                  accept=".pdf,.jpg,.png,jpeg"
                                />
                              </CCol>
                            </CRow>
                            {errors.files && errors.files.length > 0 && (
                              <CCol>
                                <p
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.files}
                                </p>
                              </CCol>
                            )}
                            <br />

                          </>
                        )}

                        {this.state.typeAccount === "2" && (
                          <>
                            <br />
                            <CRow>
                              <CCol className="mb-3">
                                <label htmlFor="content">
                                  Ajouter Logo de la société
                                </label>
                                <input
                                  type="file"
                                  name="image"
                                  onChange={this.onSelectFile}
                                  accept="image/*"
                                />
                              </CCol>
                              {this.state.src && (
                                <ReactCrop
                                  src={this.state.src}
                                  crop={this.state.crop}
                                  onImageLoaded={this.onImageLoaded}
                                  onComplete={this.onCropComplete}
                                  onChange={this.onCropChange}
                                />
                              )}

                              {errors.logo &&
                                errors.logo.length > 0 && (
                                  <CCol>
                                    {" "}
                                    <p
                                      className="invalid-feedback"
                                      style={{ display: "block" }}
                                    >
                                      {errors.logo}
                                    </p>
                                  </CCol>
                                )}

                            </CRow>

                            <br />
                            <CRow>
                              <CCol className="mb-3">
                                <label htmlFor="other">
                                  Ajouter les documents de la société
                                </label>

                                <input
                                  type="file"
                                  name="other"
                                  onChange={this.handleFileother}
                                  accept=".pdf,.jpg,.png,jpeg"
                                />
                              </CCol>
                            </CRow>
                            {errors.files && errors.files.length > 0 && (
                              <CCol>
                                <p
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.files}
                                </p>
                              </CCol>
                            )}
                            <br />

                            <CRow>
                              <CCol className="mb-3">
                                <textarea
                                  name="desc"
                                  value={this.state.desc}
                                  onChange={this.handleChange}
                                  placeholder="Description"
                                  style={{ height: "102px" }}
                                />
                              </CCol>
                            </CRow>
                            <br />
                          </>
                        )}
                        <CRow>
                          <CCol xs="12" sm="6">
                            {/* <CButton color="primary" block type="submit">
                            Créer votre compte
                          </CButton> */}
                            {!this.state.loading && (
                              <CButton color="primary" block type="submit">
                                Créer votre compte
                              </CButton>
                            )}
                            {this.state.loading && (
                              <CButton color="primary" block disabled>
                                <span>
                                  <CSpinner color="default" size="sm" />
                                </span>

                                <span> Créer votre compte</span>
                              </CButton>
                            )}
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <br />
                            {this.state.success !== "" ? (
                              <CAlert color="success" closeButton>
                                {this.state.success}
                              </CAlert>
                            ) : (
                              ""
                            )}
                            {this.state.captchaErrors.length > 1 ? (
                              <> <CAlert color="danger" >
                                {this.state.captchaErrors}
                              </CAlert>
                                <CAlert color="danger" >
                                  تعذر التحقق من كونك لست روبوت، الرجاء المحاولة مرة اخرى
                                </CAlert>
                              </>
                            ) : (
                              ""
                            )}
                          </CCol>
                        </CRow>
                      </form>

                      <CRow>
                        <CCol xs="12" sm="12" className="registerNow">
                          Vous avez un compte?{" "}
                          <Link to="/members/login">Connectez vous ici</Link>
                        </CCol></CRow>
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      );
  }
}

export default RegisterMember;
