import React, { Component } from "react";
import { API_BASE_URL } from "src/config";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CSpinner
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

export class VideoData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      team1: "",
      team2: "",
      loginErrors: "",
    };
  }
  componentDidMount() {
    fetch("https://tunisialiving.tn/get_latest_video.php")
      .then((res) => res.json())
      .then(
        (result) => {
          //console.log('result:', result);
        },
        (error) => {
          //console.log('error:', error);
        }
      );

  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {

    this.setState({ loginErrors: "" });

    this.setState({
      isloading: true,
    });

    axios
      .post(API_BASE_URL + "/6543876/addVideoData",
        this.state
      )
      .then((res) => {
        //console.log('res : ', res.data)
        if (res.data.status == 'ok') {

          this.setState({
            isloading: false,
          });

        }
        else {
          this.setState({
            isloading: false,
            loginErrors: "Une erreur s'est produite, veuillez réessayer",
          });
        }
      })
      .catch((error) => {
        // console.log("login errorssssss : ", error);
        this.setState({
          isloading: false,
          loginErrors: "error !.",
        });
      });

    event.preventDefault();
  };

  render() {
    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="8">
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <form onSubmit={this.handleSubmit}>
                      <h1>Results</h1><br />
                      <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-user" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type="text"
                          placeholder="team1"
                          name="team1"
                          value={this.state.team1}
                          onChange={this.handleChange}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-user" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type={"text"}
                          placeholder="team2"
                          name="team2"
                          value={this.state.team2}
                          onChange={this.handleChange}
                        />
                      </CInputGroup>

                      {this.state.loginErrors && <>
                        <p className="alert-danger">{this.state.loginErrors}</p>

                      </>}
                      <CRow>
                        <CCol xs="6">
                          <CButton
                            color="primary"
                            className="px-4"
                            type="submit"
                            disabled={this.state.isloading}
                          >
                            {this.state.isloading && <CSpinner size="sm" />}   Submit
                          </CButton>
                        </CCol>
                      </CRow>

                    </form>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
}

export default VideoData;
