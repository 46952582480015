import React, { useEffect, useState } from "react";
import { API_BASE_URL, IMAGES_BASE_URL, keyHeader, tokenCookie } from "../../../config";
import axios from "axios";
import {
  CButton,
  CSpinner,
  CAlert
} from "@coreui/react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Loading from "src/components/myWidgets/loadingWidget";
import apiClient from "../apiClient";

function Candidats(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [jobTitle, setJobTitle] = useState('');
  const [company, setCompany] = useState('');
  const [companyAdres, setCompanyAdres] = useState('');
  const [loading, setLoading] = useState(false);
  const [closed, setClosed] = useState(false);
  const [nom, setNom] = useState('')

  const { id } = props.match.params;
  const emailSubject = "Réception de votre candidature et proposition d'entretien";
  //const {emailBody} =;
  useEffect(() => {

    const formData = new FormData();

    formData.append("id", id);

    apiClient.post(`/get-candidates-by-job-offer`, formData)
      .then(result => {
        // console.log('resss candsss',result);
        setIsLoaded(true);
        setItems(result.data.items);
        setJobTitle(result.data.items[0].jobOffer.title);
        setCompany(result.data.company);
        setCompanyAdres(result.data.companyAdres);
        setClosed(result.data.closed);

        //console.log(emailSubject);

      })
      .catch(error => {
        setIsLoaded(true);
        setError(error);
        //console.log('error --- ', error)

      });

    // axios(`${API_BASE_URL}/get-candidates-by-job-offer/${id}`)
    //   .then((res) => res.data)
    //   .then(
    //     (result) => {
    //       setIsLoaded(true);
    //       setItems(result.items);
    //       setJobTitle(result.items[0].jobOffer.title);
    //       setCompany(result.company);
    //       //console.log(result);
    //     },
    //     (error) => {
    //       setIsLoaded(true);
    //       setError(error);
    //     }
    //   );
  }, [id]);
  const CloseJob = () => {
    //console.log('close')
    const formData = new FormData();
    setLoading(true)

    formData.append("id", id);

    apiClient.post(`/toggle-job`, formData)
      .then(result => {
        //console.log(result);
        setLoading(false);
        setClosed(result.data.closed)
      })
      .catch(error => {
        setLoading(false)
        //console.log('error --- ', error)

      });
  }
  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <Loading />
    );
  } else {
    return (
      <>

        {closed && <CAlert color="danger" className='closedAnnonceNote'>
          this offer is closed cuurently !
        </CAlert>}
        <p>
          Vous avez <strong>{items.length}</strong> canditature(s) pour le poste{" "}
          <strong>
            {jobTitle}
          </strong>
          {!loading && !closed ?
            <CButton style={{ float: 'right', marginRight: '20px' }} color="primary" onClick={CloseJob}>Close this Job</CButton>
            : loading && !closed ?
              <CButton style={{ float: 'right', marginRight: '20px' }} color="primary" disabled>
                <span><CSpinner color="default" size="sm" /></span> Close this Job</CButton>
              : !loading && closed ?
                <CButton style={{ float: 'right', marginRight: '20px' }} color="primary" onClick={CloseJob}>Open this Job</CButton>
                : loading && closed ?
                  <CButton style={{ float: 'right', marginRight: '20px' }} color="primary" disabled>
                    <span><CSpinner color="default" size="sm" /></span> Open this Job</CButton>
                  : null}
        </p>
        <table className="table table-hover table-outline mb-0  d-sm-table candidatsTable">
          <thead className="thead-light" style={{ display: 'table-header-group' }}>
            <tr>
              <th>Candidats</th>
              <th style={{ textAlign: 'center' }}>Contact</th>
              <th style={{ textAlign: 'center' }}>Cv</th>
              <th style={{ textAlign: 'center' }}>candidat Msg</th>
              <th style={{ textAlign: 'center' }}>Détail</th>
            </tr>
          </thead>
          <tbody>
            {items.map((candidature, index) => {
              let emailBody = `Cher/Chère ${candidature.candidate.prenom}, \n
  Nous vous remercions vivement d'avoir soumis votre candidature pour le poste de ${jobTitle} chez ${company}.\n
  Nous avons bien reçu votre dossier et nous tenons à vous informer que nous l'avons examiné avec attention.\n
  Après avoir pris en considération votre expérience, vos compétences et vos qualifications, nous sommes intéressés par votre profil et aimerions vous rencontrer pour discuter plus en détail de votre candidature ainsi que des opportunités au sein de notre entreprise.\n
  Nous sommes donc ravis de vous proposer un entretien en personne dans nos locaux situés à ${companyAdres}. \n
  Cet entretien nous permettra de mieux vous connaître, de discuter de vos motivations et de répondre à toutes vos questions concernant le poste et notre entreprise.\n
  Nous vous prions de bien vouloir nous faire part de vos disponibilités pour la semaine à venir afin que nous puissions convenir d'une date et d'une heure qui vous conviennent. \n
  Si toutefois vous rencontrez des contraintes particulières, n'hésitez pas à nous en informer afin que nous puissions trouver une solution adaptée.\n
  Dans l'attente de votre réponse et de notre rencontre, nous vous prions d'agréer, cher/Chère ${candidature.candidate.prenom}, l'expression de nos salutations distinguées.\n
  Cordialement.`
              return (
                <>
                  <tr key={index}>
                    <td>
                      {/* <div className="vehicle-thumbnail" style={{width:'100px',height:'100px'}}>
                      <img
                        src={
                          candidature.candidate.path?
                          `${IMAGES_BASE_URL}/public/uploads/members/${candidature.candidate.path}`
                          : `${IMAGES_BASE_URL}/public/uploads/members/default_avatar.jpg`
                        }
                        alt=""
                      />
                    </div> */}
                      <div
                      //className="profileAnnonceCardDetails"
                      >
                        <strong>
                          <div>
                            {candidature.candidate.prenom} {candidature.candidate.nom}
                          </div>
                        </strong>
                        <div>
                          <strong>Envoyé le :{" "}</strong>
                          <span><Moment format="DD/MM/YYYY">
                            {candidature.createdAt}
                          </Moment></span>
                        </div>
                      </div>
                    </td>
                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                      <a
                        href={`mailto:${candidature.candidate.email}?subject=${emailSubject}&body=${encodeURIComponent(emailBody)}`}
                        target="_blank" rel="noreferrer"
                      >
                        {candidature.candidate.email}
                      </a> <br /><br />
                      <a
                        //href="tel:+5656565"
                        href={`tel:${candidature.candidate.phoneNumber}`}
                      >
                        {candidature.candidate.phoneNumber}
                      </a>
                    </td>
                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                      {candidature.path ?

                        <a
                          href={`${IMAGES_BASE_URL}/public/uploads/candidatures/${candidature.path}`}
                          target="_blank" rel="noreferrer"
                        >{candidature.path}
                        </a>
                        : <a
                          href={`${IMAGES_BASE_URL}/public/uploads/members/${candidature.candidate.cv}`}
                          target="_blank" rel="noreferrer"
                        >
                          {candidature.candidate.cv}
                        </a>}

                    </td>
                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                      {candidature.message ? candidature.message.slice(0, 12) + '...' : ''}
                    </td>

                    <td>
                      <Link to={{
                        pathname: `/members/profile/detail-candidature/jobs/${candidature.id}`,
                        state: candidature,
                        company: company,
                        companyAdres: companyAdres
                      }}
                      >
                        <CButton>
                          <img src="assets/icons/eye.svg" /> <br />
                          voir plus
                        </CButton></Link>
                    </td>
                  </tr>

                  {/* <tr key={index}>
                  <td>
                    <div>
                      {candidature.candidate.prenom} {candidature.candidate.nom}{" "}
                    </div>
                    <div className="small text-muted">
                      Envoyé le :{" "}
                      <Moment format="DD/MM/YYYY">
                        {candidature.createdAt}
                      </Moment>
                    </div>
                  </td>

                  <td>
                    {candidature.path  ? 
                      <a
                        href={`${IMAGES_BASE_URL}/public/uploads/members/${candidature.path}`}
                        target="_blank" rel="noreferrer"
                      >
                        <img
                          src={"assets/icons/pdf_icon.svg"}
                          className="icon-brand"
                          alt=""
                        />
                      </a> : ''}
                  
                  </td>
                  <td><Link to={`/members/profile/detail-candidature/jobs/${candidature.id}`}>Voir détail</Link></td>
                </tr> */}
                </>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default Candidats;
