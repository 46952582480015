import React, { useEffect, useState, useContext } from "react";
import { API_BASE_URL, IMAGES_BASE_URL, keyHeader } from "../../../../config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Loading from "src/components/myWidgets/loadingWidget";
import Breadcrumb from "../../Breadcrumb";
import {
  CCol,
  CSelect,
  CFormGroup,
  CLabel,
  CButton,
  CSpinner,
  CNav,
  CTabContent,
  CTabPane,
  CTabs,
  CNavItem,
  CNavLink,
} from "@coreui/react";
import FavoriteContext from "../../FavoriteContext";

export default function SearchAnimal(props) {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [types, setTypes] = useState([]);
  const [type, setType] = useState(1);
  const [size, setSize] = useState("");
  const [sizes, setSizes] = useState([]);
  const [criteria, setCriteria] = useState("");
  const [criteres, setCriteres] = useState([]);
  const [typelabel, setTypelabel] = useState("Chien");
  const [isloaded, setIsloaded] = useState(false);

  const [breed, setBreed] = useState("");
  const [breeds, setBreeds] = useState([]);

  const [isLoadedALL, setisLoadedALL] = useState(false);
  const [all, setAll] = useState([]);
  const [errorAll, setErrorALL] = useState(false);
  const [results, setresults] = useState([]);
  const [retryCount, setRetryCount] = useState(0);

  const [banner, setBanner] = useState([]);
  const { banners } = useContext(
    FavoriteContext
  );
  useEffect(() => {
    setBanner(banners.find((item) => item.position === "animal"));
  }, [banners]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${API_BASE_URL}/animals/all`, keyHeader);
        setAll(result.data);
        setresults(result.data)
        setisLoadedALL(true);
        //console.log('res all ', retryCount ,result)
      } catch (error) {
        //console.log('errrrrr' ,  retryCount)
        if ((error.message === 'Network Error' || error.code === 'ECONNABORTED') && retryCount < 2) {
          setRetryCount((prevCount) => prevCount + 1);
        } else {
          setErrorALL(true);
          setRetryCount(9)
          //console.error('Failed to fetch data. Please check your internet connection.');
        }
      }
    };
    fetchData();
  }, [retryCount]);


  useEffect(() => {
    if (!localStorage.getItem("typeAnimals")) {
      axios(`${API_BASE_URL}/typeAnimals/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            //console.log("result555", result);
            setTypes(result);
            var typesF = [];

            typesF = result.find((category) => category.id.toString() === "1");
            setSizes(typesF.sizeAnimals);
            setBreeds(typesF.breedsAnimals);
            setCriteres(typesF.criteriaAnimals);

            //console.log(typesF);
            setIsloaded(true);
            localStorage.setItem("typeAnimals", JSON.stringify(result));
          },
          (error) => { }
        );
    } else {
      setTypes(JSON.parse(localStorage.getItem("typeAnimals")));
      var typesF = [];
      typesF = JSON.parse(localStorage.getItem("typeAnimals")).find((category) => category.id.toString() === "1");
      setSizes(typesF.sizeAnimals);
      setBreeds(typesF.breedsAnimals);
      setCriteres(typesF.criteriaAnimals);
      setIsloaded(true);
    }

    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            setCountries(result);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => { }
        );
    } else {
      setCountries(JSON.parse(localStorage.getItem("countries")));
    }
  }, []);



  useEffect(() => {

    if (isLoadedALL) {
      var results = all.filter(function (el) {

        const typec = !type || el.typeAnimal.id == type;
        //const sizec =  el.sizeAnimal && (!size || el.sizeAnimal.id == size);  
        const breedc = !breed || (breed && el.breedsAnimal && el.breedsAnimal.id && el.breedsAnimal.id == breed);
        const criteriac = !criteria || (criteria && el.criteriaAnimal && el.criteriaAnimal.id && el.criteriaAnimal.id == criteria);
        const sizec = !size || (size && el.sizeAnimal && el.sizeAnimal.id && el.sizeAnimal.id == size);

        //!size || el.sizeAnimal.id == size;  
        //const breedc = !breed || el.breed == breed;   
        //const criteriac = !criteria || el.criteriaAnimal.id == criteria; 
        const countryc = !country || el.country.id == country;
        const cityc = !city || el.city.id == city;

        return typec && sizec && countryc && cityc && breedc && criteriac;
      });

      //console.log('rsddsdxxx ',results)
      setresults(results);
    }

  }, [isLoadedALL, type, size, country, city, criteria, breed]);


  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    let path = ``;
    if (type) path += `type=${type}&`
    if (size) path += `size=${size}&`
    if (criteria) path += `criteria=${criteria}&`
    if (breed) path += `breed=${breed}&`
    if (country) path += `country=${country}&`
    if (city) path += `city=${city}&`

    //console.log(path.charAt(path.length - 1))
    if (path.charAt(path.length - 1) == '&') path = path.substring(0, path.length - 1)
    //console.log(path)

    history.push({
      pathname: `/animals/search-result`,
      search: `?${path}`,
      state: {
        result: results,
        all: { all: all, typelabel: typelabel, cities: cities }
      },
    });
  }

  const handleSubmitOld = (e) => {
    setSubmitted(true);
    e.preventDefault();
    if (type) {
      setLoading(true);
      const formData = new FormData();
      formData.append("type", type);
      formData.append("size", size);
      formData.append("criteria", criteria);
      formData.append("breed", breed);
      formData.append("country", country);
      formData.append("city", city);
      formData.append("delegate", delegate);
      formData.append("locality", locality);

      axios
        .post(API_BASE_URL + "/animals/search", formData)
        .then((res) => {
          //console.log("res.data", res);
          history.push({
            pathname: "/animals/search-result",
            state: { result: res.data },
          });
          setLoading(false);

          //console.log(props);
        })
        .catch((error) => {
          //console.log(" errors : ", error);
          setLoading(false);
        });
    }
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      //console.log("countriesF", countriesF);
    } else {
      setCity([]);
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      //console.log("citiesF", citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };
  const handleDelegateChange = (e) => {
    setDelegate(e.target.value);
    let id = e.target.value;
    var delegatesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      delegatesF = delegates.find((local) => local.id.toString() === id);
      setLocalities(delegatesF.localities);
      //console.log("delegatesF", delegatesF);
    } else {
      setLocality([]);
      //  setModel("");
    }
  };
  const handleTypeChange = (e) => {
    setType(e.target.value);

    let id = e.target.value;
    //console.log(id);
    var typesF = [];
    if (id !== "0") {
      typesF = types.find((category) => category.id.toString() === id);
      setSizes(typesF.sizeAnimals);
      setBreeds(typesF.breedsAnimals);
      setCriteres(typesF.criteriaAnimals);

      //console.log(typesF);
    } else {
      setSizes([]);
      setSize("");

      setBreeds([]);
      setBreed("");

      setCriteres([]);
      setCriteria("");
    }
  };

  const handleTypeChange2 = (i, label) => {
    setType(i);
    setTypelabel(label);

    let id = i;
    var typesF = [];
    //console.log(id);
    typesF = types.find((category) => category.id == id);
    setSizes(typesF.sizeAnimals);
    setBreeds(typesF.breedsAnimals);
    setCriteres(typesF.criteriaAnimals);
    setSize(''); setBreeds(''); setCriteria('');
    setCity(''); setCountry('');
    //console.log(typesF);
  };

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };
  const handleBreedsChange = (e) => {
    setBreed(e.target.value);
  };
  const handleCriteriaChange = (e) => {
    setCriteria(e.target.value);
  };

  return isloaded ? (
    <>
      <div className="vehicle-search">

        <div className="container">
          <Breadcrumb />
          <div className="row">
            <CCol
              md="6"
              className="justify-content-md-center container-vehicle"
            >
              <CTabs>
                <div className="row ">
                  <CCol xs="12" md="12">
                    <CNav variant="tabs" className="vehicule-tabs">
                      <CNavItem
                        className="vehicule-navItem animalIcon"
                        onClick={() => handleTypeChange2(1, "Chien")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/chien-blanc.svg"}
                            className="icon-vehicle2"
                            alt="Chien"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem animalIcon"
                        onClick={() => handleTypeChange2(2, "Chat")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/chat-blanc.svg"}
                            className="icon-vehicle2"
                            alt="Chat"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem animalIcon"
                        onClick={() => handleTypeChange2("3", "Rongeurs")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/hamster-blanc.svg"}
                            className="icon-vehicle2"
                            alt="Rongeurs"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem animalIcon"
                        onClick={() => handleTypeChange2("4", "Reptiles")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/reptile-blanc.svg"}
                            className="icon-vehicle2"
                            alt="Reptiles"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem animalIcon"
                        onClick={() => handleTypeChange2("5", "Oiseaux")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/oiseau-blanc.svg"}
                            className="icon-vehicle2"
                            alt="Oiseaux"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem
                        className="vehicule-navItem animalIcon"
                        onClick={() => handleTypeChange2("6", "Chevaux")}
                      >
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/chevaux-blanc.svg"}
                            className="icon-vehicle2"
                            alt="Chevaux"
                          />
                        </CNavLink>
                      </CNavItem>
                    </CNav>
                  </CCol>
                </div>

                <CTabContent fade={false} className="vehicule-tabContent filteringPage">
                  <div
                    style={{
                      display: "block",
                      padding: "20px",
                      borderRadius: "4px",
                    }}
                    className="searchFormWrapper"
                  >
                    <form onSubmit={handleSubmit}>
                      <span
                        style={{
                          display: "block",
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "black",
                          paddingBottom: "10px",
                        }}
                      >
                        {typelabel}
                      </span>

                      <CFormGroup row>
                        {/* <CCol xs="6" md="6">
                        <CLabel htmlFor="type">Type </CLabel>
                        <CSelect
                            name="type"
                            value={type}
                            onChange={(e) => handleTypeChange(e)}
                        >
                            <option value="0"> Choisir le type</option>
                            {types.map((type) => {
                                return (
                                    <option key={type.id} value={type.id}>
                                        {type.label}
                                    </option>
                                );
                            })}
                        </CSelect>


                        {submitted && !type && (
                            <span className="invalid-feedback error">
                                Veuillez choisir le type
                            </span>
                        )}
                    </CCol> */}
                        {sizes.length > 0 ? (
                          <CCol xs="6" md="6">
                            <CLabel htmlFor="size">Taille</CLabel>
                            <CSelect
                              name="size"
                              value={size}
                              onChange={(e) => handleSizeChange(e)}
                              disabled={isLoadedALL ? false : true}
                            >
                              <option value=""> Choisir la taille </option>
                              {sizes.map((size) => {
                                return (
                                  <option key={size.id} value={size.id}>
                                    {size.label}
                                  </option>
                                );
                              })}
                            </CSelect>
                          </CCol>
                        ) : null}
                        {criteres.length > 0 ? (
                          <CCol xs="6" md="6">
                            <CLabel htmlFor="criteria">Critères</CLabel>
                            <CSelect
                              name="criteria"
                              value={criteria}
                              onChange={(e) => handleCriteriaChange(e)}
                              disabled={isLoadedALL ? false : true}
                            >
                              <option value="0"> Choisir la Critère </option>
                              {criteres.map((criteria) => {
                                return (
                                  <option key={criteria.id} value={criteria.id}>
                                    {criteria.label}
                                  </option>
                                );
                              })}
                            </CSelect>
                          </CCol>
                        ) : null}

                        {breeds.length > 0 ? (
                          <CCol xs="6" md="6">
                            <CLabel htmlFor="breed">Races</CLabel>
                            <CSelect
                              name="breed"
                              value={breed}
                              onChange={(e) => handleBreedsChange(e)}
                              disabled={isLoadedALL ? false : true}
                            >
                              <option value="0"> Choisir la races </option>
                              {breeds.map((breed) => {
                                return (
                                  <option key={breed.id} value={breed.id}>
                                    {breed.label}
                                  </option>
                                );
                              })}
                            </CSelect>
                          </CCol>
                        ) : null}
                      </CFormGroup>

                      {/**  <CFormGroup row>


                    <CCol xs="6" md="6">
                        Prix <br />
                        {minPrice !== "" && (
                            <>
                                min <span className="min-max">{minPrice} TND</span>
                            </>
                        )}
                        {maxPrice !== "" && (
                            <>
                                Max <span className="min-max">{maxPrice} TND</span>{" "}
                            </>
                        )}
                        <Range
                            min={100}
                            max={300000}
                            step={100}
                            onAfterChange={(values) => {
                                setMinPrice(values[0]);
                                setMaxPrice(values[1]);
                            }}
                            defaultValue={[100, 300000]}
                        />
                    </CCol>

                </CFormGroup> */}

                      <CFormGroup row>
                        <CCol xs="6" md="6">
                          <CFormGroup>
                            <CLabel htmlFor="breed">Pays</CLabel>
                            <CSelect
                              name="country"
                              value={country}
                              onChange={(e) => handleCountryChange(e)}
                              disabled={isLoadedALL ? false : true}
                            >
                              <option value="0"> Choisissez un pays</option>
                              {countries.map((country) => {
                                return (
                                  <option key={country.id} value={country.id}>
                                    {country.label}
                                  </option>
                                );
                              })}
                            </CSelect>
                          </CFormGroup>
                        </CCol>
                        <CCol xs="6" md="6">
                          <CFormGroup>
                            <CLabel htmlFor="breed">Gouvernorat</CLabel>
                            <CSelect
                              name="city"
                              value={city}
                              onChange={(e) => handleCityChange(e)}
                              disabled={isLoadedALL ? false : true}
                            >
                              <option value="0"> Gouvernorat</option>
                              {cities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.label}
                                  </option>
                                );
                              })}
                            </CSelect>
                          </CFormGroup>
                        </CCol>
                      </CFormGroup>

                      {/* <CFormGroup row>
                        <CCol xs="6" md="6">
                          <CFormGroup>
                            Délégation
                            <CSelect
                              name="delegate"
                              value={delegate}
                              onChange={(e) => handleDelegateChange(e)}
                              disabled = {isLoadedALL? false : true} 
                            >
                              <option value="0">
                                {" "}
                                Choisissez une délégation
                              </option>
                              {delegates.map((delegate) => {
                                return (
                                  <option key={delegate.id} value={delegate.id}>
                                    {delegate.label}
                                  </option>
                                );
                              })}
                            </CSelect>
                          </CFormGroup>
                        </CCol>
                        <CCol xs="6" md="6">
                          <CFormGroup>
                            Localité
                            <CSelect
                              name="locality"
                              value={locality}
                              onChange={(e) => setLocality(e.target.value)}
                              disabled = {isLoadedALL? false : true} 
                            >
                              <option value="0"> Localité</option>
                              {localities.map((local) => {
                                return (
                                  <option key={local.id} value={local.id}>
                                    {local.label}
                                  </option>
                                );
                              })}
                            </CSelect>
                          </CFormGroup>
                        </CCol>
                      </CFormGroup> */}

                      <CFormGroup row>
                        <CCol xs="6" md="6"></CCol>
                        <CCol xs="6" md="6">
                          {!isLoadedALL && retryCount > 0 && retryCount < 4 ?
                            <CButton className=" btn-search" disabled>
                              <span>
                                {" "}
                                <CSpinner color="default" size="sm" />
                              </span> <span> Erreur réseau ! 😟 réessaye </span>
                            </CButton>
                            :
                            errorAll ?
                              <CButton className=" btn-search" disabled>
                                <span> Error Chercher ! 😟 <br />Vérifiez votre connection internet</span>
                              </CButton>
                              : !loading && isLoadedALL ? (
                                <CButton className=" btn-search" type="submit">
                                  <span> {results.length} résultats</span>
                                </CButton>
                              )
                                : !loading && isLoadedALL ? (
                                  <CButton className=" btn-search" type="submit">
                                    <span> {results.length} résultats</span>
                                  </CButton>
                                )
                                  : <CButton className=" btn-search" disabled>
                                    <span>
                                      {" "}
                                      <CSpinner color="default" size="sm" />
                                    </span>

                                    <span> Chercher </span>
                                  </CButton>
                          }
                        </CCol>
                      </CFormGroup>
                    </form>
                  </div>
                </CTabContent>
              </CTabs>
            </CCol>
            <CCol md="6">
              <div className="sideImgWrapper">
                {banner &&
                  <img
                    src={IMAGES_BASE_URL + '/public/uploads/banners/' + banner.path}
                    alt={banner.alt}
                  />
                }
              </div>
            </CCol>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Loading />
  );
}
