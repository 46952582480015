import React, { useState, useEffect, useContext } from "react";

import {
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import SearchCar from "./SearchCar";
import SearchTruck from "../trucks/SearchTruck";
import { SizeBike } from "../bikes/SizeBike";
import SearchBike from "../bikes/SearchBike";
import SearchMoto from "../motos/SearchMoto";
//import CIcon from "@coreui/icons-react";
import Breadcrumb from "../../Breadcrumb";
import FavoriteContext from "../../FavoriteContext";
import { IMAGES_BASE_URL } from "src/config";

function SearchVehicle() {
  const [showHideBike, setShowHideBike] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [banner, setBanner] = useState([]);
  const [bannerBike, setBannerBike] = useState([]);
  const { banners } = useContext(
    FavoriteContext
  );
  useEffect(() => {
    setBanner(banners.find((item) => item.position === "vehicle"));
    setBannerBike(banners.find((item) => item.position === "bike"));
  }, [banners]);

  const showBikeSize = () => {
    setShowHideBike(true);
  };
  const hideBikeSize = () => {
    setShowHideBike(false);
  }

  useEffect(() => { setActiveTab(1) }, []);

  return (
    <>
      <div className="vehicle-search vehicle-search2">
        <div className="container">
          <Breadcrumb />
          <div className="row">
            <CCol

              md="6"
              className="justify-content-md-center container-vehicle"
            >
              <CTabs >
                <div className="row ">
                  <CCol xs="12" md="12">
                    <CNav variant="tabs" className="vehicule-tabs">
                      <CNavItem className="vehicule-navItem" onClick={() => { hideBikeSize(); setActiveTab(1) }}>
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/icon_car.svg"}
                            className="icon-vehicle"
                            alt="car"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem className="vehicule-navItem" onClick={() => { hideBikeSize(); setActiveTab(2) }}>
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/icon_truck.svg"}
                            className="icon-vehicle"
                            alt="truck"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem className="vehicule-navItem" onClick={() => { showBikeSize(); setActiveTab(3) }}>
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/icon_bicycle.svg"}
                            className="icon-vehicle"
                            alt="bicyle"
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem className="vehicule-navItem" onClick={() => { hideBikeSize(); setActiveTab(4) }}>
                        <CNavLink className="vehicule-navLink">
                          <img
                            src={"assets/icons/icon_motorbike.svg"}
                            className="icon-vehicle"
                            alt="motorbike"
                          />
                        </CNavLink>
                      </CNavItem>

                    </CNav>
                  </CCol>
                </div>

                <CTabContent fade={false} className="vehicule-tabContent filteringPage">
                  <CTabPane className="searchFormWrapper">
                    {
                      // activeTab == 1 && 
                      <SearchCar />
                    }
                  </CTabPane>
                  <CTabPane className="searchFormWrapper">
                    {
                      // activeTab == 2 && 
                      <SearchTruck />
                    }
                  </CTabPane>
                  <CTabPane className="searchFormWrapper">
                    {
                      //activeTab == 3 && 
                      <SearchBike />
                    }
                  </CTabPane>
                  <CTabPane className="searchFormWrapper">
                    {
                      //activeTab == 4 &&                     
                      <SearchMoto />
                    }
                  </CTabPane>
                </CTabContent>
              </CTabs>
            </CCol>

            <CCol md="6">
              {showHideBike && (

                <SizeBike />

              )}

              {showHideBike && bannerBike ?
                <div className="sideImgWrapper2">
                  <img
                    src={IMAGES_BASE_URL + '/public/uploads/banners/' + bannerBike.path}
                    alt={bannerBike.alt}
                  />
                </div>
                : banner &&
                <div className="sideImgWrapper">
                  <img
                    style={{}}
                    src={IMAGES_BASE_URL + '/public/uploads/banners/' + banner.path}
                    alt={banner.alt}
                  />
                </div>
              }

            </CCol>
          </div> <br /> <br />
          <div className="row" >

            <CCol
              md="12"
              style={{ margin: 'auto', textAlign: 'justify' }}
              className="justify-content-md-center container-vehicle"
            >
              <h3 style={{ textAlign: 'center' }}>Bienvenue sur Tunisia Living Trafik : Votre Recherche de Véhicules en Toute Confiance !</h3>
            </CCol>
            <CCol
              md="8"
              style={{ margin: 'auto', textAlign: 'justify' }}
              className="justify-content-md-center container-vehicle"
            > <p>Découvrez "Tunisia Living Trafik", la plateforme tunisienne révolutionnaire pour trouver le véhicule parfait, qu’il s’agisse d’une voiture, d’une moto, d’un camion, ou même d’une bicyclette. Fraîchement lancée, notre plateforme est le fruit de 16 ans d’expertise dans divers domaines, combinant savoir-faire et innovation pour répondre aux besoins des Tunisiens, des résidents arabes et étrangers vivant en Tunisie.</p>
              <p>
                Que vous soyez un acheteur à la recherche d’un véhicule d’occasion ou neuf, Tunisia Living Trafik vous accompagne grâce à notre service exclusif d’expertise auto. Nos experts vous guident pour faire le meilleur choix, en évaluant la qualité, la fiabilité et la valeur de chaque véhicule. Finis les doutes : achetez en toute sérénité !
              </p>
              <p>
                Revendeurs professionnels, Tunisia Living Trafik est votre allié incontournable. Inscrivez-vous dès maintenant pour accéder à des outils et options avancés conçus spécialement pour vous. Boostez vos ventes, gérez vos annonces de voitures d’occasion, motos, ou vélos, et profitez d’une visibilité optimale auprès d’un public ciblé. Notre plateforme est évolutive et s’enrichira avec le temps de nouvelles fonctionnalités pour répondre à vos ambitions.
              </p>
              <p>
                Pensée pour la Tunisie et ses habitants, Tunisia Living Trafik est bien plus qu’un simple site de recherche de véhicules : c’est une solution complète pour une expérience d’achat et de vente simplifiée, sécurisée et adaptée à tous les types de véhicules. Que vous soyez à Tunis, Sousse, Sfax ou ailleurs, trouvez ou vendez votre prochain moyen de transport avec nous.
              </p>
            </CCol>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchVehicle;
