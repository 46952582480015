import React, { useEffect, useState } from "react";
import { API_BASE_URL, keyHeader } from "../../../../config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Loading from "src/components/myWidgets/loadingWidget";
import {
  CCol,
  CSelect,
  CFormGroup,
  CLabel,
  CButton,
  CSpinner,
} from "@coreui/react";

export default function SearchService(props) {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [delegate, setDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [locality, setLocality] = useState("");
  const [localities, setLocalities] = useState([]);
  const [types, setTypes] = useState([]);
  const [type, setType] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [isLoaded1, setIsLoaded1] = useState(false);
  const [isLoaded2, setIsLoaded2] = useState(false);
  const [isLoadedALL, setisLoadedALL] = useState(false);
  const [all, setAll] = useState([]);
  const [errorAll, setErrorALL] = useState(false);
  const [results, setresults] = useState([]);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${API_BASE_URL}/service/all`, keyHeader);
        setAll(result.data);
        setresults(result.data)
        setisLoadedALL(true);
        //console.log('res all ', retryCount ,result)
      } catch (error) {
        //console.log('errrrrr' ,  retryCount)
        if ((error.message === 'Network Error' || error.code === 'ECONNABORTED') && retryCount < 2) {
          setRetryCount((prevCount) => prevCount + 1);
        } else {
          setErrorALL(true);
          setRetryCount(9)
          // console.error('Failed to fetch data. Please check your internet connection.');
        }
      }
    };

    fetchData();

  }, [retryCount]);


  useEffect(() => {

    if (!localStorage.getItem("typeServices")) {
      axios(`${API_BASE_URL}/typeServices/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            setTypes(result);
            setIsLoaded1(true);
            localStorage.setItem("typeServices", JSON.stringify(result));
          },
          (error) => { }
        );
    } else {
      setTypes(JSON.parse(localStorage.getItem("typeServices")));
      setIsLoaded1(true);
    }

    if (!localStorage.getItem("countries")) {
      axios(`${API_BASE_URL}/countries/all`)
        .then((res) => res.data)
        .then(
          (result) => {
            setCountries(result);
            setIsLoaded2(true);
            localStorage.setItem("countries", JSON.stringify(result));
          },
          (error) => { }
        );
    } else {
      setIsLoaded2(true);
      setCountries(JSON.parse(localStorage.getItem("countries")));
    }
  }, []);


  useEffect(() => {

    if (isLoadedALL) {
      var results = all.filter(function (el) {


        const typec = !type || (type && el.typeService && el.typeService.id && el.typeService.id == type);

        const categoryc = !category || (category && el.categoryService && el.categoryService.id && el.categoryService.id == category);

        const countryc = !country || el.country.id == country;
        const cityc = !city || el.city.id == city;

        return typec && categoryc && countryc && cityc;
      });

      //console.log('rsddsdxxx ',results)
      setresults(results);
    }

  }, [isLoadedALL, type, category, country, city,]);


  let history = useHistory();


  const handleSubmit = (e) => {
    e.preventDefault();
    let path = ``;
    if (type) path += `type=${type}&`
    if (category) path += `category=${category}&`
    if (country) path += `country=${country}&`
    if (city) path += `city=${city}&`

    //console.log(path.charAt(path.length - 1))
    if (path.charAt(path.length - 1) == '&') path = path.substring(0, path.length - 1)
    //console.log(path)

    history.push({
      pathname: `/services/search-result`,
      search: `?${path}`,
      state: {
        result: results,
        all: { all: all, cities: cities }
      },
    });
  }

  const handleSubmitOld = (e) => {
    setSubmitted(true);
    e.preventDefault();
    if (type) {
      setLoading(true);
      const formData = new FormData();
      formData.append("type", type);
      formData.append("category", category);
      formData.append("country", country);
      formData.append("city", city);
      formData.append("delegate", delegate);
      formData.append("locality", locality);

      axios
        .post(API_BASE_URL + "/services/search", formData)
        .then((res) => {
          //console.log("res.data", res);
          history.push({
            pathname: "/services/search-result",
            state: { result: res.data },
          });
          setLoading(false);

          //console.log(props);
        })
        .catch((error) => {
          //console.log(" errors : ", error);
          setLoading(false);
        });
    }
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let id = e.target.value;
    var countriesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      countriesF = countries.find((city) => city.id.toString() === id);
      setCities(countriesF.cities);
      //console.log("countriesF", countriesF);
    } else {
      setCity([]);
      //  setModel("");
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    let id = e.target.value;
    var citiesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      citiesF = cities.find((delegate) => delegate.id.toString() === id);
      setDelegates(citiesF.delegations);
      //console.log("citiesF", citiesF);
    } else {
      setDelegate([]);
      //  setModel("");
    }
  };
  const handleDelegateChange = (e) => {
    setDelegate(e.target.value);
    let id = e.target.value;
    var delegatesF = [];
    //console.log("iddd", id);
    if (id !== "0") {
      delegatesF = delegates.find((local) => local.id.toString() === id);
      setLocalities(delegatesF.localities);
      //console.log("delegatesF", delegatesF);
    } else {
      setLocality([]);
      //  setModel("");
    }
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);

    let id = e.target.value;
    var typesF = [];
    if (id !== "0") {
      typesF = types.find((category) => category.id.toString() === id);
      setCategories(typesF.categoryServices);
      setCategory("");
    } else {
      setCategories([]);
      setCategory("");
    }
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  if (isLoaded1 && isLoaded2) {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <CFormGroup row>
            <CCol xs="6" md="6">
              {/* <CLabel htmlFor="type">Type </CLabel> */}
              <CSelect
                name="type"
                value={type}
                onChange={(e) => handleTypeChange(e)}
                disabled={isLoadedALL ? false : true}
              >
                <option value="0"> Choisir le type</option>
                {types.map((type) => {
                  return (
                    <option key={type.id} value={type.id}>
                      {type.label}
                    </option>
                  );
                })}
              </CSelect>

              {submitted && !type && (
                <span className="invalid-feedback error">
                  Veuillez choisir le type
                </span>
              )}
            </CCol>

            <CCol xs="6" md="6">
              {/* <CLabel htmlFor="category">Categorie</CLabel> */}
              <CSelect
                name="category"
                value={category}
                onChange={(e) => handleCategoryChange(e)}
                disabled={isLoadedALL ? false : true}
              >
                <option value="0"> Choisir la categorie </option>
                {categories &&
                  categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.label}
                      </option>
                    );
                  })}
              </CSelect>
            </CCol>
          </CFormGroup>

          {/**  <CFormGroup row>


                    <CCol xs="6" md="6">
                        Prix <br />
                        {minPrice !== "" && (
                            <>
                                min <span className="min-max">{minPrice} TND</span>
                            </>
                        )}
                        {maxPrice !== "" && (
                            <>
                                Max <span className="min-max">{maxPrice} TND</span>{" "}
                            </>
                        )}
                        <Range
                            min={100}
                            max={300000}
                            step={100}
                            onAfterChange={(values) => {
                                setMinPrice(values[0]);
                                setMaxPrice(values[1]);
                            }}
                            defaultValue={[100, 300000]}
                        />
                    </CCol>

                </CFormGroup> */}

          <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup>
                <CSelect
                  name="country"
                  value={country}
                  onChange={(e) => handleCountryChange(e)}
                  disabled={isLoadedALL ? false : true}
                >
                  <option value="0"> Choisissez un pays</option>
                  {countries.map((country) => {
                    return (
                      <option key={country.id} value={country.id}>
                        {country.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6">
              <CFormGroup>
                <CSelect
                  name="city"
                  value={city}
                  onChange={(e) => handleCityChange(e)}
                  disabled={isLoadedALL ? false : true}
                >
                  <option value="0"> Gouvernorat</option>
                  {cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CFormGroup>

          {/* <CFormGroup row>
            <CCol xs="6" md="6">
              <CFormGroup>
                Délégation
                <CSelect
                  name="delegate"
                  value={delegate}
                  onChange={(e) => handleDelegateChange(e)}
                >
                  <option value="0"> Choisissez une délégation</option>
                  {delegates.map((delegate) => {
                    return (
                      <option key={delegate.id} value={delegate.id}>
                        {delegate.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol xs="6" md="6">
              <CFormGroup>
                Localité
                <CSelect
                  name="locality"
                  value={locality}
                  onChange={(e) => setLocality(e.target.value)}
                >
                  <option value="0"> Localité</option>
                  {localities.map((local) => {
                    return (
                      <option key={local.id} value={local.id}>
                        {local.label}
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CFormGroup> */}

          <CFormGroup row>
            <CCol xs="6" md="6"></CCol>
            <CCol xs="6" md="6">
              {!isLoadedALL && retryCount > 0 && retryCount < 4 ?
                <CButton className=" btn-search" disabled>
                  <span>
                    {" "}
                    <CSpinner color="default" size="sm" />
                  </span> <span> Erreur réseau ! 😟 réessaye </span>
                </CButton>
                :
                errorAll ?
                  <CButton className=" btn-search" disabled>
                    <span> Error Chercher ! 😟 <br />Vérifiez votre connection internet</span>
                  </CButton>
                  : !loading && isLoadedALL ? (
                    <CButton className=" btn-search" type="submit">
                      <span> {results.length} résultats</span>
                    </CButton>
                  )
                    : !loading && isLoadedALL ? (
                      <CButton className=" btn-search" type="submit">
                        <span> {results.length} résultats</span>
                      </CButton>
                    )
                      : <CButton className=" btn-search" disabled>
                        <span>
                          {" "}
                          <CSpinner color="default" size="sm" />
                        </span>

                        <span> Chercher </span>
                      </CButton>
              }
            </CCol>
          </CFormGroup>
        </form>
      </>
    );
  } else
    return (
      <div className="container">
        <main className="l-main lmain2">
          <div className="l-section l-section--archive">
            <div className="loading_logo">
              <img
                src={"assets/icons/tunisia-living-loading-icon.gif"}
                alt="tunisia living loading icon"
              />
              <p>Chargement...</p>
            </div>
          </div>
        </main>
      </div>
    );
}
