import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
//import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";
//import { connect } from "react-redux";
//import * as actions from "../../reducers/vehicle/vehicleActions";
import {
  CButton, CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import FavoriteContext from "../../FavoriteContext";
import { isLogin } from "src/components/middleware/auth";
import Moment from "react-moment";

const AnimalItem = (props) => {
  const [isFav, setIsFav] = useState(null);
  const [isBeating, setBeating] = useState(false);
  const [modal, setModal] = useState(false);
  const [iconSrc, setIconSrc] = useState("");

  const { favorites, updateFavorites, checkFavorite } = useContext(
    FavoriteContext
  );
  const setFavourite = async () => {
    if (!isLogin()) setModal(true);
    else {
      setBeating(true);
      try {
        const success = await updateFavorites(props.id, props.table);

        if (success) {
          setIsFav(true);
          setBeating(false);
        } else {
          setIsFav(false);
          setBeating(false);
        }
      } catch (error) {
        // console.error(error);
        setBeating(false);
      }
    }
  };

  useEffect(() => {
    if (favorites) {
      setIsFav(checkFavorite(props.id, props.table));
    }
  }, [favorites]);


  useEffect(() => {
    let tempi = props.item.typeAnimal.id;
    switch (tempi) {
      case 1:
        setIconSrc("assets/icons/chien-blanc.svg");
        break;
      case 2:
        setIconSrc("assets/icons/chat-blanc.svg");
        break;
      case 3:
        setIconSrc("assets/icons/hamster-blanc.svg");
        break;
      case 4:
        setIconSrc("assets/icons/reptile-blanc.svg");
      case 5:
        setIconSrc("assets/icons/oiseau-blanc.svg");
      case 6:
        setIconSrc("assets/icons/chevaux-blanc.svg");
      default:
        break;
    }

  }, [])

  return (
    <>
      <div className="vehicle-item resultSearchItem">
        <div className="occasion-item">
          <div className="details-wrapper">
            <Link
              to={{
                pathname: props.url,
                state: props.item,
              }}
              className="price">
              <div className="thumbWraperResultItem">
                <img
                  className="thumb cdn-image lazyloaded"
                  src={props.image}
                  alt={props.type}
                />
              </div>
            </Link>
            <div className="thumb-caption detail_result_head">
              <Link
                // to={props.url}
                to={{
                  pathname: props.url,
                  state: props.item,
                }}
                className="price">
                <h2>
                  <span>{props.item.title ? props.item.title + ' - ' : ''}{props.type}</span>
                </h2>{" "}
              </Link>
              <CButton
                onClick={
                  isBeating ? () => { }
                    : () => {
                      setFavourite();
                    }
                }
                className={`favorite favIconClass ${isBeating ? 'beating' : ''}`}
              >
                {isFav == true ? <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="var(--ci-primary-color, currentColor)"
                    d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
                  />
                </svg>
                  : <svg

                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="var(--ci-primary-color, currentColor)"
                      d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"
                    />
                  </svg>}
              </CButton>

              {props.price ? (
                <div className="price price_div">
                  {props.price} <span className="suffix">TND</span>{" "}
                </div>
              ) : null}
            </div>
          </div>

          <Link
            // to={props.url}
            to={{
              pathname: props.url,
              state: props.item,
            }}
            className="price">
            <div className="specs-preview twoColsList">
              <ul>
                {props.size ? (
                  <li>
                    <span className="name filterWhiteIcon">
                      <img
                        src={iconSrc}
                        alt=""
                        className="icon-option-vehicle"
                      />
                    </span>
                    <span className="value">{props.size} </span>
                  </li>
                ) : null}

                {props.criteria ? (
                  <li>
                    <span className="name filterWhiteIcon">
                      <img
                        src={iconSrc}
                        alt=""
                        className="icon-option-vehicle"
                      />
                    </span>
                    <span className="value">{props.criteria} </span>
                  </li>
                ) : null}
                {props.breed ? (
                  <li>
                    <span className="name filterWhiteIcon">
                      <img
                        src={iconSrc}
                        alt=""
                        className="icon-option-vehicle"
                      />
                    </span>
                    <span className="value">{props.breed} </span>
                  </li>
                ) : null}

                <li>
                  <span className="name">
                    <img
                      src={"assets/icons/icon_map.svg"}
                      alt=""
                      className="icon-option-vehicle"
                    />
                  </span>
                  <span className="value">{props.delegate}</span>
                  &nbsp; - <span className="value">{props.locality}</span>
                </li>
                {props.at &&
                  <li>
                    <span className="name">
                      <svg className="itemResultSvg" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24"><path d="M12,19a1,1,0,1,0-1-1A1,1,0,0,0,12,19Zm5,0a1,1,0,1,0-1-1A1,1,0,0,0,17,19Zm0-4a1,1,0,1,0-1-1A1,1,0,0,0,17,15Zm-5,0a1,1,0,1,0-1-1A1,1,0,0,0,12,15ZM19,3H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,6,2V3H5A3,3,0,0,0,2,6V20a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V6A3,3,0,0,0,19,3Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H20ZM20,9H4V6A1,1,0,0,1,5,5H6V6A1,1,0,0,0,8,6V5h8V6a1,1,0,0,0,2,0V5h1a1,1,0,0,1,1,1ZM7,15a1,1,0,1,0-1-1A1,1,0,0,0,7,15Zm0,4a1,1,0,1,0-1-1A1,1,0,0,0,7,19Z" /></svg>
                    </span>
                    <span className="value">
                      <Moment format="DD-MM-YYYY">{props.at}</Moment>
                    </span>
                  </li>}
              </ul>
            </div>
          </Link>
        </div>
      </div>
      <CModal show={modal} onClose={() => setModal(!modal)}>
        <CModalHeader closeButton></CModalHeader>
        <CModalBody>Vous devez vous connecter d'abord</CModalBody>
        <CModalFooter>
          <Link className="btn-primary btn " to="/members/login">
            Login
          </Link>
          <CButton color="secondary" onClick={() => setModal(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};
// function mapStateToProps(state) {
//   console.log("article item : ", state.animals);
//   return {
//     favorites: state.animals,
//   };
// }
export default AnimalItem;
