import React, { Component } from "react";
import { API_BASE_URL, formattedExpiration } from "../../../config";
import axios from "axios";
import { temp_sub_url } from "../../../config";
import HtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { keyHeader, isSafari, isTunisliveDomain } from "../../../config";
import { isLogin } from "src/components/middleware/auth";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInputGroup,
  CRow,
  CSpinner
} from "@coreui/react";

export class LoginMember extends Component {
  constructor(props) {
    super(props);

    this.state = {
      captchaToken: '',
      isloading: false,
      email: "",
      password: "",
      loginErrors: "",
      loginErrorsA: "",
      showPass: false,
      trys: 0,
    };
    // console.log(localStorage.getItem('prevUrl'));
  }

  componentDidMount() {
    // If the user is authenticated, navigate to the home page
    //console.log('isllllll', isLogin())
    if (isLogin()) {
      this.props.history.push('/'); // navigate from props
    }
  }

  viewPassword = () => {
    let show = !this.state.showPass;
    this.setState({
      showPass: show,
    });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  // componentDidMount() {
  //   window.grecaptcha.ready(() => {
  //     window.grecaptcha.execute('6Ld0TcgpAAAAANxm4lF75_QlEAPOV5kbbQYXa-EY', { action: 'submit' }).then(token => {
  //       this.setState({ captchaToken: token }); // Set captchaToken in state
  //     });
  //   });
  // }



  // componentDidMount() {
  //   const script = document.createElement('script');
  //   script.src = "https://www.google.com/recaptcha/api.js?render=6Ld0TcgpAAAAANxm4lF75_QlEAPOV5kbbQYXa-EY";
  //   script.async = true;

  //   script.onload = () => {
  //     // Execute reCAPTCHA
  //     window.grecaptcha.ready(() => {
  //       window.grecaptcha.execute('6Ld0TcgpAAAAANxm4lF75_QlEAPOV5kbbQYXa-EY', { action: 'submit' }).then(token => {
  //         this.setState({ captchaToken: token }); // Set captchaToken in state
  //       });
  //     });
  //   };

  //   document.body.appendChild(script);
  //   this.script = script;
  // }

  // componentWillUnmount() {
  //   console.log('Unmounting Login component');
  //   if (this.script) {
  //     document.body.removeChild(this.script);
  //   }
  // }


  handleSubmit2 = (event) => {
    this.setState({ loginErrors: "", loginErrorsA: "" });
    if (this.state.password.length < 4 || this.state.email.length < 2) {
      this.setState({
        loginErrors:
          "Veuillez saisir votre email ou mot de passe correctement!",
        loginErrorsA: ' الرجاء إدخال عنوان البريد الإلكتروني و كلمة المرور بشكل صحيح '
      });
    } else {
      this.setState({
        isloading: true,
      });
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6Ld0TcgpAAAAANxm4lF75_QlEAPOV5kbbQYXa-EY', { action: 'submit' }).then(token => {
          if (token) {
            //console.log('token' , token)
            this.setState({
              isloading: true,
            });

            const formData = new FormData();
            formData.append("email", this.state.email);
            formData.append("password", this.state.password);
            formData.append('captchaToken', token);

            axios
              .post(API_BASE_URL + "/members/login", formData, keyHeader)
              .then((res) => {
                // console.log("aaaaaaaaaa");
                // console.log(res);
                //console.log(res.data);

                if (res.data.status == "ok") {
                  if (res.data.active == 1) {
                    localStorage.setItem("auth", res.data);
                    // localStorage.setItem("email", res.data.user.email);
                    localStorage.setItem("roles", res.data.user.roles);
                    // localStorage.setItem("id", res.data.user.id);
                    localStorage.setItem("avatar", res.data.user.path);
                    localStorage.setItem("typeCompte", res.data.user.accountType.id);
                    localStorage.setItem("name", res.data.user.name);
                    localStorage.setItem("prenom", res.data.user.prenom);

                    document.cookie = `token=${res.data.token}; expires=${formattedExpiration}; path=/; secure; SameSite=Strict`;


                    this.setState({
                      isloading: false,
                    });
                    window.location.replace("/members/profile");
                    // // this.props.history.push("/members/profile");
                    // if (localStorage.getItem("prevUrl") != null) {
                    //   //window.location.href = localStorage.getItem("prevUrl");
                    //   //this.props.history.push(localStorage.getItem("prevUrl"));
                    //   window.location.replace(localStorage.getItem("prevUrl"));
                    // } else 
                    // //window.location.href = temp_sub_url + "/members/profile";
                    // window.location.replace("/members/profile");
                    // //this.props.history.push("/members/profile");
                  } else if (res.data.active == 0) {
                    this.setState({
                      isloading: false,
                      loginErrors:
                        "Ce compte n'est pas encore confirmé. Veuillez vérifier votre email pour le message contenant le lien de confirmation. Si vous n'avez pas reçu le message de confirmation, veuillez contacter le support sur <a href='mailto:support@tunisialiving.tn'>support@tunisialiving.tn </a>",
                      loginErrorsA:
                        "  لم يتم تفعيل هذا الحساب بعد ، الرجاء تفعيل حسابك من خلال الرابط الذي أرسلناه إلى بريدك الإلكتروني ، إذا  لم تصلك رسالة من طرفنا تتضمن رابط التفعيل الرجاء التواصل مع فريق الدعم على العنوان التالي <a href='mailto:support@tunisialiving.tn'>support@tunisialiving.tn </a>",
                      trys: this.state.trys + 1
                    });

                  } else if (res.data.active == 2) {
                    this.setState({
                      isloading: false,
                      loginErrors:
                        " Votre compte a été désactivé, cela arrive généralement suite à un abus,  pour plus d'informations vous pouvez contacter notre équipe via cet email <a href='mailto:support@tunisialiving.tn'>support@tunisialiving.tn </a> ",
                      loginErrorsA:
                        "لقد تم إيقاف حسابك ، عادةٍ ما يحدث هذا بسبب إساءة الاستخدام ، لمزيد من المعلومات يمكنك  التواصل مع فريق الدعم على العنوان التالي <br/> <a href='mailto:support@tunisialiving.tn'>support@tunisialiving.tn </a>",
                      trys: this.state.trys + 1
                    });
                  }
                } else if (res.data.status == "captcha-failed") {
                  this.setState({
                    isloading: false,
                    loginErrors: "Google Recaptcha n'a pas pu vérifier que vous n'êtes pas un robot, veuillez réessayer.",
                    loginErrorsA: " تعذر التحقق من كونك لست روبوت، الرجاء المحاولة مرة اخرى   ",
                    trys: this.state.trys + 1
                  });
                }

                else {
                  this.setState({
                    isloading: false,
                    loginErrors: "E-mail ou mot de passe incorrecte.",
                    loginErrorsA: " عنوان الإيميل أو كلمة المرور غير صحيحين  ",
                    trys: this.state.trys + 1
                  });
                }
              })
              .catch((error) => {
                // console.log("login errors : ", error);
                if (error.response && error.response.status === 401) {
                  this.setState({
                    isloading: false,
                    loginErrors: "E-mail ou mot de passe incorrecte.",
                    loginErrorsA: " عنوان الإيميل أو كلمة المرور غير صحيحين  ",
                    trys: this.state.trys + 1
                  });

                } else
                  this.setState({
                    isloading: false,
                    loginErrors: "Une erreur s'est produite, veuillez réessayer",
                    loginErrorsA: " حدث خطأ ما ، الرجاء المحاولة ثانية "
                  });
              });
          } //else console.log('not valid token')
        });
      });


    }
    event.preventDefault();
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ loginErrors: "", loginErrorsA: "" });

    // Form validation
    if (this.state.password.length < 4 || this.state.email.length < 2) {
      this.setState({
        loginErrors: "Veuillez saisir votre email ou mot de passe correctement!",
        loginErrorsA: "الرجاء إدخال عنوان البريد الإلكتروني و كلمة المرور بشكل صحيح",
      });
      return;
    }

    this.setState({ isloading: true });

    try {
      // Captcha execution
      const token = await window.grecaptcha.execute(
        '6Ld0TcgpAAAAANxm4lF75_QlEAPOV5kbbQYXa-EY',
        { action: 'submit' }
      );

      if (!token) {
        // console.log('Captcha token not valid');
        return;
      }

      const formData = {
        email: this.state.email,
        password: this.state.password,
        captchaToken: token,
      };

      // Making the request
      const res = await axios.post(`${API_BASE_URL}/members/login`, formData, { withCredentials: true });
      // console.log(res)
      if (res.data.status === "ok") {
        if (res.data.active == 1) {
          localStorage.setItem("auth", res.data);
          localStorage.setItem("roles", res.data.user.roles);
          localStorage.setItem("avatar", res.data.user.profile);
          localStorage.setItem("typeCompte", res.data.user.accountType);
          localStorage.setItem("name", res.data.user.firstName);
          localStorage.setItem("prenom", res.data.user.lastName);

          if (0) if (isSafari() && isTunisliveDomain() && res.data.token) {
            //console.log('set jwtttttt')
            const expires = new Date();
            expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000); // 24 hours
            const jwtToken = res.data.token;
            document.cookie = `token=${jwtToken};expires=${expires.toUTCString()};path=/;domain=tunisialiving.tn;Secure`;

            // Set the JWT token as a normal cookie for 24 hours
            //document.cookie = `token=${jwtToken};expires=${expires.toUTCString()};path=/;secure`;

          } //else console.log('noooo jwtttttt')

          this.setState({ isloading: false });
          // setTimeout(() => { window.location.replace("/members/profile"); }, 1000);
          window.location.replace("/members/profile");
        } else if (res.data.active == 0) {
          this.setState({
            isloading: false,
            loginErrors:
              "Ce compte n'est pas encore confirmé. Veuillez vérifier votre email pour le message contenant le lien de confirmation. Si vous n'avez pas reçu le message de confirmation, veuillez contacter le support sur <a href='mailto:support@tunisialiving.tn'>support@tunisialiving.tn </a>",
            loginErrorsA:
              "لم يتم تفعيل هذا الحساب بعد ، الرجاء تفعيل حسابك من خلال الرابط الذي أرسلناه إلى بريدك الإلكتروني ، إذا لم تصلك رسالة من طرفنا تتضمن رابط التفعيل الرجاء التواصل مع فريق الدعم على العنوان التالي <a href='mailto:support@tunisialiving.tn'>support@tunisialiving.tn </a>",
          });
        } else if (res.data.active == 2) {
          this.setState({
            isloading: false,
            loginErrors:
              "Votre compte a été désactivé, cela arrive généralement suite à un abus,  pour plus d'informations vous pouvez contacter notre équipe via cet email <a href='mailto:support@tunisialiving.tn'>support@tunisialiving.tn </a>",
            loginErrorsA:
              "لقد تم إيقاف حسابك ، عادةٍ ما يحدث هذا بسبب إساءة الاستخدام ، لمزيد من المعلومات يمكنك التواصل مع فريق الدعم على العنوان التالي <br/> <a href='mailto:support@tunisialiving.tn'>support@tunisialiving.tn </a>",
          });
        }
      } else if (res.data.status === "captcha-failed") {
        this.setState({
          isloading: false,
          loginErrors:
            "Google Recaptcha n'a pas pu vérifier que vous n'êtes pas un robot, veuillez réessayer.",
          loginErrorsA: "تعذر التحقق من كونك لست روبوت، الرجاء المحاولة مرة اخرى",
        });
      } else {
        this.setState({
          isloading: false,
          loginErrors: "E-mail ou mot de passe incorrecte.",
          loginErrorsA: "عنوان الإيميل أو كلمة المرور غير صحيحين",
        });
      }
    } catch (error) {
      //console.log("Login error:", error);

      if (error.response && error.response.status === 401) {
        this.setState({
          isloading: false,
          loginErrors: "E-mail ou mot de passe incorrecte.",
          loginErrorsA: "عنوان الإيميل أو كلمة المرور غير صحيحين",
        });
      } else {
        this.setState({
          isloading: false,
          loginErrors: "Une erreur s'est produite, veuillez réessayer",
          loginErrorsA: "حدث خطأ ما، الرجاء المحاولة ثانية",
        });
      }
    }
  };


  render() {
    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer className='loginPageClass'>
          <CRow className="justify-content-center">
            <CCol md="6">
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <form onSubmit={this.handleSubmit}>
                      <h1>Connexion</h1>
                      <p>Connectez vous à votre compte</p>
                      <CInputGroup className="mb-3">
                        <input
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          autoComplete="on"
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <input
                          type={this.state.showPass ? "text" : "password"}
                          placeholder="mot de passe"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                        />
                      </CInputGroup>
                      <CCol className="showPassRow">
                        <label htmlFor="showpassword">
                          <input
                            name="showpassword"
                            type="checkbox"
                            onChange={this.viewPassword}
                          />
                          Afficher le mot de passe
                        </label>
                      </CCol>
                      {this.state.loginErrors.length > 0 && (
                        <CCol>
                          <p
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {HtmlParser(this.state.loginErrors)}
                          </p>
                          <p
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {HtmlParser(this.state.loginErrorsA)}
                          </p>
                        </CCol>
                      )}

                      <CRow>
                        <CCol xs="6" className="logInButton">
                          {!this.state.isloading && (
                            <CButton color="primary" className="px-4" type="submit"
                              disabled={this.state.trys > 3}
                            >
                              Login
                            </CButton>
                          )}

                          {this.state.isloading && (
                            <CButton color="primary" className="px-4" disabled>
                              <span>
                                {" "}
                                <CSpinner color="default" size="sm" />
                              </span>

                              <span> Login</span>
                            </CButton>
                          )}
                        </CCol>

                        <CCol xs="6" className="text-right">
                          <a
                            href={"/members/forget"}
                            style={{ color: "blue", cursor: "pointer" }}
                          >
                            mot de passe oublié?
                          </a>
                        </CCol>
                      </CRow>
                    </form>
                    <br />
                    <div className="registerNow">Nouveau sur TL ?    <Link
                      to="/members/register"
                    >
                      <span> S'inscrire Maintenant</span>
                    </Link></div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
}

export default LoginMember;
