
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { tokenCookie } from "src/config";

import FavoriteContext from "./FavoriteContext";
import { isLogin } from "../middleware/auth";
import {
  CButton, CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";

export default function ItemCard(props) {
  const show = { display: "inline-block" };
  const hide = { display: "none" };

  const [isFav, setIsFav] = useState(null);
  const [isBeating, setBeating] = useState(false);
  const [modal, setModal] = useState(false);
  const { favorites, updateFavorites, checkFavorite } = useContext(
    FavoriteContext
  );
  const setFavourite = async () => {
    //console.log('token in set ', tokenCookie , isLogin())

    if (!isLogin()) setModal(true);
    else {
      setBeating(true);
      try {
        const success = await updateFavorites(props.id, props.table);
        // console.error('successxxxxxvvvv ',success);
        if (success && success == 'log') {
          setModal(true);
        }
        if (success && success != 'log') {
          setIsFav(true);
          setBeating(false);
        } else {
          setIsFav(false);
          setBeating(false);
        }
      } catch (error) {
        // console.error(error);
        setBeating(false);
      }
    }
  };

  useEffect(() => {

    if (favorites) {
      setIsFav(checkFavorite(props.id, props.table));
    }
  }, [favorites]);


  return (
    <>
      <div className="vehicle-item">
        <CButton
          onClick={
            isBeating ? () => { }
              : () => {
                setFavourite();
              }
          }
          className={`favorite favIconClass ${isBeating ? 'beating' : ''}`}
        //disabled={isFav == null? true : false}
        >
          {
            !isLogin() ?
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false"
                style={{ display: "block", fill: "#0000005e", stroke: "white", strokeWidth: "2", overflow: "visible" }}>
                <path d="M16 28c7-4.73 14-10 14-17a6.98 6.98 0 0 0-7-7c-1.8 0-3.58.68-4.95 2.05L16 8.1l-2.05-2.05a6.98 6.98 0 0 0-9.9 0A6.98 6.98 0 0 0 2 11c0 7 7 12.27 14 17z"></path></svg>
              : isLogin() && isFav == null ? ''
                : isFav == false ?
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false"
                    style={{ display: "block", fill: "#0000005e", stroke: "white", strokeWidth: "2", overflow: "visible" }}>
                    <path d="M16 28c7-4.73 14-10 14-17a6.98 6.98 0 0 0-7-7c-1.8 0-3.58.68-4.95 2.05L16 8.1l-2.05-2.05a6.98 6.98 0 0 0-9.9 0A6.98 6.98 0 0 0 2 11c0 7 7 12.27 14 17z"></path></svg>

                  : isFav == true ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false"
                      style={{ display: "block", fill: "#ff287d", stroke: "white", strokeWidth: "2", overflow: "visible" }}>
                      <path d="M16 28c7-4.73 14-10 14-17a6.98 6.98 0 0 0-7-7c-1.8 0-3.58.68-4.95 2.05L16 8.1l-2.05-2.05a6.98 6.98 0 0 0-9.9 0A6.98 6.98 0 0 0 2 11c0 7 7 12.27 14 17z"></path></svg>

                    : ''}
        </CButton>
        <Link
          //to={props.url} 
          to={{
            pathname: props.url,
            state: props.item,
          }}
          className="itemCardLink">
          <div className="c-posts__media vehicle-media ">
            <img className=" similar-car" src={props.image} alt={props.title} />
          </div>
          <div className="row pt-4 pb-4 pl-4">
            <CCol md="12" className="itemCard1H2P">
              <h4 className="itemCardTitle">
                {props.title}
              </h4>
              {props.subtitle && <p className="itemCardSubtitle itemCardSubtitle2">{props.subtitle} </p>}
              {props.city &&
                <p className="itemCardSubtitle itemCardLocation"> <img src="assets/icons/icon_map.svg" alt="city" className="place-icon-itemCard" /> {props.city}</p>
              }

            </CCol>

            <div className="mx-auto">
              {/* <CButton color="danger" className="btn-vehicle ">
              <img src="assets/icons/eye.svg" />
            </CButton> */}
              {props.at && <>
                <img
                  src={"assets/icons/icon_calandar.svg"}
                  alt=""
                  className="place-icon-itemCard"
                /> <Moment format="DD-MM-YYYY">{props.at}</Moment></>}
              <div className="price priceCard">
                {Array.isArray(props.price) && props.price.length > 1
                  ? (Math.min(...props.price.map(item => parseFloat(item.price))).toLocaleString('en-US', {
                    useGrouping: true,
                    maximumFractionDigits: 0,
                  }) + '-' + Math.max(...props.price.map(item => parseFloat(item.price))).toLocaleString('en-US', {
                    useGrouping: true,
                    maximumFractionDigits: 0,
                  })
                  )
                  : Array.isArray(props.price) && props.price.length == 1
                    ? props.price[0].price.toLocaleString('en-US', {
                      useGrouping: true,
                      maximumFractionDigits: 0,
                    })
                    : props.price
                      ? props.price.toLocaleString('en-US', {
                        useGrouping: true,
                        maximumFractionDigits: 0,
                      })
                      : ''
                }
                {" "}
                <span className="suffix" style={props.price && props.price != 0 ? show : hide}>
                  Tnd
                </span>{" "}
              </div>
            </div>
          </div>
        </Link>
      </div>

      <CModal show={modal} onClose={() => setModal(!modal)}>
        <CModalHeader closeButton></CModalHeader>
        <CModalBody>Vous devez vous connecter d'abord</CModalBody>
        <CModalFooter>
          <Link className="btn-primary btn " to="/members/login">
            Login
          </Link>
          <CButton color="secondary" onClick={() => setModal(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>

    </>
  );
}
